const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const mapApiJs = process.env.REACT_APP_MAP_API_JS_PATH;

export const InitialAddress = {
	address: {
		postcode: '',
		address: '',
		paon: '',
		street: '',
		town_city: '',
		county: '',
		latitude: 0,
		longitude: 0,
		display_address: '',
		name: '',
	},
	uprn: 0,
	name: '',
};

export const extractAddress = (place) => {
	const address = {
		city: '',
		state: '',
		postcode: '',
		country: 'United Kingdom',
		county: '',
		houseNumber: '',
		street: '',
		formattedAddress: '',
		coordinates: {
			lat: 0,
			lng: 0,
		},
	};
	const formattedAddress: ''[] = [];

	if (!Array.isArray(place?.address_components)) {
		return address;
	}

	place.address_components.forEach((component) => {
		const { types, ...rest } = component;
		const value = rest.long_name;

		if (types.includes('street_number')) {
			address.houseNumber = value;
			formattedAddress.push(value);
		}

		if (types.includes('route')) {
			formattedAddress.push(value);
			address.street = formattedAddress.join(', ');
		}

		if (types.includes('postal_code')) {
			address.postcode = value;
			formattedAddress.push(value);
		}

		if (types.includes('locality') || types.includes('postal_town')) {
			address.city = value;
			formattedAddress.push(value);
		}

		if (types.includes('administrative_area_level_1')) {
			address.county = value;
			formattedAddress.push(value);
		}

		if (types.includes('administrative_area_level_2')) {
			address.state = value;
			formattedAddress.push(value);
		}

		if (types.includes('country')) {
			address.country = value;
			formattedAddress.push(value);
		}
	});

	address.formattedAddress = formattedAddress.join(', ');

	if (!address.street) address.street = place.name;

	return address;
};

const onChangeAddress = (autocomplete, setValue, clearErrors) => {
	const place = autocomplete.getPlace();
	const extractedAddress = extractAddress(place);

	setValue('address', extractedAddress);
	setValue('address.coordinates.lat', place.geometry.location.lat());
	setValue('address.coordinates.lng', place.geometry.location.lng());
	clearErrors('address');
};

export const initAutocomplete = (searchInput, setValue, clearErrors) => {
	if (!searchInput.current) return;

	const options = {
		componentRestrictions: { country: 'gb' },
	};

	const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current, options);
	autocomplete.setFields(['address_component', 'geometry', 'name']);

	autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete, setValue, clearErrors));
};

// init gmap script
export const initMapScript = () => {
	// if script already loaded
	if (window.google) {
		return Promise.resolve();
	}
	const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly&language=en`;
	return loadAsyncScript(src);
};

// load google map api js

function loadAsyncScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		Object.assign(script, {
			type: 'text/javascript',
			async: true,
			src,
		});
		script.addEventListener('load', () => resolve(script));
		document.head.appendChild(script);
	});
}
