import { FC } from 'react';
import Iconify from 'src/components/Iconify';
import { Button } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { pinTransaction } from 'src/redux/slices/transaction';
import { pinProperty } from 'src/redux/slices/property';
import { getNav } from 'src/utils/propertyHelpers';
import { getProperties } from 'src/redux/slices/tableSlice';
import { PinCellProps } from './types';
import { TableCell } from '../../styles';

const PinCell: FC<PinCellProps> = ({ isPinned, isTransaction = false, id }) => {
	const handleClick = async () => {
		if (isTransaction) {
			await dispatch(pinTransaction({ id, isPinned }));
		} else {
			await dispatch(pinProperty({ id, isPinned }));
			dispatch(getProperties());
		}

		getNav();
	};

	return (
		<TableCell padding='none'>
			<Button sx={{ minWidth: 'fit-content' }} onClick={handleClick}>
				<Iconify icon={isPinned ? 'lucide:pin-off' : 'lucide:pin'} fontSize={25} />
			</Button>
		</TableCell>
	);
};

export default PinCell;
