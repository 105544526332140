import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components';
import { ConfirmationModal } from 'src/components/modals';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { IDashboardUser } from 'src/redux/types';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { getUserRef } from 'src/utils/firebase';
import { findAllTransactionOnboardingSnapshots } from 'src/utils/onboarding';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { getAddressVerificationDocument } from 'src/utils/verification';
import { useEffect } from 'react';
import { getUsersThunk } from 'src/redux/slices/tableSlice';
import { getFullVerificationAddress } from 'src/utils/verification-session';
import { useLoadScript } from '@react-google-maps/api';
import verificationUpdateNotification from 'src/api/user/verification-update-notification';
import { findAllPropertyOnboardings } from 'src/utils/firebase/property-onboarding';
import { UserAddressConfirmationErrorForm } from './types';
import { userAddressConfirmationErrorValidationSchema } from './validation';

const Content = () => {
	const { payload: user } = useSelector(selectModal<IDashboardUser>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm<UserAddressConfirmationErrorForm>({
		resolver: yupResolver(userAddressConfirmationErrorValidationSchema),
	});

	useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
		libraries: ['places'],
		language: 'en',
	});

	const document = user.verificationSession ? getAddressVerificationDocument(user.verificationSession) : null;
	const documentAddress = document?.structured_postal_address;

	useEffect(() => {
		methods.reset({
			buildingNumber: user.verificationSession?.requestedBuildingNumber ?? documentAddress?.building_number,
			city: user.verificationSession?.requestedCity ?? documentAddress?.town_city,
			country: user.verificationSession?.requestedCountry ?? documentAddress?.country,
			postcode: user.verificationSession?.requestedPostcode ?? documentAddress?.postal_code,
			state: user.verificationSession?.requestedState ?? documentAddress?.state,
			street: user.verificationSession?.requestedStreet ?? documentAddress?.street,
		});
	}, [
		user.verificationSession,
		documentAddress?.building_number,
		documentAddress?.town_city,
		documentAddress?.postal_code,
		documentAddress?.state,
		documentAddress?.street,
	]);

	if (!user.verificationSession) return <>Something went wrong</>;

	const userRef = getUserRef(user.uid);

	const handleCancel = () => handleCloseModal();

	const handleSubmit = async (data: UserAddressConfirmationErrorForm) => {
		try {
			setIsLoading(true);

			const [propertyOnboardings, transactionOnboardings] = await Promise.all([
				findAllPropertyOnboardings(user.uid),
				findAllTransactionOnboardingSnapshots(user.uid),
			]);

			const update = {
				addressConfirmation: OnboardingStepStatusEnum.PASSED,
				addressIssue: OnboardingStepStatusEnum.PASSED,
				verificationIssue: OnboardingStepStatusEnum.PASSED,
			};

			const fullAddress = await getFullVerificationAddress({
				building_number: data.buildingNumber,
				country: data.country,
				postal_code: data.postcode,
				state: data.state,
				street: data.street,
				town_city: data.city,
			});

			await Promise.all([
				updateDoc<unknown>(userRef, {
					isAddressLocked: true,
					address: fullAddress,
					verificationStatus: VerificationSessionStatus.VERIFIED,
				}),
				...propertyOnboardings.map((o) => updateDoc(o.ref, update)),
				...transactionOnboardings.map((o) => updateDoc(o.ref, update)),
			]);

			await verificationUpdateNotification(user.uid, 'addressError');
			dispatch(getUsersThunk());

			handleCloseModal();
		} catch (e) {
			console.log('e', e);
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSubmit)}>
			<Box display='flex' flexDirection='column' gap='15px'>
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField
						name=''
						label='Building Number (document)'
						value={documentAddress?.building_number}
						disabled
						shrink
					/>
					<RHFTextField name='buildingNumber' label='Building Number' placeholder='Please enter building number' />
				</Box>
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField name='' label='Street (document)' value={documentAddress?.street} disabled shrink />
					<RHFTextField name='street' label='Street' placeholder='Please enter street' />
				</Box>
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField name='' label='City (document)' value={documentAddress?.town_city} disabled shrink />
					<RHFTextField name='city' label='City' placeholder='Please enter city' />
				</Box>
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField name='' label='Postcode (document)' value={documentAddress?.postal_code} disabled shrink />
					<RHFTextField name='postcode' label='Postcode' placeholder='Please enter postcode' />
				</Box>
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField name='' label='County (document)' value={documentAddress?.state} disabled shrink />
					<RHFTextField name='state' label='County' placeholder='Please enter county' />
				</Box>
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField name='' label='Country (document)' value={documentAddress?.country} disabled shrink />
					<RHFTextField name='country' label='Country' placeholder='Please enter country' />
				</Box>
			</Box>
			<br />
			<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
				<LoadingButton onClick={handleCancel} variant='outlined' size='large' loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' size='large' loading={isLoading} type='submit'>
					Submit
				</LoadingButton>
			</Box>
		</FormProvider>
	);
};

const UserAddressConfirmationErrorModal = () => (
	<ConfirmationModal
		sx={{ maxWidth: '550px' }}
		modalName={modals.userAddressConfirmationError}
		title='FIX ADDRESS ERROR'
		withButtons={false}
		description={<Content />}
	/>
);

export default UserAddressConfirmationErrorModal;
