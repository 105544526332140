import { Button, IconButton, Menu } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import generateDocuments from 'src/api/user/generate-documents';
import Iconify from 'src/components/Iconify';
import useLoading from 'src/hooks/useLoading';
import { IDashboardUser } from 'src/redux/types';
import { stopPropagation } from 'src/utils/common';

const UserActions: FC<IDashboardUser> = (user) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();

	const hasDocuments = !!Object.keys(user.documents ?? {}).length;

	if (!user.verificationSession || hasDocuments) return null;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		stopPropagation(event);
	};
	const handleClose = (event?) => {
		setAnchorEl(null);
		if (event) stopPropagation(event);
	};

	const isOpen = !!anchorEl;

	const handleGenerateDocuments = async () => {
		try {
			setIsLoading(true);

			await generateDocuments(user.uid);

			enqueueSnackbar('Request to generate verification documents successfully submitted!');
			handleClose();

			setIsLoading(true);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
				<Box px='10px'>
					<Button onClick={handleGenerateDocuments} disabled={isLoading}>
						Regenerate documents
					</Button>
				</Box>
			</Menu>
			<IconButton onClick={handleClick} disableRipple>
				<Iconify icon='simple-line-icons:options-vertical' fontSize={22} />
			</IconButton>
		</>
	);
};

export default UserActions;
