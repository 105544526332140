import { updateDoc } from 'firebase/firestore';
import {
	IQuestionnaireTransaction,
	OnboardingStepStatusEnum,
	Transaction,
	TransactionOfferStatusEnum,
	TransactionOnboarding,
} from 'src/types';
import { TransactionOnboardingStep, TransactionRole, TransactionSide } from 'src/pages/transactions/types';
import { VerificationSessionSide } from 'src/hooks/types';
import { transactionOnboardingSteps, transactionOnboardingStepsKeys } from 'src/pages/transactions/constants';
import { IExtendedTransactionParticipants } from 'src/redux/types';
import { transactionState } from 'src/components/modals/transaction/UpdateTransactionState/constants';
import { getTransaction } from '../firebase';

export const makeTransactionAndOffersFrozen = async (id: string) => {
	const transaction = await getTransaction(id);

	await Promise.all([
		updateDoc<unknown>(transaction.ref, { state: transactionState.standBy }),
		Promise.all(
			transaction.data.offers.map((offer) => updateDoc(offer, { status: TransactionOfferStatusEnum.FROZEN })),
		),
	]);
};

export const getVerificationSessionSideByTransactionRole = (role: TransactionRole): VerificationSessionSide => {
	switch (role) {
		case 'buyer':
			return 'buy';
		case 'seller':
			return 'sell';
		default:
			return 'ancillary';
	}
};

export const getTransactionOnboardingModalName = (step: TransactionOnboardingStep) => transactionOnboardingSteps[step];
export const notAdminTransactionsFilter = (data) =>
	data.state !== transactionState.standBy && data.state !== transactionState.archived;
export const adminTransactionsFilter = (data) =>
	data.state !== transactionState.standBy &&
	data.state !== transactionState.archived &&
	data.state !== transactionState.defunct;
export const archiveTransactionsFilter = (data) => data.state === transactionState.archived;

export const sortTransactionOnboardingSteps = (steps: TransactionOnboardingStep[]) =>
	steps.sort((a, b) => transactionOnboardingStepsKeys.indexOf(a) - transactionOnboardingStepsKeys.indexOf(b));

export const getTransactionOnboardingLatestStep = (
	onboarding: TransactionOnboarding,
): TransactionOnboardingStep | undefined => {
	const onboardingSideKeys = Object.keys(onboarding) as TransactionOnboardingStep[];

	return sortTransactionOnboardingSteps(onboardingSideKeys).find(
		(step) => onboarding[step] === OnboardingStepStatusEnum.PENDING,
	);
};

export const getTransactionSellersHolders = (
	participants: IExtendedTransactionParticipants,
	transactionQuestionnaire: IQuestionnaireTransaction,
) => {
	const proprietors: TagboxOption[] = [];

	participants.all.forEach((seller) => {
		if (seller.relationship !== 'proprietor' || !seller.approved) return;

		const personalQuestionnaire = transactionQuestionnaire?.personal_details?.[seller.uid];
		const company = personalQuestionnaire?.personal?.tax?.company_details;

		if (company && company.name && company.number) proprietors.push({ text: company.name, value: company.number });

		proprietors.push({ text: `${seller.givenNames} ${seller.lastNames}`, value: seller.uid });
	});

	return proprietors;
};

export const getTransactionBuyersHolders = (
	participants: IExtendedTransactionParticipants,
	transactionQuestionnaire?: IQuestionnaireTransaction,
	withCompanies = false,
) => {
	const buyers: TagboxOption[] = [];

	participants.all.forEach((buyer) => {
		const isBuyer = buyer.role === 'buyer' && (!buyer.relationship || buyer.relationship === 'buyer') && buyer.approved;

		if (!isBuyer) return;

		buyers.push({ text: `${buyer.givenNames} ${buyer.lastNames}`, value: buyer.uid });

		if (withCompanies && transactionQuestionnaire) {
			const personalQuestionnaire = transactionQuestionnaire?.personal_details?.[buyer.uid];
			const company = personalQuestionnaire?.personal?.tax?.company_details;

			if (company && company.name && company.number) buyers.push({ text: company.name, value: company.number });
		}
	}, []);

	return buyers;
};

export const getUserTransactionSide = (transaction: Transaction, userId: string): TransactionSide | null => {
	const sides = Object.keys(transaction.parties[userId]).filter(
		(s) => !!Object.values(transaction.parties[userId]?.[s]).length,
	) as TransactionSide[];

	return sides[0] ?? null;
};
