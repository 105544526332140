import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { getQuoteThunk, selectQuote } from 'src/redux/slices/quoteSlice';
import Progress from 'src/components/Progress';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { logout } from 'src/api';
import { AuthPage } from '../auth/styles';
import { QuoteSection } from './styled';
import Transaction from './components/Transaction';
import ReportMissingQuoteAddressModal from './components/ReportMissingQuoteAddressModal';
import { quoteTransactionStatus } from './constants';

const Quote = () => {
	const quote = useSelector(selectQuote);
	const { id: quoteId } = useParams();
	const navigate = useNavigate();

	const handleLogin =
		(includeQuoteId = false) =>
		() => {
			navigate('/auth/register');

			if (includeQuoteId) sessionStorage.setItem('quoteId', quoteId as string);
		};

	useEffect(() => {
		if (!quoteId) {
			handleLogin();

			return;
		}

		logout();
		dispatch(getQuoteThunk({ id: quoteId, withLoading: true }));
	}, [quoteId]);

	const areAllTransactionsSubmitted = !!quote.data?.transactions.every(
		(t) => t.status === quoteTransactionStatus.submitted,
	);

	return (
		<Page title='Quote'>
			<AuthPage>
				<Box display='flex' alignItems='center' flexDirection='column'>
					{!quote.isInitialized ? (
						<Progress />
					) : quote.error || !quote.data ? (
						<Box display='flex' flexDirection='column' gap='10px' alignItems='center'>
							<Typography color='red' fontSize='16px' fontWeight={600}>
								{quote.error?.message ?? 'Something went wrong'}
							</Typography>
							<Button onClick={handleLogin()} variant='contained'>
								Go to Login
							</Button>
						</Box>
					) : (
						<Box display='flex' flexDirection='column' gap='15px' maxWidth='750px' minWidth='750px'>
							<QuoteSection>
								<Typography variant='h4'>YOUR TRANSACTIONS</Typography>
								<Typography sx={{ fontSize: '16px' }}>
									Please provide the addresses for all the properties listed in your quote
								</Typography>
							</QuoteSection>
							{quote.data.transactions.map((t) => (
								<Transaction key={t.id} {...t} />
							))}
						</Box>
					)}
					<Button
						onClick={handleLogin(true)}
						variant='contained'
						sx={{
							mt: '15px',
							padding: '12px 24px', // Chunky button
							fontSize: '18px',
							textTransform: 'unset', // Enforce sentence case
						}}
						disabled={!areAllTransactionsSubmitted}
					>
						Let's go! 🚀
					</Button>
				</Box>
			</AuthPage>
			<ReportMissingQuoteAddressModal />
		</Page>
	);
};

export default Quote;
