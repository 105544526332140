import { TransactionSide } from 'src/pages/transactions/types';
import { call } from '../@';

export type AddTransactionReferrerPayload = {
	userId: string;
	id: string;
	side: TransactionSide;
};

export default async function addTransactionReferrer(payload: AddTransactionReferrerPayload) {
	return await call('transaction-addReferrer', payload);
}
