import { signOut } from 'firebase/auth';
import { auth } from 'src/firebaseConfig';
import { endSession } from '../user-session';

export default async function logout() {
	const currentSessionId = sessionStorage.getItem('sessionId');

	if (currentSessionId) await endSession(currentSessionId).catch(() => null);

	console.log('logout is here');

	sessionStorage.removeItem('sessionId');
	sessionStorage.removeItem('quoteId');

	await signOut(auth);
}
