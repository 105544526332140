import { noop } from 'lodash';
import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser, updateUser } from 'src/redux/slices/auth';
import { LoadingButton } from '@mui/lab';
import useLoading from 'src/hooks/useLoading';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { getAddressVerificationDocument } from 'src/utils/verification';
import { FC, useEffect, useMemo } from 'react';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { dispatch } from 'src/redux/store';
import { updateTransactionOnboarding } from 'src/redux/slices/transaction';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { useSnackbar } from 'notistack';
import { updatePropertyOnboarding } from 'src/redux/slices/property';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { ConfirmationModal } from '..';
import { VerificationAddressIssueForm, VerificationAddressIssueModalProps } from './types';
import { verificationAddressIssueValidationSchema } from './validation';

const VerificationAddressIssueModal: FC<VerificationAddressIssueModalProps> = ({ isProperty }) => {
	const { isLoading, setIsLoading } = useLoading();
	const user = useSelector(selectUser);
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm<VerificationAddressIssueForm>({
		resolver: yupResolver(verificationAddressIssueValidationSchema),
	});

	const document = useMemo(
		() => (user.verificationSession ? getAddressVerificationDocument(user.verificationSession) : null),
		[user.verificationSession],
	);

	useEffect(() => {
		if (!document) return;

		methods.reset({
			buildingNumber: document.structured_postal_address?.building_number,
			street: document.structured_postal_address?.street,
			state: document.structured_postal_address?.state,
			city: document.structured_postal_address?.town_city,
			country: document.structured_postal_address?.country,
			postcode: document.structured_postal_address?.postal_code,
		});
	}, [document]);

	if (!user.verificationSession) return null;

	const handleSubmit = async (data: VerificationAddressIssueForm) => {
		try {
			setIsLoading(true);

			await updateDoc(user.verificationSessionRef as DocumentReference, {
				requestedBuildingNumber: data.buildingNumber,
				requestedStreet: data.street,
				requestedState: data.state,
				requestedCity: data.city,
				requestedCountry: data.country,
				requestedPostcode: data.postcode,
			});

			await dispatch(
				updateUser({
					verificationStatus:
						user.verificationStatus === VerificationSessionStatus.NAME_MISMATCH
							? VerificationSessionStatus.NAME_ADDRESS_MISMATCH
							: VerificationSessionStatus.ADDRESS_MISMATCH,
				}),
			).unwrap();

			if (isProperty) {
				await dispatch(
					updatePropertyOnboarding({
						payload: {
							addressIssue: OnboardingStepStatusEnum.PASSED,
							verificationIssue: OnboardingStepStatusEnum.PENDING,
						},
						moveToNextModal: true,
					}),
				).unwrap();
			} else {
				await dispatch(
					updateTransactionOnboarding({
						addressIssue: OnboardingStepStatusEnum.PASSED,
						verificationIssue: OnboardingStepStatusEnum.PENDING,
					}),
				).unwrap();
			}
		} catch (e) {
			if (e instanceof Error) {
				enqueueSnackbar(e.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '625px' }}
			modalName={isProperty ? propertyOnboardingSteps.addressIssue : transactionOnboardingSteps.addressIssue}
			title='ADDRESS ERROR'
			description={
				<FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSubmit)}>
					<Typography variant='h6' color='black' mb='10px'>
						Enter your domicile as it appears on your chosen proof of address
					</Typography>
					<Box display='flex' flexDirection='column' gap='15px'>
						<RHFTextField
							name='buildingNumber'
							label='Building Number'
							placeholder='Please enter your building number'
						/>
						<RHFTextField name='street' label='Street' placeholder='Please enter your street' />
						<RHFTextField name='city' label='City' placeholder='Please enter your city' />
						<RHFTextField name='postcode' label='Postcode' placeholder='Please enter your postcode' />
						<RHFTextField name='state' label='County' placeholder='Please enter your county' />
						<RHFTextField name='country' label='Country' placeholder='Please enter your country' />
					</Box>
					<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
						<LoadingButton variant='contained' size='large' loading={isLoading} type='submit'>
							Submit
						</LoadingButton>
					</Box>
				</FormProvider>
			}
			withButtons={false}
			confirmText='Go to Dashboard'
			handleClose={noop}
		/>
	);
};

export default VerificationAddressIssueModal;
