/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { StyledMuiAutocomplete } from 'src/pages/auth/styles';

const RHFEmailsField = () => {
	const { control, setValue } = useFormContext();

	return (
		<Controller
			name='emails'
			control={control}
			render={({ field: { value }, fieldState: { error } }) => {
				const currentValue = value ?? [];

				const formattedError =
					[error].flat()?.reduce?.((acc, e) => {
						if (!e?.message) return acc;

						// eslint-disable-next-line no-param-reassign
						acc += `${e.message} `;

						return acc;
					}, '') ?? null;

				return (
					<StyledMuiAutocomplete
						// @ts-ignore
						multiple
						// @ts-ignore
						freeSolo
						onChange={(_, emails) => setValue('emails', emails)}
						options={currentValue}
						value={currentValue}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder='Enter emails'
								variant='outlined'
								error={!!formattedError}
								helperText={formattedError}
							/>
						)}
					/>
				);
			}}
		/>
	);
};

export default RHFEmailsField;
