import { TransactionState } from 'src/components/modals/transaction/UpdateTransactionState/types';
import {
	IDashboardTransactionSummary,
	IDashboardTransactionSummaryUser,
	IDashboardTransactionTotal,
} from 'src/redux/types';
import { call } from '../@';

export type GetTransactionsSummariesPayload = {
	userIds?: string[];
	states?: TransactionState[];
	creationDateFrom?: Date;
	creationDateTo?: Date;
	latestActionDateFrom?: Date;
	latestActionDateTo?: Date;
	order?: string;
	orderBy?: string;
};

export type GetTransactionsSummariesResponse = {
	list: IDashboardTransactionSummary[];
	total: IDashboardTransactionTotal;
	users: IDashboardTransactionSummaryUser[];
};

export default async function getTransactionsSummaries(payload: GetTransactionsSummariesPayload) {
	return await call('transaction-getSummaries', {
		...payload,
		userIds: payload.userIds?.join(','),
		states: payload.states?.join(','),
		creationDateFrom: payload.creationDateFrom?.toISOString(),
		creationDateTo: payload.creationDateTo?.toISOString(),
		latestActionDateFrom: payload.latestActionDateFrom?.toISOString(),
		latestActionDateTo: payload.latestActionDateTo?.toISOString(),
	});
}
