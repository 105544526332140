import { numberWithOptionalDecimalRegExp } from './constants';

const intl = new Intl.NumberFormat('en-US');
const twoDecimalsIntl = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const formatBidNumber = (number: number, withTwoDecimals = false) =>
	number ? (withTwoDecimals ? twoDecimalsIntl : intl).format(number) : number;
export const extractBidValue = (value: string | number) => +value.toString().replaceAll(',', '');

export const formatBidValue = (value: string | number) => {
	let newPrice = value.toString().replaceAll(',', '');

	if (newPrice.includes('.') && newPrice.split('.')[1].split('').length > 2) newPrice = newPrice.slice(0, -1);

	const isValidPrice = numberWithOptionalDecimalRegExp.test(newPrice);

	if (!newPrice) return '';
	if (isValidPrice) {
		let newFormattedPrice = formatBidNumber(+newPrice);

		if (newPrice.endsWith('.')) newFormattedPrice += '.';

		if (newPrice.endsWith('.0')) newFormattedPrice += '.0';
		if (newPrice.endsWith('.00')) newFormattedPrice += '.00';
		if (newPrice.endsWith('0') && !newPrice.endsWith('.0') && newPrice.includes('.') && !newPrice.endsWith('.00'))
			newFormattedPrice += '0';

		return newFormattedPrice;
	}

	return '';
};
