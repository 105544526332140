import { Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { selectModal } from 'src/redux/slices/modal';
import { getTransactionSummaryThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import deleteTransactionReferrer from 'src/api/transaction/delete-transaction-referrer';
import { TransactionSummaryReferrer } from 'src/redux/types';
import { getReferrerLabel } from '../Tracker/components/Referrer/utils';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<TransactionSummaryReferrer>);
	const transaction = useSelector(selectTransactionOverview);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		try {
			setIsLoading(true);

			await deleteTransactionReferrer({ id: transaction.id, userId: payload.id, side: payload.side });

			await dispatch(getTransactionSummaryThunk({ id: transaction.id }));

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Typography sx={styledMainTitle} pt={0}>
				DELETE REFERRER
			</Typography>
			<Typography>Are you sure you want to delete this referrer?</Typography>
			{getReferrerLabel(payload)}
			<ActionButtons mt={'20px'}>
				<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton
					variant='contained'
					size='large'
					fullWidth
					type='submit'
					loading={isLoading}
					onClick={handleSubmit}
				>
					Continue
				</LoadingButton>
			</ActionButtons>
		</>
	);
};

const DeleteReferrerModal = () => (
	<Modal name={modals.deleteReferrer} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default DeleteReferrerModal;
