import type { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel } from '@mui/material';
import type { IRHFSwitch } from './types';

// ----------------------------------------------------------------------

const style = {
	padding: '3px',
	'.MuiSwitch-switchBase': {
		padding: '7px',
		'&.Mui-checked': {
			backgroundColor: 'transparent',
		},
	},
	'.MuiSwitch-thumb': {
		width: '24px',
		height: '24px',
	},
	'.MuiSwitch-track': {
		height: '32px',
		width: '60px',
		borderRadius: '16px',
	},
	'.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: 'primary.light',
		borderWidth: '1px',
		borderColor: 'primary.main',
		borderStyle: 'solid',
		opacity: 1,
	},
};

const RHFSwitch: FC<IRHFSwitch> = ({ name, color, sx, ...other }) => {
	const { control } = useFormContext();

	return (
		<FormControlLabel
			control={
				<Controller
					name={name}
					control={control}
					render={({ field }) => (
						<Switch {...field} checked={field.value} color={color} sx={{ ...style, ...sx }} {...other} />
					)}
				/>
			}
			label=''
			sx={{ marginLeft: 0 }}
		/>
	);
};

export default RHFSwitch;
