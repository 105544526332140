/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getQuote from 'src/api/quote/get-quote';
import { Quote, QuoteSliceState } from '../types/quoteSlice';
import { RootState } from '../store';

const initialState: QuoteSliceState = {
	quote: { isInitialized: false, data: null },
};

export const selectQuote = (state: RootState) => state.quote.quote;

export const getQuoteThunk = createAsyncThunk<Quote, { id: string; withLoading?: boolean }>(
	'quote/get',
	({ id }, { rejectWithValue }) => {
		try {
			return getQuote({ id });
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

const quoteSlice = createSlice({
	name: 'data',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getQuoteThunk.pending, (state, { meta }) => {
			if (meta.arg.withLoading) state.quote.isInitialized = false;
		});
		builder.addCase(getQuoteThunk.fulfilled, (state, { payload }) => {
			state.quote.data = payload;
			state.quote.isInitialized = true;
		});
		builder.addCase(getQuoteThunk.rejected, (state, { error }) => {
			state.quote.isInitialized = true;
			state.quote.error = error;
		});
	},
});

export default quoteSlice.reducer;
