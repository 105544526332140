import { call } from '../@';

type Payload = {
	id: string;
	transactionId: string;
	givenNames: string;
	lastNames: string;
	email?: string;
	phone?: string;
	business?: string;
	branch?: string;
	quote?: number | null;
	address?: string;
	relationship?: string;
};

export default async function updateTransactionPlaceholder(payload: Payload) {
	return await call('transaction-updatePlaceholder', payload);
}
