import { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from '../Iconify';
import { FieldWrapper, GeneralBoxWrapper, IconWrapper, ValueWrapper } from './styles';
import type { IInviteCardTooltip } from './types';

export const InviteCardTooltip: FC<IInviteCardTooltip> = ({ fields }) => {
	const { enqueueSnackbar } = useSnackbar();

	const handleCopyAll = async () => {
		const parts: string[] = [];

		fields.forEach((f) => {
			if (!f.onCopy || !f.value) return;

			parts.push(f.value);
		});

		await navigator.clipboard.writeText(parts.join('\n'));

		enqueueSnackbar('Data successfully copied!');
	};

	return (
		<GeneralBoxWrapper position='relative'>
			<IconButton
				onClick={handleCopyAll}
				sx={{ ml: '5px', position: 'absolute', top: -25, right: -23, fontSize: 14 }}
				disableRipple
			>
				Copy all
			</IconButton>
			{fields.map(({ icon, value, label, onCopy, displayValue = true }) => (
				<FieldWrapper key={value}>
					<Box display='flex' alignItems='center' gap='5px'>
						<IconWrapper>
							<Iconify icon={icon} fontSize={15} color='#FFFFFF' />
						</IconWrapper>
						{!!label && (
							<Typography fontSize='16px' fontWeight={700} pb='3px'>
								{label}
							</Typography>
						)}
					</Box>
					<ValueWrapper>
						{displayValue && value}
						{!!onCopy && (
							<IconButton onClick={onCopy} sx={{ ml: '5px' }}>
								<Iconify icon='solar:copy-linear' fontSize={18} color='#D6D9DE' />
							</IconButton>
						)}
					</ValueWrapper>
				</FieldWrapper>
			))}
		</GeneralBoxWrapper>
	);
};
