import { FormProvider, useForm } from 'react-hook-form';

import modals from 'src/constants/modals';
import { useEffect, useState } from 'react';
import { BrandModal } from 'src/components/common';
import { dispatch, useSelector } from 'src/redux/store';
import {
	getPropertyAvailableDocuments,
	selectProperty,
	selectPropertyTitlesDocuments,
} from 'src/redux/slices/property';
import { selectModal } from 'src/redux/slices/modal';
import { Box, Typography } from '@mui/material';
import Progress from 'src/components/Progress';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { LoadingButton } from '@mui/lab';
import Modal from 'src/components/customModal';
import downloadPropertyDocuments from 'src/api/property/download-property-documents';
import { useSnackbar } from 'notistack';
import useLoading from 'src/hooks/useLoading';
import { parseStringToJson } from 'src/utils/strings';
import { RESPONSE_CODES } from 'src/constants';
import useError from 'src/hooks/useError';
import { getTransactionOverviewThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import DocumentsCategory from './components/DocumentsCategory';
import { FormTitleDocumentCategory, AvailableDocumentsFormType, FormTitleDocumentItem } from './types';
import { propertyTitleDocumentsCategoriesKeys } from '../../constants';

const Content = () => {
	const titlesDocuments = useSelector(selectPropertyTitlesDocuments);
	const transaction = useSelector(selectTransactionOverview);
	const { isLoading, setIsLoading } = useLoading();
	const { payload } = useSelector(selectModal<{ titles: Array<{ title: string; tenure: string }> }>);
	const property = useSelector(selectProperty);
	const { enqueueSnackbar } = useSnackbar();
	const { setError, error } = useError();
	const form = useForm<AvailableDocumentsFormType>({
		defaultValues: { titlesDocuments: [] },
	});
	const [force, setForce] = useState(false);

	const handleSubmit = async (data: AvailableDocumentsFormType) => {
		try {
			setIsLoading(true);
			setError(null);

			const selectedDocuments = data.titlesDocuments.reduce<FormTitleDocumentItem[]>((acc, documents) => {
				propertyTitleDocumentsCategoriesKeys.forEach((key) => {
					const extendedDocuments = documents[key].reduce((categoryAcc, document) => {
						if (document.selected && !transaction.downloadedDocumentsIds.includes(document.id)) {
							categoryAcc.push({ ...document, title: documents.title, tenure: documents.tenure });
						}

						return categoryAcc;
					}, []);

					acc.push(...extendedDocuments);
				});

				return acc;
			}, []);

			await downloadPropertyDocuments(property.id, transaction.id, selectedDocuments, force);
			dispatch(getTransactionOverviewThunk({ id: transaction.id }));
			enqueueSnackbar('Download completed!');
			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) {
				const response = parseStringToJson(e.message);

				if (typeof response === 'string') {
					enqueueSnackbar(e.message, { variant: 'error' });
				} else if (response?.code) {
					switch (response?.code) {
						case RESPONSE_CODES.DOCUMENTS.DOWNLOADING_ACKNOWLEDGEMENT:
							enqueueSnackbar(response.acknowledgement, { variant: 'error' });

							break;
						case RESPONSE_CODES.HMLR.TITLE_PENDING_APPLICATION:
							setError(response.reason);
							setForce(true);

							break;
						default:
							break;
					}
				}
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		dispatch(getPropertyAvailableDocuments({ titles: payload.titles }));
	}, [property.id]);

	useEffect(() => {
		form.reset({ titlesDocuments: titlesDocuments.data });
	}, [titlesDocuments.data]);

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<BrandModal.Title title='DOCUMENTS AVAILABILITY' />
				{error}
				{titlesDocuments.isInitialized ? (
					<Box display='flex' flexDirection='column' pt='5px'>
						{titlesDocuments.data.length ? (
							<>
								{titlesDocuments.data.map((documents, index) => (
									<DocumentsCategory
										key={documents.title}
										documentsCategory={documents as FormTitleDocumentCategory}
										index={index}
									/>
								))}
							</>
						) : (
							<Typography>No available documents found</Typography>
						)}
						<ActionButtons mt='7px'>
							<LoadingButton variant='outlined' fullWidth onClick={handleCloseModal} loading={isLoading}>
								Cancel
							</LoadingButton>
							<LoadingButton variant='contained' fullWidth type='submit' loading={isLoading}>
								{force ? 'Force Submit' : 'Submit'}
							</LoadingButton>
						</ActionButtons>
					</Box>
				) : (
					<Box height='100px'>
						<Progress zoom={0.5} />
					</Box>
				)}
			</form>
		</FormProvider>
	);
};

const AvailableDocumentsModal = () => (
	<Modal name={modals.availableDocuments} cardSx={{ maxWidth: '600px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default AvailableDocumentsModal;
