import { TransactionState } from 'src/components/modals/transaction/UpdateTransactionState/types';
import { call } from '../@';
import { SuccessResponse } from '../@/api-methods';

export type UpdateTransactionDto = {
	id: string;
	propertyId?: string;
	state?: TransactionState;
};

export type UpdateTransactionResponse = SuccessResponse;

export default async function updateTransaction(dto: UpdateTransactionDto) {
	return await call('transaction-update', dto);
}
