import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { selectModal } from 'src/redux/slices/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { LoadingButton } from '@mui/lab';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import reportMissingQuoteAddress from 'src/api/quote/report-missing-quote-address';
import { getQuoteThunk, selectQuote } from 'src/redux/slices/quoteSlice';
import { dispatch } from 'src/redux/store';
import { RHFTextField } from 'src/components';
import { QuoteTransaction } from 'src/redux/types/quoteSlice';
import { reportMissingQuoteAddressValidationSchema } from './validation';
import { ReportMissingQuoteAddressForm } from './types';
import { getQuoteTransactionLabel } from '../Transaction/utils';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<{ transaction: QuoteTransaction; postcode: string }>);
	const quote = useSelector(selectQuote);
	const form = useForm<ReportMissingQuoteAddressForm>({
		resolver: yupResolver(reportMissingQuoteAddressValidationSchema),
		defaultValues: { postcode: payload.postcode },
	});

	const handleSubmit = async (data: ReportMissingQuoteAddressForm) => {
		try {
			if (!quote.data?.id) return;

			setIsLoading(true);

			await reportMissingQuoteAddress({
				id: quote.data?.id,
				address: data.address,
				postcode: data.postcode,
				transactionId: payload.transaction.id,
			});

			await dispatch(getQuoteThunk({ id: quote.data.id })).unwrap();

			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle} pt={0}>
					{getQuoteTransactionLabel(payload.transaction)}
				</Typography>
				<Typography my='10px'>
					Please type the missing address below. We'll verify it against our records and get back to you!
				</Typography>
				<RHFTextField name='postcode' label='Postcode' disabled />
				<RHFTextField name='address' label='Address' sx={{ mt: '10px' }} />
				<ActionButtons mt={'20px'}>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton variant='contained' size='large' fullWidth type='submit' loading={isLoading}>
						Confirm
					</LoadingButton>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

const ReportMissingQuoteAddressModal = () => (
	<Modal name={modals.reportMissingQuoteAddress} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default ReportMissingQuoteAddressModal;
