import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import Iconify from 'src/components/Iconify';
import modals from 'src/constants/modals';
import { openModal } from 'src/redux/slices/modal';
import { dispatch } from 'src/redux/store';
import { TransactionSummaryReferrer } from 'src/redux/types';
import { getReferrerLabel } from './utils';

const Referrer: FC<TransactionSummaryReferrer> = (user) => {
	const handleDelete = () => dispatch(openModal({ name: modals.deleteReferrer, payload: user }));

	return (
		<li>
			<Box display='flex' alignItems='center' gap='15px'>
				{getReferrerLabel(user)}
				<IconButton onClick={handleDelete}>
					<Iconify icon='icon-park-solid:close-one' fontSize='25px' />
				</IconButton>
			</Box>
		</li>
	);
};

export default Referrer;
