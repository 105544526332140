import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import modals from 'src/constants/modals';
import { getUserBankAccountsThunk, selectUserBankAccounts } from 'src/redux/slices/auth';
import { openModal } from 'src/redux/slices/modal';
import { dispatch } from 'src/redux/store';
import ErrorView from 'src/components/ErrorView';
import { CenterBox } from './style';
import BankAccount from './components/BankAccount';
import AddBankAccountModal from './components/AddBankAccountModal';
import DeleteBankAccountModal from './components/BankAccount/components/DeleteBankAccountModal';

const BankAccounts = () => {
	const bankAccounts = useSelector(selectUserBankAccounts);

	const handleAddBankAccount = () => dispatch(openModal({ name: modals.addBankAccount }));

	useEffect(() => {
		dispatch(getUserBankAccountsThunk());
	}, []);

	return (
		<Box mt='-30px' height='100%'>
			<Box display='flex' alignItems='center' gap='7px'>
				<Typography variant='h4'>Bank Accounts</Typography>
				<LoadingButton
					type='submit'
					variant='contained'
					onClick={handleAddBankAccount}
					sx={{ textTransform: 'initial' }}
				>
					Add bank account
				</LoadingButton>
			</Box>
			<Box height='100%'>
				{bankAccounts.error ? (
					<ErrorView error={bankAccounts.error} />
				) : bankAccounts.isInitialized ? (
					<Box display='flex' flexDirection='column' gap='15px' mt='30px' width='500px'>
						{bankAccounts.data.map((account) => (
							<BankAccount key={account.id} {...account} />
						))}
					</Box>
				) : (
					<CenterBox height='80%'>
						<Progress />
					</CenterBox>
				)}
			</Box>
			<AddBankAccountModal />
			<DeleteBankAccountModal />
		</Box>
	);
};

export default BankAccounts;
