import { FC } from 'react';
import { QuoteTransaction } from 'src/redux/types/quoteSlice';
import { Box, Typography, Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import UprnForm from 'src/components/UprnForm';
import useLoading from 'src/hooks/useLoading';
import useError from 'src/hooks/useError';
import { InitialAddress } from 'src/components/GoogleAddressAutoComplete/GooglePlaceAutocomlete';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import submitQuoteTransactionAddress from 'src/api/quote/submit-quote-transaction-address';
import { useSelector } from 'react-redux';
import { getQuoteThunk, selectQuote } from 'src/redux/slices/quoteSlice';
import { extractNewPropertyAddress, handleCloseModal } from 'src/utils/propertyHelpers';
import { useSnackbar } from 'notistack';
import { IFindAddressByPostcodeResponse } from 'src/api/epc/epc-types';
import { QuoteSection } from '../../styled';
import { QuoteTransactionForm } from './types';
import { quoteTransactionValidationSchema } from './validation';
import { iconByTransactionStatus } from './constants';
import { getQuoteTransactionLabel } from './utils';
import { quoteTransactionStatus } from '../../constants';

const Transaction: FC<QuoteTransaction> = (transaction) => {
	const { error, setError } = useError();
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const quote = useSelector(selectQuote);

	const form = useForm<QuoteTransactionForm>({
		resolver: yupResolver(quoteTransactionValidationSchema),
		defaultValues: InitialAddress,
	});

	const handleSubmit = async (data: QuoteTransactionForm) => {
		try {
			if (!quote.data?.id) return;

			setIsLoading(true);

			await submitQuoteTransactionAddress({
				id: quote.data.id,
				transactionId: transaction.id,
				uprn: data.uprn,
				address: extractNewPropertyAddress(data.address as IFindAddressByPostcodeResponse),
			});

			await dispatch(getQuoteThunk({ id: quote.data.id })).unwrap();

			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const address = form.watch('address');

	const handleReportMissingAddress = () => {
		dispatch(
			openModal({ name: modals.reportMissingQuoteAddress, payload: { transaction, postcode: address?.postcode } }),
		);
	};

	const isValidPostcode = !!address?.postcode && !Object.keys(form.formState.errors.address?.postcode ?? {}).length;
	const isPendingTransaction = transaction.status === quoteTransactionStatus.pending;
	const isMissingAddressTransaction = transaction.status === quoteTransactionStatus.missingAddress;
	const isErrorTransaction = transaction.status === quoteTransactionStatus.error;

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<QuoteSection py='20px !important' alignItems='center' sx={{ flexDirection: 'row', gap: '20px' }}>
					<Box width='100%'>
						<Box width='100%'>
							<Typography variant='h6'>
								{iconByTransactionStatus[transaction.status]} {getQuoteTransactionLabel(transaction)}
								{isMissingAddressTransaction && (
									<Typography fontWeight={600} display='inline' ml='10px' color='gray'>
										We're verifying your submission.
									</Typography>
								)}
								{isErrorTransaction && !!transaction.error && (
									<Typography fontWeight={600} display='inline' ml='10px' color='red'>
										{transaction.error}
									</Typography>
								)}
							</Typography>
							{isPendingTransaction && (
								<Typography mt='5px' color='gray'>
									Enter the property postcode and choose an address
								</Typography>
							)}
						</Box>
						<Box display='flex' flexDirection='column' gap='15px' width='100%' alignItems='flex-end' mt='7px'>
							{error}
							{isPendingTransaction ? (
								<>
									<UprnForm
										setError={setError}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										sx={{ width: '100%' }}
									/>
									<Box display='flex' gap='15px'>
										{isValidPostcode && (
											<Button
												variant='outlined'
												sx={{ width: '240px', height: '50px' }}
												onClick={handleReportMissingAddress}
											>
												Can't find your property
											</Button>
										)}
										<Button
											type='submit'
											variant='contained'
											sx={{ width: '200px', height: '50px' }}
											disabled={!form.formState.isValid}
										>
											Submit
										</Button>
									</Box>
								</>
							) : (
								<Box
									component='b'
									width='100%'
									sx={{
										fontSize: '16px', // Set the font size (adjust as needed)
										fontWeight: 'normal', // Set the font weight (you can change it to 'bold' or other values)
									}}
								>
									🏠 {transaction.address?.displayAddress}
									{transaction.address?.townCity ? `, ${transaction.address?.townCity}` : ''},{' '}
									{transaction.address?.postcode}
								</Box>
							)}
						</Box>
					</Box>
				</QuoteSection>
			</form>
		</FormProvider>
	);
};

export default Transaction;
