import type { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Slider } from '@mui/material';
import type { IRHFSlider } from './types';

// ----------------------------------------------------------------------

const RHFSlider: FC<IRHFSlider> = ({ name, ...other }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => <Slider {...field} valueLabelDisplay='auto' {...other} />}
		/>
	);
};

export default RHFSlider;
