import { Table, TableBody } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorView from 'src/components/ErrorView';
import LoadingView from 'src/components/LoadingView';
import { getUsersThunk, selectUsers } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { TableCell, TableRow, TableHead } from '../../styles';
import StatusField from './components/StatusField';
import ManageEmailChangeRequestModal from './components/ManageEmailChangeRequestModal';
import HeadCell from './components/HeadCell';
import VerificationField from './components/VerificationField';
import UserVerificationFailedModal from './components/UserVerificationFailed';
import UserNameConfirmationErrorModal from './components/UserNameConfirmationError';
import UserAddressConfirmationErrorModal from './components/UserAddressConfirmationError';
import UserActions from './components/UserActions';

const UsersTable = () => {
	const users = useSelector(selectUsers);

	useEffect(() => {
		dispatch(getUsersThunk());
	}, []);

	if (users.list.error) return <ErrorView error={users.list.error} />;

	return (
		<LoadingView isLoading={users.list.isLoading}>
			<Table>
				<TableHead>
					<TableRow>
						<HeadCell name='Given Names' columnKey='givenNames' minWidth={175} />
						<HeadCell name='Last Names' columnKey='lastNames' minWidth={175} />
						<HeadCell name='Email' columnKey='email' />
						<HeadCell name='Type' columnKey='professions' />
						<HeadCell name='Verification' columnKey='verificationStatus' minWidth={200} />
						<HeadCell name='Status' columnKey='status' />
						<HeadCell name='Id' columnKey='uid' />
					</TableRow>
				</TableHead>
				<TableBody>
					{users.list.data.map((row) => (
						<TableRow key={row.uid}>
							<TableCell>{row.givenNames}</TableCell>
							<TableCell>{row.lastNames}</TableCell>
							<TableCell maxWidth={400} minWidth={350} wordBreak='break-all'>
								{row.email}
							</TableCell>
							<TableCell>{row.professions}</TableCell>
							<TableCell>
								<VerificationField user={row} />
							</TableCell>
							<TableCell minWidth={275}>
								<StatusField user={row} />
							</TableCell>
							<TableCell>{row.uid}</TableCell>
							<TableCell>
								<UserActions {...row} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<ManageEmailChangeRequestModal />
			<UserVerificationFailedModal />
			<UserNameConfirmationErrorModal />
			<UserAddressConfirmationErrorModal />
		</LoadingView>
	);
};

export default UsersTable;
