import { labelByTrackerStepName, trackerSteps } from 'src/components/TransactionSummary/constants';
import { TrackerStageName, TrackerStepName } from 'src/types';

export const trackerStepsOptions = trackerSteps.map((step) => ({
	key: step,
	name: labelByTrackerStepName[step],
}));

export const trackerStagesByStep: Record<TrackerStepName, TrackerStageName[]> = {
	seller: ['onboarding', 'questions', 'evidence', 'instruction'],
	property: ['onboarding', 'questions', 'documents', 'listing', 'searches'],
	buyer: ['onboarding', 'questions', 'evidence', 'instruction'],
	legals: ['contractPack', 'sourceOfFunds', 'enquiries', 'exchange'],
	listing: [],
	exchange: [],
};
