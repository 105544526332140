import { Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFAutocomplete } from 'src/components/hook-form';
import { transactionPartieSidesOptions } from 'src/pages/transactions/constants';
import { selectUsers } from 'src/redux/slices/tableSlice';
import { isEqual } from 'lodash';
import { ROLES_ANCILLARY } from 'src/constants';
import { IDashboardUser } from 'src/redux/types';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import addTransactionReferrer from 'src/api/transaction/add-transaction-referrer';
import { getTransactionSummaryThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { AddReferrerForm } from './types';
import { validationSchema } from './validation';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const users = useSelector(selectUsers, isEqual);
	const [isLoading, setIsLoading] = useState(false);
	const transaction = useSelector(selectTransactionOverview);
	const form = useForm<AddReferrerForm>({
		resolver: yupResolver(validationSchema),
	});

	const ancillaries = useMemo(
		() => users.list.data.filter((u) => u.roleId === ROLES_ANCILLARY.id && u.givenNames && u.lastNames),
		[users.list],
	);

	const handleSubmit = async (data: AddReferrerForm) => {
		try {
			setIsLoading(true);

			await addTransactionReferrer({ id: transaction.id, ...data });

			await dispatch(getTransactionSummaryThunk({ id: transaction.id }));

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle} pt={0}>
					ADD REFERRER
				</Typography>
				<Box display='flex' flexDirection='column' gap='15px'>
					<RHFAutocomplete
						name='side'
						label='Select a side'
						options={transactionPartieSidesOptions.filter((o) => o.key !== 'view')}
						valueAttribute='key'
					/>
					<RHFAutocomplete
						name='userId'
						label='Select referrer'
						options={ancillaries}
						getOptionLabel={(o: IDashboardUser) => `${o.givenNames} ${o.lastNames}`}
						renderOption={(props, option: IDashboardUser) => (
							<li {...props} key={option.uid}>
								{option.givenNames} {option.lastNames}
							</li>
						)}
						valueAttribute='uid'
					/>
				</Box>
				<ActionButtons mt={'20px'}>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton variant='contained' size='large' fullWidth type='submit' loading={isLoading}>
						Add
					</LoadingButton>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

const AddReferrerModal = () => (
	<Modal name={modals.addReferrer} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default AddReferrerModal;
