import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { RHFPhoneNumberInput } from 'src/components';
import { dispatch } from 'src/redux/store';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { useSelector } from 'react-redux';
import useLoading from 'src/hooks/useLoading';
import requestQuoteCallback from 'src/api/quote/request-quote-callback';
import { useSnackbar } from 'notistack';
import { selectQuote } from 'src/redux/slices/quoteSlice';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { validationSchema } from './validation';
import { QuoteCallbackForm } from './types';

const Content = () => {
	const { payload } = useSelector(selectModal<{ issue: string }>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const quote = useSelector(selectQuote);
	const form = useForm<QuoteCallbackForm>({
		resolver: yupResolver(validationSchema),
	});

	const handleSubmit = async (data: QuoteCallbackForm) => {
		try {
			setIsLoading(false);

			await requestQuoteCallback({ id: quote.data?.id as string, issue: payload.issue, phone: data.phone });

			enqueueSnackbar('Quote callback request successfully submitted!');

			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};
	const handleBack = () => {
		dispatch(openModal({ name: modals.quoteQuestions, payload: { issue: payload.issue } }));
	};

	return (
		<FormProvider {...form}>
			<Typography sx={styledMainTitle} pt={0}>
				REQUEST A CALLBACK
			</Typography>
			<RHFPhoneNumberInput name='phone' label='Your phone' />
			<ActionButtons mt={'20px'}>
				<LoadingButton variant='outlined' size='large' fullWidth onClick={handleBack} loading={isLoading}>
					Go back
				</LoadingButton>
				<LoadingButton
					variant='contained'
					size='large'
					fullWidth
					sx={{ textTransform: 'unset' }}
					onClick={form.handleSubmit(handleSubmit)}
					loading={isLoading}
				>
					Submit
				</LoadingButton>
			</ActionButtons>
		</FormProvider>
	);
};

const QuoteCallbackModal = () => (
	<Modal name={modals.quoteCallbackRequest} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default QuoteCallbackModal;
