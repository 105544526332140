import { call } from '../@';

export type ReportMissingQuoteAddressPayload = {
	id: string;
	transactionId: string;
	postcode: string;
	address: string;
};

export default async function reportMissingQuoteAddress(payload: ReportMissingQuoteAddressPayload) {
	return await call('reportMissingQuoteAddress', payload);
}
