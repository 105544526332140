import { PropertyDocument } from 'src/types';
import { call } from '../@';

const downloadPropertyDocuments = (
	propertyId: string,
	transactionId: string,
	documents: PropertyDocument[],
	force = false,
) => call('hmlr-downloadPropertyDocuments', { documents, propertyId, force, transactionId });

export default downloadPropertyDocuments;
