/* eslint-disable react/no-unstable-nested-components */
import { LoadingButton } from '@mui/lab';
import { Box, Switch, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import Table from 'src/components/common/Table';
import Row from 'src/components/common/Table/components/Row';
import { TableColumn } from 'src/components/common/Table/types';
import Iconify from 'src/components/Iconify';
import Progress from 'src/components/Progress';
import useLoading from 'src/hooks/useLoading';
import { getMonthlyReportsThunk, selectMonthlyReports, sortMonthlyReports } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { MonthlyReport } from 'src/redux/types';
import { downloadCsv } from 'src/utils/csv';
import { formatPrice } from 'src/utils/transaction/summary';
import { MarkdownWrapper } from './styles';

const MonthlyTable = () => {
	const { data: reports, isLoading: areReportsLoading, error } = useSelector(selectMonthlyReports);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const [isCountMode, setIsCountMode] = useState(false);

	const handleSortChange = ({ order, orderBy }) => dispatch(sortMonthlyReports({ order, orderBy }));

	useEffect(() => {
		dispatch(getMonthlyReportsThunk());
	}, []);

	if (areReportsLoading) return <Progress />;
	if (error || !reports.data) {
		return (
			<Box p='15px'>
				<Typography color='red' variant='h4'>
					{error?.message ?? 'Something went wrong'}
				</Typography>
			</Box>
		);
	}

	const handleCountModeChange = () => setIsCountMode(!isCountMode);

	const columns: TableColumn<MonthlyReport>[] = isCountMode
		? [
				{ key: 'date', name: 'Month', sortable: true, width: 150 },
				{ key: 'quotedCount', name: 'Quoted', sortable: true, width: 150 },
				{ key: 'bookedCount', name: 'Booked', sortable: true, width: 150 },
				{ key: 'allocatedCount', name: 'Allocated', sortable: true, width: 150 },
				{ key: 'lostCount', name: 'Lost', sortable: true, width: 150 },
				{ key: 'openCount', name: 'Open', sortable: true, width: 150 },
				{ key: 'dueCount', name: 'Due', sortable: true, width: 150 },
				{ key: 'overdueCount', name: 'Overdue', sortable: true, width: 150 },
				{ key: 'realisedCount', name: 'Realised', sortable: true, width: 150 },
		  ]
		: [
				{ key: 'date', name: 'Month', sortable: true, width: 150 },
				{ key: 'quoted', name: 'Quoted', sortable: true, width: 150, getLabel: (r) => formatPrice(r.quoted, true) },
				{ key: 'booked', name: 'Booked', sortable: true, width: 150, getLabel: (r) => formatPrice(r.booked, true) },
				{
					key: 'allocated',
					name: 'Allocated',
					sortable: true,
					width: 150,
					getLabel: (r) => formatPrice(r.allocated, true),
				},
				{ key: 'lost', name: 'Lost', sortable: true, width: 150, getLabel: (r) => formatPrice(r.lost, true) },
				{ key: 'open', name: 'Open', sortable: true, width: 150, getLabel: (r) => formatPrice(r.open, true) },
				{ key: 'due', name: 'Due', sortable: true, width: 150, getLabel: (r) => formatPrice(r.due, true) },
				{ key: 'overdue', name: 'Overdue', sortable: true, width: 150, getLabel: (r) => formatPrice(r.overdue, true) },
				{
					key: 'realised',
					name: 'Realised',
					sortable: true,
					width: 150,
					getLabel: (r) => formatPrice(r.realised, true),
				},
		  ];

	const handleDownload = async () => {
		try {
			if (!reports.data) return;

			setIsLoading(true);

			const rows = [
				columns.map((c) => c.name),
				...[...reports.data.rows, reports.data.total].map((row) =>
					columns.map((c) => (c.getLabel ? c.getLabel(row) : row[c.key])),
				),
			];

			const date = moment().format('YYYY_MM_DD');

			downloadCsv(`monthly_report_${date}.csv`, rows);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Box mt='10px' display='flex' flexDirection='column' gap='7px'>
				<Typography fontSize='17px'>
					Display mode: Revenue
					<Switch
						onChange={handleCountModeChange}
						value={isCountMode}
						sx={{
							'& .MuiSwitch-switchBase': {
								color: 'blue',
							},
							'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
								backgroundColor: '#D3D3D3 !important',
								opacity: 1,
							},
							'& .MuiSwitch-track': {
								backgroundColor: '#D3D3D3 !important',
								opacity: 1,
							},
						}}
					/>
					Sides
				</Typography>
			</Box>
			<Table
				columns={columns}
				data={{
					data: {
						data: reports.data.rows,
						limit: 0,
						page: 0,
						totalCount: 0,
						totalPages: 0,
						sortOrder: reports.order,
						orderBy: reports.orderBy,
					},
					isInitialized: !isLoading,
				}}
				handleSortChange={handleSortChange}
				withPagination={false}
				stickyHeader
				rowKeyField='id'
				width={1750}
			>
				<Row columns={columns} row={reports.data.total} sx={{ background: 'lightgrey' }} />
			</Table>
			<Box pt='10px'>
				<LoadingButton variant='contained' onClick={handleDownload} loading={isLoading}>
					Download CSV
					<Iconify icon='mdi:file' fontSize={20} ml='5px' />
				</LoadingButton>
			</Box>
			<MarkdownWrapper mt='15px' fontSize='17px' variant='body2' color='grey' maxWidth='1500px'>
				<ReactMarkdown>
					{`# Notes

All amounts are gross (= VAT-inclusive), representing the actual funds received in our bank accounts.

## "Quoted"
- **Quoted revenue**: Total value of quotes sent in month M.
- **Quoted sides**: Count of transaction sides quoted in month M.


#### Criteria:
- transaction state: Live, Standby, Defunct, or Completed
- task "Quote Sent": Done
- date of "Quote Sent" is in month M


## "Booked"
- **Booked revenue**: Total value of quotes accepted in month M.
- **Booked sides**: Count of transaction sides booked in month M.


#### Criteria:
- transaction state: Live, Standby, Defunct, or Completed
- task "Quote Accepted": Done
- date of "Quote Accepted" is in month M


## "Allocated"
- **Allocated revenue**: Booked revenue from transactions where we received money on account (e.g. searches paid) in month M.
- **Allocated sides**: Count of transaction sides allocated in month M.


#### Criteria:
- transaction state: Live, Standby, Defunct, or Completed
- task "Quote Accepted": Done
- task "Exchange": Not Done
- task "Completion": Not Done
- any Crezco payment: Paid
- payment date is in month M


**Comments**: The rationale is that this revenue is more likely to become realised since the parties have made a financial commitment to the transaction.


## "Lost"
- **Lost revenue**: Booked revenue from transactions that were marked as _Defunct_ in month M.
- **Lost transactions**: Count of transactions lost in month M. (ToDo: this calculation will need to be upgraded to track lost buy sides, which we currently can't do.)


#### Criteria:
- transaction state: Defunct
- task "Quote Sent": Done
- transaction was set to Defunct in month M


## "Open"
- **Open revenue**: Booked revenue that has not yet been realised. It carries over from previous months and is only displayed in the current month.
- **Open sides**: Count of transaction sides open in month M.


#### Criteria:
- transaction state: Live
- task "Quote Accepted": Done
- task "Exchange": Not Done
- task "Completion": Not Done
- today's date is in month M


## "Due"
- **Due revenue**: Booked revenue from transactions that have EXCHANGED, but where the final payment remains unpaid as of today. It is only displayed in the current month.
- **Due sides**: Count of transaction sides due in month M.


#### Criteria:
- transaction state: Live
- task "Quote Accepted": Done
- task "Exchange": Done
- task "Completion": Not Done
- final payment: Not Paid
- today's date is in month M


## "Overdue"
- **Overdue revenue**: Booked revenue from transactions that have COMPLETED, but where the final payment remains unpaid as of today. It is only displayed in the current month.
- **Overdue sides**: Count of transaction sides overdue in month M.


#### Criteria:
- transaction state: Live
- task "Quote Accepted": Done
- task "Exchange": Done
- task "Completion": Done
- final payment: Not Paid
- today's date is in month M


## "Realised"


- **Realised revenue**: Booked revenue from transactions with completed final payments. This represents the amount that would be deposited into the bank account if we were responsible for distributing the solicitor’s share ourselves.
- **Realised sides**: Count of transaction sides with final payment in month M.

#### Criteria:
- transaction state: Any (all payments count)
- task "Quote Accepted": Done
- task "Exchange": Done
- task "Completion": Done
- final payment: Paid
- payment date is in month M

**Comments**: Currently, "Realised" does not reflect the actual money received in our bank accounts. What we receive is only the "Conveyo" portion of booked revenue (i.e., "Booked Revenue" minus "Solicitor Share"). However, for consistency, we can't display it this way—otherwise, realised revenue would always appear lower than booked revenue.`}
				</ReactMarkdown>
			</MarkdownWrapper>
		</Box>
	);
};

export default MonthlyTable;
