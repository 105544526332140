import { Box, Button, Fade, MenuItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';

import noop from 'src/utils/noop';
import { formatBidNumber } from 'src/utils/formatBid';
import { useSelector } from 'src/redux/store';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { selectUser } from 'src/redux/slices/auth';
import { NoBreak } from 'src/styled';
import { selectTransactionQuestionnaire } from 'src/redux/slices/transactionQuestionnaire';
import { IExtendedQuestionnaireTransaction } from 'src/types';
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';
import { ProfilePhoto } from '../ProfilePhoto';
import { InviteCardTooltip } from './InviteCardTooltip';
import {
	TextUserName,
	CardStyled,
	CompanyStyled,
	CustomTooltip,
	EditIconBtnStyled,
	InviteLogo,
	MenuPopoverStyle,
	TypoBranchName,
	TypoCompanyName,
	UserNameStyled,
	UserRoleStyled,
	ContentContainer,
	StatusBar,
	Offers,
	BidPrice,
	Bid,
	CompanyInfo,
} from './styles';
import type { IInviteCard } from './types';
import {
	getStatusBar,
	getUserBranchInfoOptions,
	getUserBusiness,
	getUserInfoOptions,
	getUserName,
	getUserRoleName,
} from './utils';
import { InviteCountTooltip } from './InviteCountTooltip';

const InviteCard: FC<IInviteCard> = ({
	isActive = true,
	user,
	isInviteDisabled = false,
	isViewer = false,
	onClick = noop<[]>,
	onEdit = noop,
	onDelete = noop,
	menuOptions = [
		{ label: 'Edit', onClick: onEdit },
		{ label: 'Delete', onClick: onDelete },
	],
	customRole,
	customOnTitleLabel,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const currentUser = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);
	const questionnaire = useSelector(selectTransactionQuestionnaire) as IExtendedQuestionnaireTransaction;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = !!anchorEl;
	const isExistingUser = !!user.uid || user.isPlaceholder;
	const isAddedByMe = user.addedById === currentUser.uid;

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const [tooltip, setTooltip] = useState({
		user: false,
		company: false,
	});
	const isPrincipal = user.role === 'buyer' || user.role === 'seller';
	const isDesktop = useResponsive('up', 'lg');
	const handleTooltip = (el: string) => setTooltip((prev) => ({ ...prev, [el]: !prev[el] }));

	useEffect(() => {
		if (isDesktop) setTooltip({ user: false, company: false });
	}, [isDesktop]);

	const handleClose =
		(onAction = noop<[]>) =>
		() => {
			onAction();
			setAnchorEl(null);
		};

	const isAgreedOfferAgreed = transaction.agreedOffer?.agreedBy.some((id) => id === user.uid);

	const { business, branch } = useMemo(() => getUserBusiness(user), [user]);
	const statusBar = getStatusBar(transaction, currentUser, user, questionnaire, enqueueSnackbar);

	const branchInfoOptions = getUserBranchInfoOptions(user, enqueueSnackbar);
	const userInfoOptions = getUserInfoOptions(user, questionnaire, enqueueSnackbar);

	const personalDetails = questionnaire?.personal_details?.[user.uid];

	if (!currentUser.isAdmin && currentUser.uid !== user.uid && user.role === 'solicitor' && !user.approved) {
		return (
			<CardStyled
				isActive={isActive}
				isCurrentUser={user.uid === currentUser.uid}
				isExistingUser={isExistingUser}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					pb: '35px',
					px: '15px',
					backgroundColor: '#F4F6F8',
				}}
			>
				<Typography fontSize='20px' fontWeight={500} textAlign='center'>
					<span style={{ fontSize: '40px' }}>🔍</span> <br />
					Matching you with the best
					<br />
					solicitor for your transaction...
				</Typography>
			</CardStyled>
		);
	}

	return (
		<CardStyled isActive={isActive} isCurrentUser={user.uid === currentUser.uid} isExistingUser={isExistingUser}>
			{isExistingUser && statusBar.isVisible && (
				<StatusBar color={statusBar.color} textColor={statusBar.textColor}>
					{statusBar.title}
				</StatusBar>
			)}
			<ContentContainer>
				{isExistingUser && !!menuOptions.length && (
					<>
						<EditIconBtnStyled
							id='settings'
							aria-controls={open ? 'fade-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
						>
							<Iconify icon='bx:dots-vertical-rounded' />
						</EditIconBtnStyled>
						<MenuPopover
							id='fade-menu'
							anchorEl={anchorEl}
							transformOrigin={{ vertical: 'top', horizontal: 'left' }}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
							disabledArrow
							open={open}
							onClose={handleClose()}
							TransitionComponent={Fade}
							sx={MenuPopoverStyle}
						>
							{menuOptions.map((option) => {
								const isComponent = typeof option.label === 'function';
								const label = isComponent ? <option.label handleClose={handleClose()} /> : option.label;

								return (
									<MenuItem
										key={option.label}
										onClick={isComponent ? () => option.onClick(handleClose()) : handleClose(option.onClick)}
									>
										{label}
									</MenuItem>
								);
							})}
						</MenuPopover>
					</>
				)}

				<Box display='flex' columnGap='15px' pt='7px'>
					{isExistingUser && <ProfilePhoto photo={user.profilePhoto} givenNames={user.givenNames ?? ''} />}
					<Box width='100%'>
						<UserNameStyled variant='h6' editable>
							{userInfoOptions.length && (user.canSeeDetails || currentUser.isAdmin) ? (
								<CustomTooltip
									open={tooltip.user}
									onMouseEnter={() => handleTooltip('user')}
									onClose={() => handleTooltip('user')}
									title={<InviteCardTooltip fields={userInfoOptions} />}
								>
									<TextUserName variant='h6'>{getUserName(user)}</TextUserName>
								</CustomTooltip>
							) : (
								<TextUserName variant='h6'>{getUserName(user)}</TextUserName>
							)}
						</UserNameStyled>
						{isExistingUser && <UserRoleStyled variant='body1'>{customRole ?? getUserRoleName(user)}</UserRoleStyled>}
						{(user.titleGivenNames || user.titleLastNames) && (
							<UserRoleStyled variant='body1'>
								{customOnTitleLabel ?? 'On title as'}:{' '}
								<b>{[user.titleGivenNames, user.titleLastNames].filter(Boolean).join(' ')}</b>
							</UserRoleStyled>
						)}
						{(currentUser.isAdmin || isAddedByMe) && !user.approved && <InviteCountTooltip user={user} />}
					</Box>
				</Box>
			</ContentContainer>
			<ContentContainer>
				<Offers>
					{user.offer && user.offer.isActive && isViewer && (
						<Bid color='info'>
							Offered: <br />
							<NoBreak>
								<BidPrice>{formatBidNumber(user.offer.price)}</BidPrice>
							</NoBreak>
						</Bid>
					)}
					{!isAgreedOfferAgreed && user.counterOffer && user.counterOffer.isActive && isViewer && (
						<Bid color='error'>
							Asked: <br />{' '}
							<NoBreak>
								<BidPrice>{formatBidNumber(user.counterOffer.price)}</BidPrice>
							</NoBreak>
						</Bid>
					)}
				</Offers>
			</ContentContainer>

			{isExistingUser && !isPrincipal && (
				<CompanyInfo>
					<Box>
						<CompanyStyled>
							<TypoCompanyName hasLogo={!!branch.logo}>{business.name}</TypoCompanyName>
							{!!branchInfoOptions.length && (
								<CustomTooltip
									open={tooltip.company}
									onMouseEnter={() => handleTooltip('company')}
									onClose={() => handleTooltip('company')}
									title={<InviteCardTooltip fields={branchInfoOptions} />}
								>
									<span>
										<Iconify icon='material-symbols:info' width={20} height={20} color='#C7C7C7' />
									</span>
								</CustomTooltip>
							)}
						</CompanyStyled>
						{user.branch && <TypoBranchName hasLogo={!!branch.logo}>{branch.name}</TypoBranchName>}
					</Box>
					{branch.logo && <InviteLogo src={branch.logo} />}
				</CompanyInfo>
			)}
			{isPrincipal &&
				personalDetails?.personal?.correspondence &&
				!personalDetails?.personal.tax?.is_legal_person &&
				(personalDetails?.personal.tax?.company_details?.name ||
					personalDetails?.personal.tax?.company_details?.number) && (
					<CompanyInfo>
						<Box>
							<TypoCompanyName hasLogo={false}>{personalDetails?.personal.tax.company_details?.name}</TypoCompanyName>
							<TypoBranchName hasLogo={false}>{personalDetails?.personal.tax.company_details?.number}</TypoBranchName>
						</Box>
					</CompanyInfo>
				)}
			{!isExistingUser && (
				<ContentContainer>
					<Button
						disabled={isInviteDisabled}
						variant='contained'
						size='large'
						onClick={onClick}
						sx={{
							position: 'absolute',
							right: '20px',
							bottom: '24px',
							width: '171px',
						}}
					>
						Invite
					</Button>
				</ContentContainer>
			)}
		</CardStyled>
	);
};

export default InviteCard;
