import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import acceptQuote from 'src/api/quote/accept-quote';
import LoadingScreen from 'src/components/LoadingScreen';

const AcceptQuote = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const quoteId = searchParams.get('id');
	const token = searchParams.get('token');

	useEffect(() => {
		const setup = async () => {
			try {
				if (!quoteId || !token) {
					navigate('/auth/login', { replace: true });

					return;
				}

				await acceptQuote({ id: quoteId, token });

				navigate(`/quote/${quoteId}`, { replace: true });
			} catch (e) {
				console.log('e', e);

				navigate('/auth/login', { replace: true });
			}
		};

		setup();
	}, [quoteId, token]);

	return <LoadingScreen />;
};

export default AcceptQuote;
