/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from 'react';
import { Table } from 'src/components';
import { TableColumn } from 'src/components/common/Table/types';
import { Box, Typography } from '@mui/material';
import { transactionStatusIcon } from 'src/components/TransactionSummary/constants';
import { IDashboardTransactionSummary } from 'src/redux/types';
import { Link } from 'react-router-dom';
import { PATH_TRANSACTIONS } from 'src/routes/paths';
import { formatDate, formatPrice } from 'src/utils/transaction/summary';
import Row from 'src/components/common/Table/components/Row';
import { OrderByDirection } from 'firebase/firestore';
import { formatCompanies, formatUsers } from 'src/components/TransactionSummary/utils';
import { useSelector } from 'react-redux';
import { getSummariesThunk, selectSummaries, selectSummariesFilters } from 'src/redux/slices/tableSlice';
import { isEqual } from 'lodash';
import { dispatch } from 'src/redux/store';
import { selectUser } from 'src/redux/slices/auth';
import PinCell from '../../PinCell';
import { getTransactionIconColorByDaysCount } from './utils';
import { UsersList } from './styled';

const SummariesTable = () => {
	const summaries = useSelector(selectSummaries, isEqual);
	const user = useSelector(selectUser);
	const filters = useSelector(selectSummariesFilters, isEqual);
	const [order, setOrder] = useState<OrderByDirection>('asc');
	const [orderBy, setOrderBy] = useState<string>('lastUpdatedOn');

	const handleSortChange = (params) => {
		setOrder(params.order);
		setOrderBy(params.orderBy);
	};

	useEffect(() => {
		dispatch(
			getSummariesThunk({
				order,
				orderBy,
				creationDateFrom: filters.creationDate?.gte,
				creationDateTo: filters.creationDate?.lte,
				latestActionDateFrom: filters.latestActionDate?.gte,
				latestActionDateTo: filters.latestActionDate?.lte,
				...(filters.states?.length && { states: filters.states.map((s) => s.key) }),
				...(filters.users?.length && { userIds: filters.users.map((s) => s.id) }),
			}),
		);
	}, [order, orderBy, filters]);

	const columns: TableColumn<IDashboardTransactionSummary>[] = [
		{
			name: '',
			key: 'isPinned',
			getLabel: (row) => (
				<Box display='flex' justifyContent='center'>
					<PinCell
						isPinned={!!user.settings.pinnedTransactions?.some((t) => t.id === row.id)}
						id={row.id}
						isTransaction
					/>
				</Box>
			),
			sortable: false,
			sx: { pr: 0, minWidth: '75px' },
		},
		{
			name: '',
			key: 'status',
			sortable: false,
			getLabel: (row) => {
				const statusIcon = transactionStatusIcon[row.status.status];

				return row.state === 'WITHDRAWAL' ? (
					<Typography textAlign='center' fontSize='25px'>
						💀
					</Typography>
				) : row.state === 'FALL_THROUGH' ? (
					<Typography textAlign='center' fontSize='25px'>
						💔
					</Typography>
				) : row.state === 'DEFUNCT' ? (
					<Typography textAlign='center' fontSize='25px'>
						💸
					</Typography>
				) : row.state === 'DEMO' ? (
					<Typography textAlign='center' fontSize='25px'>
						🎠
					</Typography>
				) : row.state === 'STANDBY' ? (
					<Typography fontWeight={900} borderRadius='50%' textAlign='center' fontSize='20px'>
						{row.frozenDays ?? '-'}
					</Typography>
				) : typeof row.status.days === 'number' ? (
					<Typography
						sx={{ color: getTransactionIconColorByDaysCount(row.status) }}
						fontWeight={900}
						borderRadius='50%'
						textAlign='center'
						fontSize='20px'
					>
						{row.status.days}
					</Typography>
				) : (
					<Typography textAlign='center' fontSize='25px'>
						{statusIcon}
					</Typography>
				);
			},
		},
		{
			name: 'Name',
			key: 'name',
			sx: { pl: '10px' },
			getLabel: (row) => <Link to={PATH_TRANSACTIONS.view(row.id)}>{row.name}</Link>,
			width: 400,
		},
		// { name: 'Postcode', key: 'postcode', getLabel: (row) => row.general.postcode, width: 150 },
		// { name: 'UPRN', key: 'uprn', getLabel: (row) => row.general.uprn, width: 150 },
		{
			name: 'Last Updated On',
			key: 'lastUpdatedOn',
			getLabel: (row) => formatDate(row.lastUpdatedOn, 'YYYY-MM-DD hh:mm A'),
			sx: { whiteSpace: 'nowrap' },
		},
		{
			name: 'Created On',
			key: 'createdDate',
			getLabel: (row) => formatDate(row.createdAt),
			sx: { whiteSpace: 'nowrap' },
		},
		// {
		// 	name: 'Time from Instruction to Sale Agreed',
		// 	key: 'timeFromInstructionToSaleAgreed',
		// 	getLabel: (row) => formatDate(row.general.createdDate),
		// 	width: 250,
		// },
		// {
		// 	name: 'Time from Sale Agreed to Exchange',
		// 	key: 'timeFromSaleAgreedToExchange',
		// 	getLabel: (row) => formatDate(row.general.createdDate),
		// 	width: 250,
		// },
		// {
		// 	name: 'Duration',
		// 	key: 'duration',
		// 	getLabel: (row) => formatDaysDuration(row.duration),
		// 	width: 200,
		// },
		// {
		// 	name: 'Instruction Date',
		// 	key: 'instructionDate',
		// 	getLabel: (row) => formatDate(row.general.instructionDate),
		// 	width: 175,
		// },
		// {
		// 	name: 'Seller Date',
		// 	key: 'sellerDate',
		// 	getLabel: (row) => formatDate(row.general.sellerDate),
		// 	width: 175,
		// },
		// {
		// 	name: 'Property Date',
		// 	key: 'propertyDate',
		// 	getLabel: (row) => formatDate(row.general.propertyDate),
		// 	width: 175,
		// },
		// {
		// 	name: 'Buyer Date',
		// 	key: 'buyerDate',
		// 	getLabel: (row) => formatDate(row.general.buyerDate),
		// 	width: 175,
		// },
		// {
		// 	name: 'Legals Date',
		// 	key: 'legalsDate',
		// 	getLabel: (row) => formatDate(row.general.legalsDate),
		// 	width: 175,
		// },
		{
			name: 'Ask Price',
			key: 'askPrice',
			getLabel: (row) => formatPrice(row.askPrice),
			sx: { whiteSpace: 'nowrap' },
		},
		// {
		// 	name: 'Our margin',
		// 	key: 'ourMargin',
		// 	getLabel: (row) => formatPrice(row.general.margin.withVat),
		// 	width: 175,
		// },
		{
			name: 'Sellers',
			key: 'sellers',
			getLabel: (row) => (
				<UsersList sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
					{formatCompanies(row.companies.filter((c) => c.side === 'sell'))}
					{formatUsers(row.sellers)}
				</UsersList>
			),
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		// {
		// 	name: 'Seller Quote (incl VAT)',
		// 	key: 'sellerQuote',
		// 	getLabel: (row) => formatPrice(row.sellSide.quote?.withVat),
		// 	width: 250,
		// },
		{
			name: 'Sell Side Agents',
			key: 'sellSideAgents',
			getLabel: (row) => <UsersList>{formatUsers(row.sellSideAgents)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		// {
		// 	name: 'Sell Side Agency Rate',
		// 	key: 'sellSideAgencyRate',
		// 	getLabel: (row) => formatPercent(row.sellSide.agencyRate?.withVat),
		// 	width: 175,
		// },
		// {
		// 	name: 'Sell Side Agency Fee',
		// 	key: 'sellSideAgencyFee',
		// 	getLabel: (row) => formatPrice(row.sellSide.agencyFee?.withVat),
		// 	width: 175,
		// },
		// {
		// 	name: 'Sell Side Referral Fee',
		// 	key: 'sellSideReferralFee',
		// 	getLabel: (row) => formatPrice(row.sellSide.referralFee?.withVat),
		// 	width: 175,
		// },
		{
			name: 'Sell Side Solicitors',
			key: 'sellSideSolicitors',
			getLabel: (row) => <UsersList>{formatUsers(row.sellSideSolicitors)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		{
			name: 'Sell Side Referrer(s)',
			key: 'sellReferrers',
			getLabel: (row) => <UsersList>{formatUsers(row.sellReferrers)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		// {
		// 	name: 'Sell Side Legal Budget (incl VAT)',
		// 	key: 'sellSideLegalFee',
		// 	getLabel: (row) => formatPrice(row.sellSide.legalFee?.withVat),
		// 	width: 175,
		// },
		{
			name: 'Buyers',
			key: 'buyers',
			getLabel: (row) => <UsersList>{formatUsers(row.buyers)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		// {
		// 	name: 'Buyer Quote (incl VAT)',
		// 	key: 'buyerQuote',
		// 	getLabel: (row) => formatPrice(row.buySide.quote?.withVat),
		// 	width: 175,
		// },
		{
			name: 'Buy Side Agents',
			key: 'buySideAgents',
			getLabel: (row) => <UsersList>{formatUsers(row.buySideAgents)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		// {
		// 	name: 'Buy Side Agency Rate',
		// 	key: 'buySideAgencyRate',
		// 	getLabel: (row) => formatPercent(row.buySide.agencyRate?.withVat),
		// 	width: 175,
		// },
		// {
		// 	name: 'Buy Side Agency Fee',
		// 	key: 'buySideAgencyFee',
		// 	getLabel: (row) => formatPrice(row.buySide.agencyFee?.withVat),
		// 	width: 175,
		// },
		// {
		// 	name: 'Buy Side Referral Fee',
		// 	key: 'buySideReferralFee',
		// 	getLabel: (row) => formatPrice(row.buySide.referralFee?.withVat),
		// 	width: 175,
		// },
		{
			name: 'Buy Side Solicitors',
			key: 'buySideSolicitors',
			getLabel: (row) => <UsersList>{formatUsers(row.buySideSolicitors)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		{
			name: 'Buy Side Referrer(s)',
			key: 'buyReferrers',
			getLabel: (row) => <UsersList>{formatUsers(row.buyReferrers)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		{
			name: 'Buy Side Mortgage Brokers',
			key: 'buySideBroker',
			getLabel: (row) => <UsersList>{formatUsers(row.buySideMortgageBrokers)}</UsersList>,
			sx: { whiteSpace: 'nowrap' },
			sortable: false,
		},
		// {
		// 	name: 'Buy Side Legal Budget (incl VAT)',
		// 	key: 'buySideLegalFee',
		// 	getLabel: (row) => formatPrice(row.buySide.legalFee?.withVat),
		// 	width: 175,
		// },
	];

	const totalColumns = useMemo(() => {
		if (!summaries.data.total) return [];

		return columns.map((c) => {
			const isExistingColumn = Object.hasOwn(summaries.data.total ?? {}, c.key);

			return { ...c, getLabel: (r) => (isExistingColumn ? c.getLabel?.(r) : '') };
		});
	}, [summaries.data.total, columns]);

	return (
		<Table
			columns={columns}
			data={{
				data: {
					data: summaries.data.list,
					limit: 0,
					page: 0,
					totalCount: 0,
					totalPages: 0,
					sortOrder: order,
					orderBy,
				},
				isInitialized: summaries.isInitialized,
			}}
			handleSortChange={handleSortChange}
			withPagination={false}
			stickyHeader
			rowKeyField='id'
			width={1750}
			headSx={{ borderBottom: '1px solid grey' }}
			headCellSx={{ px: '5px' }}
			cellSx={{ py: '10px', px: '5px' }}
		>
			{summaries.data.total ? (
				<Row
					columns={totalColumns}
					row={summaries.data.total}
					sx={{ background: 'lightgrey' }}
					cellSx={{ py: '10px', px: '5px' }}
				/>
			) : null}
		</Table>
	);
};

export default SummariesTable;
