import { v4 as uuidv4 } from 'uuid';
import { formatBidValue } from './formatBid';

export const validateEmail = (email: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const parseStringToJson = (content: string) => {
	try {
		return JSON.parse(content);
	} catch {
		return content;
	}
};

export const convertConstructionAgeToYears = (age: string) => age.replace(/England and Wales:/g, '').trim();
export const convertPriceToPricePerPeriod = (price: number, period = 'year') =>
	`£${formatBidValue(price)} per ${period}`;

export const getFileNameWithoutExtension = (name: string) =>
	name.includes('.') ? name.substring(0, name.lastIndexOf('.')) : name;

export const getFileNameExtension = (name: string) => {
	const index = name.lastIndexOf('.');

	return index !== -1 ? name.slice(index) : '';
};

export const getStringAfterSymbols = (string: string, combination) => {
	const index = string.indexOf(combination);

	return index !== -1 ? string.substring(index + combination.length) : null;
};

export const randomUUID = () => uuidv4();

export const convertStringToSortingNumber = (str: string) =>
	// eslint-disable-next-line no-param-reassign
	str.split('').reduce((acc, char) => (acc += char.charCodeAt(0)), 0);
