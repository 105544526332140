import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { selectModal } from 'src/redux/slices/modal';
import { getTransactionOverviewThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { RHFRadioGroup } from 'src/components/hook-form';
import {
	transactionBuyerRelationship,
	transactionPartieSides,
	transactionSellerRelationship,
} from 'src/pages/transactions/constants';
import { selectProperty } from 'src/redux/slices/property';
import { Task } from 'src/redux/types';
import { fullVersionEmail } from 'src/constants/common';
import { EmailLink } from 'src/components/common';
import confirmTransactionRelationship from 'src/api/transaction/confirm-transaction-relationship';
import syncTransactionTasks from 'src/api/transaction/sync-transaction-tasks';
import { ConfirmRelationshipForm } from './types';
import { validationSchema } from './validation';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<Task>);
	const property = useSelector(selectProperty);
	const transaction = useSelector(selectTransactionOverview);
	const [isLoading, setIsLoading] = useState(false);
	const form = useForm<ConfirmRelationshipForm>({
		resolver: yupResolver(validationSchema),
		defaultValues: {},
	});

	const isBuySide = payload.metadata?.side === transactionPartieSides.buy.key;

	const handleSubmit = async (data: ConfirmRelationshipForm) => {
		try {
			if (data.relationship === 'other') return;

			setIsLoading(true);

			await confirmTransactionRelationship({ id: transaction.id, relationship: data.relationship });
			await syncTransactionTasks(transaction.id);

			await dispatch(getTransactionOverviewThunk({ id: transaction.id }));

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message);
		} finally {
			setIsLoading(false);
		}
	};

	const relationship = form.watch('relationship');
	const isOtherRelationship = relationship === 'other';

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle} pt={0}>
					CONFIRM RELATIONSHIP
				</Typography>
				<Alert severity='warning'>
					Your name does not appear on the register for <b>{property.name}</b>.
				</Alert>
				<br />
				Who are you in relation to the {isBuySide ? 'buyer' : 'proprietor'}(s)?
				<br /> <br />
				<RHFRadioGroup
					name='relationship'
					options={[
						...(!isBuySide
							? [
									{
										key: transactionSellerRelationship.executor.key,
										name: (
											<>
												<b>Executor</b> - you have the legal authority to act on behalf of a <b>deceased</b> proprietor
											</>
										),
									},
									{
										key: transactionSellerRelationship.attorney.key,
										name: (
											<>
												<b>Attorney</b> - you have the legal authority to act on behalf of a <b>living</b> proprietor
											</>
										),
									},
									{
										key: transactionSellerRelationship.trustee.key,
										name: (
											<>
												<b>Trustee</b> - you hold and manage this property in trust for the owner.
											</>
										),
									},
									{
										key: transactionSellerRelationship.proxy.key,
										name: (
											<>
												<b>Helper</b> - here to help, but with no legal authority to act on behalf of the proprietor(s)
											</>
										),
									},
							  ]
							: [
									{
										key: transactionBuyerRelationship.buyer.key,
										name: (
											<>
												<b>Buyer</b> - a future owner of this property
											</>
										),
									},
									{
										key: transactionBuyerRelationship.proxy.key,
										name: (
											<>
												<b>Helper</b> - family and friends, just here to help
											</>
										),
									},
									{
										key: transactionBuyerRelationship.attorney.key,
										name: (
											<>
												<b>Attorney</b> - a helper with the legal authority to act on behalf of a buyer
											</>
										),
									},
							  ]),
						{
							key: 'other',
							name: <b>Other</b>,
						},
					]}
				/>
				{isOtherRelationship && (
					<>
						<br />
						<Box
							height={29}
							sx={{ fontSize: 18 }}
							display='flex'
							alignItems='center'
							justifyContent='center'
							whiteSpace='pre-wrap'
						>
							Please send us an email at <EmailLink email={fullVersionEmail} />
						</Box>
					</>
				)}
				<ActionButtons mt={'20px'}>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton variant='contained' size='large' fullWidth type='submit' loading={isLoading}>
						Confirm
					</LoadingButton>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

const ConfirmRelationshipModal = () => (
	<Modal name={modals.confirmRelationship} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default ConfirmRelationshipModal;
