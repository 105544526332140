import { TransactionRelationship } from 'src/pages/transactions/types';
import { call } from '../@';

export type ConfirmTransactionRelationshipDto = {
	id: string;
	relationship: TransactionRelationship;
};

export default async function confirmTransactionRelationship(dto: ConfirmTransactionRelationshipDto) {
	return await call('transaction-confirmRelationship', dto);
}
