import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import authReducer from './slices/auth';
import headerReducer from './slices/headerSlice';
import modalReducer from './slices/modal';
import navbarReducer from './slices/navSlice';
import propertyReducer from './slices/property';
import propertyQuestionnaireReducer from './slices/propertyQuestionnaire';
import tableReducer from './slices/tableSlice';
import transactionReducer from './slices/transaction';
import transactionQuestionnaireReducer from './slices/transactionQuestionnaire';
import permissionsReducer from './slices/permissions';
import documentsReducer from './slices/documents';
import roomsReducer from './slices/rooms';
import quoteReducer from './slices/quoteSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
	key: 'root',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
};

const rootReducer = combineReducers({
	auth: authReducer,
	modal: modalReducer,
	navbar: navbarReducer,
	actionMenu: headerReducer,
	property: propertyReducer,
	table: tableReducer,
	transaction: transactionReducer,
	propertyQuestionnaire: propertyQuestionnaireReducer,
	transactionQuestionnaire: transactionQuestionnaireReducer,
	permissions: permissionsReducer,
	documents: documentsReducer,
	rooms: roomsReducer,
	quote: quoteReducer,
});

export { rootPersistConfig, rootReducer };
