/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { dispatch } from 'src/redux/store';
import { BrandModal } from 'src/components/common';
import { selectModal } from 'src/redux/slices/modal';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { CompanyBranch } from 'src/redux/types';
import { getCompaniesThunk, updateCompanyBranchThunk } from 'src/redux/slices/tableSlice';
import Field from 'src/components/common/Field';
import moment from 'moment';
import { InviteLogo } from 'src/components/InviteCard/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFAutocomplete, RHFPhoneNumberInput, RHFTextField } from 'src/components';
import GoogleAddressAutocomplete from 'src/components/GoogleAddressAutoComplete';
import RHFEmailsField from 'src/components/hook-form/RHFEmailsField';
import { UpdateBranchForm } from './types';
import { updateBranchValidationSchema } from './validation';
import { companyBranchStatusOptions, companyBranchTypeOptions } from './constants';

const Content = () => {
	const { payload } = useSelector(selectModal<CompanyBranch>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const form = useForm<UpdateBranchForm>({
		resolver: yupResolver(updateBranchValidationSchema),
		defaultValues: {
			address: { ...payload.address, coordinates: payload.coordinates },
			emails: payload.emails,
			name: payload.name,
			status: payload.status,
			telephone: payload.telephone,
			website: payload.website,
			type: payload.type,
		},
	});

	const handleUpdate = async (data: UpdateBranchForm) => {
		try {
			setIsLoading(true);

			await dispatch(updateCompanyBranchThunk({ id: payload.id, companyId: payload.companyId, ...data })).unwrap();

			dispatch(getCompaniesThunk());
			enqueueSnackbar('Company branch updated!');
			handleCloseModal();
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...form}>
			<BrandModal.Title title='UPDATE COMPANY BRANCH' />
			<Box py='15px'>
				<Box display='flex' flexDirection='column' gap='10px'>
					<Field label='Name' value={<RHFTextField name='name' label='Enter a name' />} row={false} />
					<Field
						label='Type'
						value={<RHFAutocomplete name='type' label='Select a type' options={companyBranchTypeOptions} />}
						row={false}
					/>
					<Field
						label='Status'
						value={<RHFAutocomplete name='status' label='Select a status' options={companyBranchStatusOptions} />}
						row={false}
					/>
					<Field
						label='Address'
						value={
							<GoogleAddressAutocomplete
								name='address.formattedAddress'
								label='Select an address'
								setValue={form.setValue}
								clearErrors={form.clearErrors}
							/>
						}
						row={false}
					/>
					<Field
						label='Telephone'
						value={<RHFPhoneNumberInput name='telephone' label='Enter a telephone' />}
						row={false}
					/>
					<Field label='Website' value={<RHFTextField name='website' label='Enter a website' />} row={false} />
					<Field label='Emails' value={<RHFEmailsField />} row={false} />
					<Field value={<InviteLogo src={payload.logo} maxHeight='100px !important' />} />
					<Field label='Created At' value={moment(payload.createdAt).format('YYYY-MM-DD')} />
				</Box>
				<ActionButtons mt='20px'>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton
						variant='contained'
						size='large'
						fullWidth
						onClick={form.handleSubmit(handleUpdate)}
						loading={isLoading}
					>
						Update
					</LoadingButton>
				</ActionButtons>
			</Box>
		</FormProvider>
	);
};

const UpdateCompanyBranchModal = () => (
	<Modal name={modals.updateCompanyBranch} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default UpdateCompanyBranchModal;
