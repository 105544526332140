import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router';
import LoadingScreen from 'src/components/LoadingScreen';
import { selectIsAuthenticationLoading, selectIsInitialized, selectUser } from 'src/redux/slices/auth';
import { useSelector } from 'src/redux/store';
import { UserOnboardingStatus } from 'src/types';

const OnboardingGuard: FC<PropsWithChildren> = ({ children }) => {
	const user = useSelector(selectUser);
	const location = useLocation();
	const isInitialized = useSelector(selectIsInitialized);
	const isAuthenticationLoading = useSelector(selectIsAuthenticationLoading);

	if (!isInitialized) return <LoadingScreen />;
	if (isInitialized && !user && !isAuthenticationLoading) return <Navigate to='/auth/login' />;
	if (user?.onboarding?.currentPage && location.pathname !== user.onboarding.currentPage) {
		return <Navigate to={user.onboarding.currentPage} />;
	}

	if (user.onboarding?.status === UserOnboardingStatus.COMPLETED || !user.onboarding) {
		return <Navigate to={sessionStorage.getItem('redirectTo') ?? '/dashboard'} />;
	}

	return <>{children}</>;
};

export default OnboardingGuard;
