import { professionsById } from 'src/constants';
import { TransactionSummaryReferrer } from 'src/redux/types';

export const getReferrerLabel = (user: TransactionSummaryReferrer) => (
	<div>
		<b>
			{user.givenNames} {user.lastNames}
		</b>
		{user.professionId ? ` -  ${professionsById[user.professionId]?.name}` : ''}
	</div>
);
