import { TransactionSide } from 'src/pages/transactions/types';
import { call } from '../@';

export type DeleteTransactionReferrerPayload = {
	userId: string;
	id: string;
	side: TransactionSide;
};

export default async function deleteTransactionReferrer(payload: DeleteTransactionReferrerPayload) {
	return await call('transaction-deleteReferrer', payload);
}
