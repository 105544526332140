import { Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import useLoading from 'src/hooks/useLoading';
import { deleteUserBankAccountThunk, getUserBankAccountsThunk } from 'src/redux/slices/auth';
import { useSelector } from 'react-redux';
import { dispatch } from 'src/redux/store';
import { selectModal } from 'src/redux/slices/modal';
import { selectCountries } from 'src/redux/slices/tableSlice';
import { getBankAccountLabel } from 'src/constants/surveyJson/common';
import { UserBankAccount } from 'src/redux/types';

const Content = () => {
	const countries = useSelector(selectCountries);
	const { payload } = useSelector(selectModal<UserBankAccount>);
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();

	const handleDelete = async () => {
		try {
			setIsLoading(true);

			await dispatch(deleteUserBankAccountThunk({ id: payload.id })).unwrap();

			handleCloseModal();

			dispatch(getUserBankAccountsThunk());
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Typography sx={styledMainTitle} pt={0}>
				DELETE BANK ACCOUNT
			</Typography>
			<Typography>Are you sure you want to delete this bank account? This action cannot be reversed.</Typography>
			<Typography color='gray' mt='10px'>
				{getBankAccountLabel(countries, payload)}
			</Typography>
			<ActionButtons mt='20px'>
				<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' size='large' fullWidth loading={isLoading} onClick={handleDelete}>
					Delete
				</LoadingButton>
			</ActionButtons>
		</>
	);
};

const DeleteBankAccountModal = () => (
	<Modal name={modals.deleteBankAccount} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default DeleteBankAccountModal;
