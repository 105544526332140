/* eslint-disable @typescript-eslint/no-explicit-any */
import { isValidDate } from 'src/utils/common';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { IDashboardTransactionSummaryUser, ITransactionSummaryUser } from 'src/redux/types';
import { professionsById } from 'src/constants';
import { TransactionSummariesFilters } from './types';

export const convertUsersToOptions = (users: ITransactionSummaryUser[]) =>
	users.map(({ id, givenNames, lastNames }) => ({ id, name: `${givenNames} ${lastNames}` }));

// export const convertFiltersToForm = (filters: TransactionSummariesFilters) => ({
// 	...filters,
// 	...(filters.instructionDate && {
// 		instructionDate: {
// 			gte:
// 				filters.instructionDate.gte instanceof Timestamp
// 					? filters.instructionDate.gte.toDate()
// 					: filters.instructionDate.gte,
// 			lte:
// 				filters.instructionDate.lte instanceof Timestamp
// 					? filters.instructionDate.lte.toDate()
// 					: filters.instructionDate.lte,
// 		},
// 	}),
// 	...(filters.latestActionDate && {
// 		latestActionDate: {
// 			gte:
// 				filters.latestActionDate.gte instanceof Timestamp
// 					? filters.latestActionDate.gte.toDate()
// 					: filters.latestActionDate.gte,
// 			lte:
// 				filters.latestActionDate.lte instanceof Timestamp
// 					? filters.latestActionDate.lte.toDate()
// 					: filters.latestActionDate.lte,
// 		},
// 	}),
// });

const isValueMatching = (value: string | number | Date, filterValue: string | number | Date): boolean => {
	if (
		(typeof filterValue === 'string' || typeof filterValue === 'number') &&
		(typeof value === 'string' || typeof value === 'number')
	) {
		return value.toString().toLowerCase().includes(filterValue.toString().toLowerCase());
	}
	if (typeof filterValue === 'string' && isValidDate(filterValue) && isValidDate(value)) {
		return isSameDay(new Date(filterValue), new Date(value));
	}
	if (typeof filterValue === 'number' && typeof value === 'number') {
		return value === filterValue;
	}
	return false;
};

const isValueIncluded = (value: string | Array<string | { id: string }>, includeValue: string): boolean => {
	if (typeof value === 'string' || typeof value === 'number') {
		return value.toString().toLowerCase().includes(includeValue.toLowerCase());
	}
	if (Array.isArray(value)) {
		return value.some((item) =>
			typeof item === 'string' ? item.toLowerCase().includes(includeValue.toLowerCase()) : item.id === includeValue,
		);
	}
	return false;
};

const isValueInRange = (
	value: string | number,
	rangeValue: string | number | Date | Timestamp,
	compareFn: (a: Date, b: Date) => boolean,
): boolean => {
	if (rangeValue instanceof Timestamp && isValidDate(value)) {
		return isSameDay(rangeValue.toDate(), new Date(value)) || compareFn(new Date(value), rangeValue.toDate());
	}
	if (rangeValue instanceof Date && isValidDate(value)) {
		return isSameDay(new Date(rangeValue), new Date(value)) || compareFn(new Date(value), new Date(rangeValue));
	}
	if (typeof rangeValue === 'string' && isValidDate(rangeValue) && isValidDate(value)) {
		return isSameDay(new Date(rangeValue), new Date(value)) || compareFn(new Date(value), new Date(rangeValue));
	}
	if (typeof rangeValue === 'number' && typeof value === 'number') {
		return value >= rangeValue;
	}
	return false;
};

export const filterTransactionSummaries = (filters: TransactionSummariesFilters, summaries: any[]): any[] => {
	const filterEntries = Object.entries(filters);

	return summaries.filter((summary) => {
		for (const [column, params] of filterEntries) {
			const { eq, like, inc, gte, lte } = params as any;
			const { value } = summary[column];

			if (like && !isValueMatching(value, like)) return false;
			if (eq && !isValueMatching(value, eq)) return false;
			if (inc && !isValueIncluded(value, inc)) return false;
			if (gte && !isValueInRange(value, gte, isAfter)) return false;
			if (lte && !isValueInRange(value, lte, isBefore)) return false;
		}

		return true;
	});
};

export const getUserLabel = (user: IDashboardTransactionSummaryUser) =>
	`${user.givenNames} ${user.lastNames}${user.professionId ? ` (${professionsById[user.professionId].name})` : ''}`;
