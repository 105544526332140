import { GeneralTab } from 'src/components/GeneralTab';
import Iconify from 'src/components/Iconify';
import { Documents } from 'src/components/Documents';
import { FC } from 'react';
import TransactionSummary from 'src/components/TransactionSummary';
import TransactionQuestions from 'src/sections/transaction/Questions';
import TransactionEmailTrail from 'src/components/TransactionTrail';
import TransactionJobs from 'src/components/TransactionJobs';
import TransactionParticipants from '../participants';

export const getTransactionTabs = ({
	isAdmin,
	isBuyerViewer,
	isPendingParticipant,
	isSolicitor,
	isVerified,
}: {
	isPendingParticipant: boolean;
	isVerified: boolean;
	isBuyerViewer: boolean;
	isAdmin: boolean;
	isSolicitor: boolean;
}) =>
	[
		...(isSolicitor && !isPendingParticipant && isVerified
			? [
					{
						value: 'summary',
						label: 'Progress',
						icon: <Iconify icon={'codicon:graph'} width={20} height={20} />,
						component: TransactionSummary,
					},
			  ]
			: []),
		{
			value: 'overview',
			label: 'Overview',
			icon: <Iconify icon={'ph:house-fill'} width={20} height={20} />,
			component: GeneralTab,
		},
		{
			value: 'participants',
			label: 'Participants',
			icon: <Iconify icon={'heroicons-solid:users'} width={20} height={20} />,
			component: () => <TransactionParticipants />,
		},
		...(!isPendingParticipant || isAdmin
			? [
					{
						value: 'questions',
						label: 'Questions',
						icon: <Iconify icon='material-symbols:checklist' width={20} height={20} />,
						component: () => <TransactionQuestions />,
					},

					...(!isBuyerViewer || isAdmin
						? [
								{
									value: 'documents',
									label: 'Documents',
									icon: <Iconify icon={'fluent:document-48-filled'} width={20} height={20} />,
									component: Documents,
								},
						  ]
						: []),
			  ]
			: []),
		...(isAdmin
			? [
					{
						value: 'summary',
						label: 'Admin',
						icon: <Iconify icon={'codicon:graph'} width={20} height={20} />,
						component: TransactionSummary,
					},
			  ]
			: []),
		...(isAdmin
			? [
					{
						value: 'trail',
						label: 'Trail',
						icon: <Iconify icon='clarity:timeline-line' width={22} height={22} />,
						component: TransactionEmailTrail,
					},
					{
						value: 'jobs',
						label: 'Jobs',
						icon: <Iconify icon='eos-icons:cronjob' width={22} height={22} />,
						component: TransactionJobs,
					},
			  ]
			: []),
	] as {
		value: string;
		label: string;
		icon: JSX.Element;
		// eslint-disable-next-line
		component: FC<any>;
	}[];
