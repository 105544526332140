import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import Iconify from 'src/components/Iconify';
import { UserBankAccount } from 'src/redux/types';
import palette from 'src/theme/palette';
import { getBankAccountLabel } from 'src/constants/surveyJson/common';
import { useSelector } from 'react-redux';
import { selectCountries } from 'src/redux/slices/tableSlice';
import { stopPropagation } from 'src/utils/common';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import Field from '../../../../components/common/Field';
import Files from './components/Files';

const BankAccount: FC<UserBankAccount> = (account) => {
	const countries = useSelector(selectCountries);
	const { country, bank, ...fields } = account;

	const handleDelete = (e) => {
		stopPropagation(e);

		dispatch(openModal({ name: modals.deleteBankAccount, payload: account }));
	};

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<Iconify icon='eva:arrow-down-fill' fontSize={25} color='#A3A3A3' />}
				sx={{ background: palette.light.grey[300], p: '10px' }}
			>
				<Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
					<Typography component='span'>
						{account.bank} ({account.country['2LetterCode']}) {getBankAccountLabel(countries, account, false)}
					</Typography>
					{!account.files.length && (
						<IconButton onClick={handleDelete}>
							<Iconify icon='icon-park-solid:close-one' fontSize='20px' />
						</IconButton>
					)}
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ p: '10px', pb: '0' }}>
				<Field label='Country' value={country.name} />
				<Field label='Bank' value={bank} />
				{country.banking.fields.map((f) => (
					<Field key={f.key} label={f.name} value={fields[f.key]} />
				))}
				<Files {...account} />
			</AccordionDetails>
		</Accordion>
	);
};

export default BankAccount;
