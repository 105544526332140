import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'src/redux/store';
import { selectIsAuthenticated, selectIsInitialized, selectUser, logout } from 'src/redux/slices/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { UserStatus } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import submitQuote from 'src/api/quote/submit-quote';
import LoadingScreen from '../components/LoadingScreen';

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
	const user = useSelector(selectUser);
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const isInitialized = useSelector(selectIsInitialized);
	const { isLoading, setIsLoading } = useLoading();
	const { pathname } = useLocation();

	const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

	useEffect(() => {
		const setup = async () => {
			if (user?.status !== UserStatus.ACTIVE || isLoading) return;

			const quoteId = sessionStorage.getItem('quoteId');

			if (quoteId) {
				setIsLoading(true);

				await submitQuote({ id: quoteId })
					.catch(console.log)
					.finally(() => {
						sessionStorage.removeItem('quoteId');
						setIsLoading(false);
					});
			}
		};

		setup();
	}, [user]);

	if (!isInitialized || isLoading) return <LoadingScreen />;
	if (isInitialized && !user) {
		sessionStorage.setItem('redirectTo', pathname);

		return <Navigate to='/auth/login' />;
	}
	if (user.onboarding?.currentPage) return <Navigate to={user.onboarding.currentPage} />;

	if (!isAuthenticated || user?.status === UserStatus.PENDING) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		dispatch(logout());
		return <Navigate to='/auth/login' {...(user?.status === UserStatus.PENDING && { state: { notVerified: true } })} />;
	}

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
};

export default AuthGuard;
