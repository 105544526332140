import { matchIsValidTel } from 'mui-tel-input';
import { postCodeRegExp } from 'src/constants';
import * as Yup from 'yup';

export const requiredString = Yup.string().required('This field is required');
export const urlString = Yup.string()
	.matches(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/, 'Enter a valid URL')
	.required('Website is required');
export const optionalString = Yup.string().nullable().optional();
export const requiredNumber = Yup.number().required('This field is required');
export const emailString = requiredString.email('Email must be a valid email address');
export const getPhoneNumberString = (required = true) => {
	const schema = Yup.string()
		.nullable()
		.transform((value) => (!value ? null : value))
		.test('isPhoneNumberValid', 'Number must be valid phone number', (value) => {
			if (!required && value === null) return true;

			return matchIsValidTel(value ?? '');
		});

	if (required) return schema.required('This field is required');

	return schema;
};
export const passwordString = requiredString.matches(
	/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})^[^ ]+$/,
	'At least 8 characters, 1 uppercase, 1 lowercase & 1 number.',
);
export const nonEmptyString = requiredString.trim().min(1, 'This field must not be empty');

export const addressSchema = Yup.object().shape({
	postcode: requiredString.matches(postCodeRegExp, 'Post code is not valid'),
	address: requiredString,
	paon: requiredString,
	saon: Yup.string().nullable().optional(),
	street: requiredString,
	locality: Yup.string().nullable().optional(),
	town_city: requiredString,
	district: Yup.string().nullable().optional(),
	county: requiredString,
	latitude: requiredNumber,
	longitude: requiredNumber,
	display_address: requiredString,
	name: Yup.string().nullable().optional(),
});
