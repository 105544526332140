import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import { dispatch, useSelector } from 'src/redux/store';
import { selectModal } from 'src/redux/slices/modal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { getNav, handleCloseModal } from 'src/utils/propertyHelpers';
import { selectPropertySlice } from 'src/redux/slices/property';
import {
	deleteTransaction,
	delistTransaction,
	dropFromProperty,
	dropFromTransaction,
	frozeTransaction,
} from 'src/redux/slices/transaction';
import { ROOTS_DASHBOARD } from 'src/routes/paths';
import modals from 'src/constants/modals';
import { selectUser } from 'src/redux/slices/auth';
import { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';

const DropPropertyModalContent = () => {
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const { payload } = useSelector(
		selectModal<{ propertyId?: string; transactionId?: string; abandon?: boolean; delist?: boolean; delete?: boolean }>,
	);
	const { property } = useSelector(selectPropertySlice);

	const handleDrop = async () => {
		setIsLoading(true);

		if (payload.transactionId) {
			if (user.isAdmin) {
				if (payload.delist) await dispatch(delistTransaction(payload.transactionId));
				if (payload.delete) await dispatch(deleteTransaction(payload.transactionId));
			} else if (payload.abandon) await dispatch(dropFromTransaction(payload.transactionId));
			else await dispatch(frozeTransaction(payload.transactionId));
		}
		if (payload.propertyId) await dispatch(dropFromProperty(payload.propertyId));

		handleCloseModal();
		if (payload.delist ? !user.isAdmin : true) navigate(ROOTS_DASHBOARD);
		setIsLoading(false);
		getNav();
	};

	const actionText = useMemo(() => {
		if (payload.delist) return 'delist';
		if (payload.abandon) return 'abandon';
		if (payload.delete) return 'delete';

		return 'delete';
	}, [payload]);

	return (
		<>
			<Typography sx={styledMainTitle} pt={0} textTransform='uppercase'>
				{actionText} {payload.propertyId ? 'PROPERTY' : 'TRANSACTION'}
			</Typography>
			<Typography fontSize='17px' fontWeight={500} mb={'20px'}>
				Are you sure you want to {actionText} <b>{property?.name}</b>?
			</Typography>
			<ActionButtons>
				<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton
					variant='contained'
					size='large'
					fullWidth
					loading={isLoading}
					onClick={handleDrop}
					sx={{ textTransform: 'capitalize' }}
				>
					{actionText}
				</LoadingButton>
			</ActionButtons>
		</>
	);
};

const DropPropertyModal = () => (
	<Modal name={modals.dropProperty} cardSx={{ maxWidth: '550px', padding: '32px 27px' }}>
		<DropPropertyModalContent />
	</Modal>
);

export default DropPropertyModal;
