import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { Box } from '@mui/system';

const Content = () => (
	<Box height='1000px'>
		<iframe
			title='Identity Verification'
			style={{ border: 'none' }}
			width='100%'
			height='100%'
			allow='camera'
			allowFullScreen
			src='https://meetings-eu1.hubspot.com/andrea-monti-solza'
		/>
	</Box>
);

const QuoteMeetingModal = () => (
	<Modal name={modals.quoteMeeting} cardSx={{ overflow: 'visible', maxWidth: '1000px' }}>
		<Content />
	</Modal>
);

export default QuoteMeetingModal;
