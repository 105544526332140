import { FC, MouseEvent, useState } from 'react';
import { PickerModal } from 'mui-daterange-picker-plus';
import type { DateRange } from 'mui-daterange-picker-plus';
import { Box, FormHelperText, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { stopPropagation } from 'src/utils/common';
import { ICustomDateRange, IDateRangePicker } from './types';
import { DateRangeInput, DateRangePart } from './styles';
import Iconify from '../Iconify';

const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');

const RHFDateRangePicker: FC<IDateRangePicker> = ({ label, name, value, onChange }) => {
	const { control, resetField } = useFormContext();

	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const handleClick = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = !!anchorEl;

	const handleClearFilter = (e) => {
		stopPropagation(e);
		resetField(name);
	};

	return (
		<Box>
			{label && (
				<Typography variant='subtitle1' mb='3px'>
					{label}
				</Typography>
			)}
			<Controller
				name={name}
				control={control}
				render={({ field, fieldState: { error } }) => {
					const handleChange = (newRange: DateRange) => {
						const customDateRange: ICustomDateRange = { gte: newRange.startDate, lte: newRange.endDate };
						handleClose();

						if (onChange) onChange(customDateRange);
						else {
							field.onChange({ target: { name, value: customDateRange } });
						}
					};

					const gte = value?.gte || field.value?.gte;
					const lte = value?.lte || field.value?.lte;

					return (
						<Box position='relative'>
							<DateRangeInput onClick={handleClick}>
								<DateRangePart variant='body1' color={gte ? 'black' : 'grey.500'}>
									{gte ? formatDate(gte) : 'YYY-MM-DD'}
								</DateRangePart>
								-
								<DateRangePart variant='body1' color={value?.lte || field.value?.lte ? 'black' : 'grey.500'}>
									{lte ? formatDate(lte) : 'YYY-MM-DD'}
								</DateRangePart>
								<IconButton onClick={handleClearFilter} sx={{ ml: '-10px' }}>
									<Iconify icon='carbon:close-filled' fontSize='18px' />
								</IconButton>
							</DateRangeInput>
							{error && <FormHelperText error>{error?.message}</FormHelperText>}
							<PickerModal
								customProps={{ onCloseCallback: handleClose, onSubmit: handleChange }}
								initialDateRange={{ startDate: gte, endDate: lte }}
								modalProps={{
									open,
									anchorEl,
									onClose: handleClose,
									slotProps: { paper: { sx: { borderRadius: '16px', boxShadow: 'rgba(0, 0, 0, 0.21) 0px 0px 4px' } } },
									anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
								}}
							/>
						</Box>
					);
				}}
			/>
		</Box>
	);
};

export default RHFDateRangePicker;
