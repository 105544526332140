import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { RHFTextField } from 'src/components';
import { dispatch } from 'src/redux/store';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { useSelector } from 'react-redux';
import { validationSchema } from './validation';
import { QuoteQuestionsForm } from './types';

const Content = () => {
	const { payload } = useSelector(selectModal<{ issue?: string }>);
	const form = useForm<QuoteQuestionsForm>({
		resolver: yupResolver(validationSchema),
		defaultValues: { issue: payload.issue },
	});

	const handleSendMessage = async (data: QuoteQuestionsForm) => {
		dispatch(openModal({ name: modals.quoteMessage, payload: { issue: data.issue } }));
	};
	const handleRequestCallback = async (data: QuoteQuestionsForm) => {
		dispatch(openModal({ name: modals.quoteCallbackRequest, payload: { issue: data.issue } }));
	};
	const handleBookMeeting = async (data: QuoteQuestionsForm) => {
		dispatch(openModal({ name: modals.quoteMeeting, payload: { issue: data.issue } }));
	};

	return (
		<FormProvider {...form}>
			<Typography sx={styledMainTitle} pt={0}>
				ASK US ANYTHING
			</Typography>
			<Typography mt='15px' mb='7px'>
				In a few words, describe your issue so we can connect you with the best helper for you.
			</Typography>
			<RHFTextField name='issue' label='Describe your issue' sx={{ width: '100%', mt: '10px' }} />

			<ActionButtons mt={'20px'}>
				<LoadingButton
					variant='outlined'
					size='large'
					fullWidth
					onClick={form.handleSubmit(handleSendMessage)}
					sx={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
				>
					Send a message ✉️
				</LoadingButton>
				<LoadingButton
					variant='outlined'
					size='large'
					fullWidth
					onClick={form.handleSubmit(handleRequestCallback)}
					sx={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
				>
					Request a callback 📞
				</LoadingButton>
				<LoadingButton
					variant='contained'
					size='large'
					fullWidth
					sx={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
					onClick={form.handleSubmit(handleBookMeeting)}
				>
					Book a meeting 💻
				</LoadingButton>
			</ActionButtons>
		</FormProvider>
	);
};

const QuoteQuestionsModal = () => (
	<Modal name={modals.quoteQuestions} cardSx={{ overflow: 'visible', maxWidth: '750px' }}>
		<Content />
	</Modal>
);

export default QuoteQuestionsModal;
