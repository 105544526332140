import { IconButton, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { ModalCard } from '../customModal/styled';
import Iconify from '../Iconify';

const MobileScreenModal = () => {
	const [isOpen, setIsOpen] = useState(true);

	const handleClose = () => setIsOpen(false);

	return (
		<Modal
			open={isOpen}
			onClose={handleClose}
			slotProps={{ root: { style: { maxWidth: '100vw', maxHeight: '100vh', padding: '20px', display: 'flex' } } }}
		>
			<ModalCard sx={{ px: '25px', py: '30px', position: 'relative' }}>
				<Typography fontWeight={500}>
					<strong>Conveyo works best on a computer.</strong> 🖥️ <br />
					If you experience any issues on mobile, <a href={`mailto:care@conveyo.io`}>let us know</a>—we're happy to
					help! 💬
				</Typography>
				<IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
					<Iconify icon='icon-park-solid:close-one' fontSize='25px' />
				</IconButton>
			</ModalCard>
		</Modal>
	);
};

export default MobileScreenModal;
