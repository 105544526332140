import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Field from 'src/components/common/Field';
import Modal from 'src/components/customModal';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { selectModal } from 'src/redux/slices/modal';
import { Payment } from 'src/types';
import {
	getTransactionOverviewThunk,
	getTransactionSummaryThunk,
	selectTransactionOverview,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import reportPaidPayment from 'src/api/payment/report-paid-payment';
import { validationSchema } from './validation';
import { ReportPaidPaymentForm } from './types';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<WithId<Payment>>);
	const transaction = useSelector(selectTransactionOverview);
	const [isLoading, setIsLoading] = useState(false);
	const form = useForm<ReportPaidPaymentForm>({
		resolver: yupResolver(validationSchema),
		defaultValues: { paidAt: new Date() },
	});

	const handleSubmit = async (data: ReportPaidPaymentForm) => {
		try {
			setIsLoading(true);

			await reportPaidPayment({ id: payload.id, paidAt: data.paidAt });

			await Promise.all([
				dispatch(getTransactionOverviewThunk({ id: transaction.id })),
				dispatch(getTransactionSummaryThunk({ id: transaction.id })),
			]);

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle} pt={0}>
					ENTER PAYMENT
				</Typography>
				<Alert severity='warning' sx={{ mb: '10px' }}>
					<a href={payload.paymentUrl} target='_blank' rel='noopener noreferrer'>
						Check the payment link
					</a>{' '}
					before entering this payment to ensure it's unpaid. The original payment will be deleted.
				</Alert>
				<Field label='Amount' value={payload.amount} />
				<Field label='Currency' value={payload.currency} />
				<Field
					label='Paid At'
					value={<RHFDatePicker sx={{ mt: '-10px' }} name='paidAt' placeholder='Paid at' />}
					row={false}
				/>
				<Typography mt='15px'>To modify the amount, please edit the quote directly.</Typography>
				<ActionButtons mt={'20px'}>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton variant='contained' size='large' fullWidth type='submit' loading={isLoading}>
						Confirm
					</LoadingButton>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

const ReportPaidPaymentModal = () => (
	<Modal name={modals.reportPaidPayment} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default ReportPaidPaymentModal;
