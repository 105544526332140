import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { groupPropertyTitles, handleCloseModal } from 'src/utils/propertyHelpers';
import { RHFRadioGroup } from 'src/components/hook-form';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	getTransactionSummaryThunk,
	selectTransactionOverview,
	selectTransactionSummary,
} from 'src/redux/slices/transaction';
import { LoadingButton } from '@mui/lab';
import { getPropertyTitles, selectProperty, selectPropertyTitles } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import Progress from 'src/components/Progress';
import { BrandModal } from 'src/components/common';
import { openModal, selectModal } from 'src/redux/slices/modal';
import useLoading from 'src/hooks/useLoading';
import selectTransactionTitle from 'src/api/transaction/select-transaction-title';
import { useSnackbar } from 'notistack';
import palette from 'src/theme/palette';
import syncTransactionTasks from 'src/api/transaction/sync-transaction-tasks';
import { AssignTitleForm } from './types';
import { validationSchema } from './validation';

const Content = () => {
	const property = useSelector(selectProperty);
	const transaction = useSelector(selectTransactionOverview);
	const { payload } = useSelector(selectModal<{ select?: boolean; download?: boolean }>);
	const { data: summary } = useSelector(selectTransactionSummary);
	const { data: titles, isInitialized, error } = useSelector(selectPropertyTitles);
	const { isLoading, setIsLoading } = useLoading();
	const form = useForm<AssignTitleForm>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			...(payload.download && { title: transaction.titleId }),
		},
	});
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		dispatch(getPropertyTitles({ id: property.id }));
	}, [property.id]);

	useEffect(() => {
		if (!summary?.general.titleId) return;

		form.reset({ title: summary.general.titleId });
	}, [summary?.general.titleId]);

	const handleSubmit = async (data: AssignTitleForm) => {
		const selectedTitle = titles.find((t) => t.number === data.title);

		if (!selectedTitle) return;

		dispatch(openModal({ name: modals.titleAssignmentConfirmation, payload: selectedTitle }));
	};

	const handleSearch = () => dispatch(openModal({ name: modals.searchTitle }));
	const handleSelectUnregistered = async () => {
		try {
			setIsLoading(true);

			await selectTransactionTitle({
				transactionId: transaction.id,
				force: true,
				title: { number: 'not-registered', address: 'not-registered', tenure: 'not-registered' },
			});
			await syncTransactionTasks(transaction.id);

			dispatch(getTransactionSummaryThunk({ id: transaction.id, withLoading: true }));
			dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transaction.id }));

			enqueueSnackbar('Title assigned as not registered!');

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const groupedTitles = groupPropertyTitles(titles);

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<BrandModal.Title title={payload.select ? 'SELECT TITLE' : 'ASSIGN TITLE'} />
				<Box py='15px'>
					{!isInitialized ? (
						<Box pt='30px' pb='50px'>
							<Progress />
						</Box>
					) : (
						<div>
							<Box display='flex' flexDirection='column' gap='10px'>
								{groupedTitles.length ? (
									groupedTitles.map((group) => (
										<Box key={group.address}>
											<Typography fontWeight={600} fontSize='15px'>
												{group.address}
											</Typography>
											<RHFRadioGroup
												name='title'
												row={false}
												sx={{ width: '100%' }}
												options={group.titles}
												disabled={payload.download}
											/>
										</Box>
									))
								) : (
									<Typography>
										We couldn't find any titles for this address.{' '}
										<Typography
											sx={{
												textDecoration: 'underline',
												cursor: isLoading ? 'default' : 'pointer',
												pointerEvents: isLoading ? 'none' : 'all',
												color: isLoading ? 'gray' : palette.light.primary.main,
											}}
											display='inline'
											color='primary'
											onClick={handleSearch}
										>
											Search title manually
										</Typography>
										<br />
										<Typography color='red'>{!!error && <>{error.message?.toString()}</>}</Typography>
										<Typography mt='3px'>
											Confident no title exists?{' '}
											<Typography
												sx={{
													textDecoration: 'underline',
													cursor: isLoading ? 'default' : 'pointer',
													pointerEvents: isLoading ? 'none' : 'all',
													color: isLoading ? 'gray' : palette.light.primary.main,
												}}
												display='inline'
												color='primary'
												onClick={handleSelectUnregistered}
											>
												Set title to 'Not registered'
											</Typography>
											.
										</Typography>
									</Typography>
								)}
							</Box>
							<ActionButtons mt='20px'>
								<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
									Cancel
								</LoadingButton>
								<LoadingButton
									variant='contained'
									size='large'
									fullWidth
									type='submit'
									disabled={!titles.length}
									loading={isLoading}
								>
									Select
								</LoadingButton>
							</ActionButtons>
						</div>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

const AssignTitleModal = () => (
	<Modal name={modals.assignTitle} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default AssignTitleModal;
