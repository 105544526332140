import { RHFSwitch } from 'src/components/hook-form';
import { Box } from '@mui/system';
import { useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { ITransactionSummary } from 'src/redux/types';
import { LockedSwitchProps } from './types';

const LockedSwitch: FC<LockedSwitchProps> = ({ name, handleSave }) => {
	const { watch, setValue, getValues } = useFormContext();
	const value = watch(name);

	const handleChange = () => {
		setValue(name, !value);

		const values = getValues();

		handleSave(values as ITransactionSummary);
	};

	return (
		<Box display='flex' width='100%' justifyContent='flex-end'>
			<RHFSwitch name={name} onChange={handleChange} />
		</Box>
	);
};

export default LockedSwitch;
