import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { useTask } from 'src/components/GeneralTab/components/TasksSection/components/Task/hooks/useTask';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { Task } from 'src/redux/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { FormProvider, RHFCheckbox } from 'src/components/hook-form';
import { getTaskRef, getUserRef } from 'src/utils/firebase';
import { getTransactionTrackerThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import useError from 'src/hooks/useError';
import { useEffect } from 'react';
import { arrayUnion, updateDoc } from 'firebase/firestore';
import { transactionRoles, transactionUserRelationship } from 'src/pages/transactions/constants';
import syncTransactionTasks from 'src/api/transaction/sync-transaction-tasks';
import { DeleteTaskForm } from './types';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);
	const { payload } = useSelector(selectModal<Task>);
	const { description, statement } = useTask(payload);
	const { setError, error } = useError();
	const form = useForm<DeleteTaskForm>({
		defaultValues: { users: [] },
	});

	const taskRef = getTaskRef(transaction.id, payload.id);
	const users = form.watch('users');

	const handleSubmit = async (data: DeleteTaskForm) => {
		if (!data.users.length) {
			setError('At least one user must be selected');

			return;
		}

		setIsLoading(true);

		const userRefs = data.users.map((id) => getUserRef(id));
		const areAllUsersSelected = data.users.length === payload.users.length;

		await updateDoc<unknown>(taskRef, {
			deletedForUsers: arrayUnion(...userRefs),
			...(areAllUsersSelected && { deleted: true }),
		});

		await syncTransactionTasks(transaction.id);

		dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));

		setIsLoading(false);
		handleCloseModal();
	};

	const handleChange = (userId) => {
		const isExistingUserId = users.includes(userId);

		if (isExistingUserId) {
			form.setValue(
				'users',
				users.filter((id) => userId !== id),
			);

			return;
		}

		form.setValue('users', [...users, userId]);
	};

	useEffect(() => {
		if (users.length) setError(null);
	}, [users]);

	return (
		<Box>
			<FormProvider
				methods={form}
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle}>DELETE TASK</Typography>
				<Typography variant='subtitle1' mb='7px'>
					Who do you want to remove "{description ?? statement}" for?
				</Typography>
				{error}
				<Box display='flex' flexDirection='column' gap='3px'>
					{payload.users.map((u) => (
						<RHFCheckbox
							key={u.id}
							label={`${u.givenNames} ${u.lastNames} - ${transactionRoles[u.role]?.name}${
								u.relationship ? ` (${transactionUserRelationship[u.relationship]?.shortName})` : ''
							}`}
							name='users'
							labelControlProps={{ labelPlacement: 'end' }}
							checked={users.includes(u.id)}
							onChange={() => handleChange(u.id)}
						/>
					))}
				</Box>
				<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center' mt='20px'>
					<LoadingButton variant='outlined' onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton variant='contained' type='submit' loading={isLoading}>
						Delete
					</LoadingButton>
				</Box>
			</FormProvider>
		</Box>
	);
};

const DeleteTaskModal = () => (
	<Modal name={modals.deleteTask} cardSx={{ maxWidth: '600px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default DeleteTaskModal;
