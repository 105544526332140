import { Box } from '@mui/system';
import Logo from '../Logo';

const DownScreen = () => (
	<Box
		sx={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			fontSize: '35px',
			fontWeight: 700,
			textAlign: 'center',
		}}
	>
		<Box mb='40px' sx={{ transform: 'scale(3)' }}>
			<Logo disabledLink />
		</Box>

		<h4
			style={{
				background: 'linear-gradient(to right, #0fedfa, #f411fc)',
				WebkitBackgroundClip: 'text',
				WebkitTextFillColor: 'transparent',
			}}
		>
			Conveyo is Temporarily Unavailable
		</h4>
		<p style={{ fontSize: '18px' }}>
			We're performing essential maintenance to improve your experience.
			<br />
			Conveyo will be back shortly. Thank you for your patience!
		</p>
	</Box>
);

export default DownScreen;
