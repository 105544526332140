import { Box } from '@mui/material';
import Filters from './components/Filters';

const SummariesToolbar = () => (
	<Box display='flex' gap='15px' alignItems='center' pl='10px'>
		<Filters />
	</Box>
);

export default SummariesToolbar;
