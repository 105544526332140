import { AccordionSummary, AccordionSummaryProps, styled } from '@mui/material';
import { RHFCheckbox } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';

export const DocumentCheckbox = styled(RHFCheckbox)<{ isAvailable?: boolean }>(({ isAvailable = true }) => ({
	padding: 0,
	paddingRight: '7px',
	visibility: !isAvailable ? 'hidden' : 'visible',
}));

export const DocumentCheckboxesAccordionSummary = styled((props: AccordionSummaryProps) => (
	<AccordionSummary expandIcon={<Iconify icon='material-symbols-light:play-arrow' fontSize={18} />} {...props} />
))({
	background: 'transparent',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
});
