import { call } from '../@';

export type AcceptQuotePayload = {
	id: string;
	token: string;
};

export default async function acceptQuote(payload: AcceptQuotePayload) {
	return await call('acceptQuote', payload);
}
