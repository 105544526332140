import { Box, styled } from '@mui/material';

export const QuoteSection = styled(Box)({
	width: '100%',
	background: 'white',
	padding: '10px 25px',
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
	alignItems: 'center',
	borderRadius: '4px',
});
