import { useSelector } from 'react-redux';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { taskStatus, taskType } from 'src/constants/tasks';
import { Box, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import moment from 'moment';
import { ExchangeSectionWrapper } from './styled';

const ExchangeSection = () => {
	const transaction = useSelector(selectTransactionOverview);

	const exchangeDateAgreedTask = transaction.progress.exchange.tasks.find(
		(t) => t.type === taskType.exchangeDateAgreed,
	);
	const hasExchangedTask = transaction.progress.exchange.tasks.find((t) => t.type === taskType.hasExchanged);
	const completionDateAgreedTask = transaction.progress.exchange.tasks.find(
		(t) => t.type === taskType.completionDateAgreed,
	);
	const hasCompletedTask = transaction.progress.exchange.tasks.find((t) => t.type === taskType.hasCompleted);

	const hasExchanged = hasExchangedTask?.status === taskStatus.done;
	const hasCompleted = hasCompletedTask?.status === taskStatus.done;

	const exchangeDate = hasExchanged
		? moment(hasExchangedTask.completedAt).format('DD MMM YYYY')
		: hasExchangedTask?.completedAt || hasExchangedTask?.dueDate
		? moment(hasExchangedTask?.completedAt || hasExchangedTask?.dueDate).format('DD MMM YYYY')
		: 'TBA';
	const exchangeColor =
		exchangeDateAgreedTask?.status === taskStatus.done && hasExchangedTask?.status === taskStatus.done
			? '#20BC39'
			: 'grey';
	const completionDate = hasCompleted
		? moment(hasCompletedTask.completedAt).format('DD MMM YYYY')
		: hasCompletedTask?.completedAt || hasCompletedTask?.dueDate
		? moment(hasCompletedTask?.completedAt ?? hasCompletedTask?.dueDate).format('DD MMM YYYY')
		: 'TBA';
	const completionColor =
		completionDateAgreedTask?.status === taskStatus.done && hasCompletedTask?.status === taskStatus.done
			? '#20BC39'
			: 'grey';

	return (
		<ExchangeSectionWrapper>
			<Box display='flex' gap='15px' alignItems='center'>
				<Box
					sx={{ background: '#69788533', padding: '5px', borderRadius: '50%', width: '40px', height: '40px' }}
					display='flex'
					justifyContent='center'
					alignItems='center'
				>
					<Iconify icon='mdi:book-edit-outline' color={exchangeColor} fontSize='25px' />
				</Box>
				<Typography fontWeight={700} fontSize='17px'>
					Exchange
					<Typography color={exchangeColor} fontWeight={600} fontSize='17px'>
						{exchangeDate}
					</Typography>
				</Typography>
			</Box>
			<Box display='flex' gap='15px' alignItems='center'>
				<Box
					sx={{ background: '#69788533', padding: '5px', borderRadius: '50%', width: '40px', height: '40px' }}
					display='flex'
					justifyContent='center'
					alignItems='center'
				>
					<Iconify icon='mdi:key-chain' color={completionColor} fontSize='25px' />
				</Box>
				<Typography fontWeight={700} fontSize='17px'>
					Completion
					<Typography color={completionColor} fontWeight={600} fontSize='17px'>
						{completionDate}
					</Typography>
				</Typography>
			</Box>
		</ExchangeSectionWrapper>
	);
};

export default ExchangeSection;
