import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import {
	getTransactionOverviewThunk,
	getTransactionSummaryThunk,
	getTransactionTrackerThunk,
	selectTransactionOverview,
	selectTransactionSummary,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import { SetValueConfig, useForm } from 'react-hook-form';
import { formatDate, formatPrice } from 'src/utils/transaction/summary';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ITransactionSummary } from 'src/redux/types';
import { PATH_PROPERTIES } from 'src/routes/paths';
import { Link } from 'react-router-dom';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import { selectUser } from 'src/redux/slices/auth';
import useLoading from 'src/hooks/useLoading';
import { PaymentStatusEnum } from 'src/types';
import { UsersList } from 'src/pages/dashboard/components/summaries/Table/styled';
import SummaryField from './components/SummaryField';
import { ActiveTransactionSummaryField, TransactionSummaryTable } from './styles';
import { FormProvider, RHFEditor } from '../hook-form';
import { formatCompanies, formatReferrers, formatUsers, getFormattedFormData, updateTransactionSummary } from './utils';
import VATField from './components/VATField';
import Iconify from '../Iconify';
import GenerateDocumentModal from './components/GenerateDocumentModal';
import GetOfficialCopiesModal from './components/GetOfficialCopiesModal';
import AvailableDocumentsModal from './components/AvailableDocumentsModal';
import { formatInputValueToPrice } from './components/VATField/utils';
import PaymentField from './components/PaymentField';
import Tracker from './components/Tracker';
import MoveTaskModal from './components/Tracker/components/MoveTaskModal';
import DeleteTaskModal from './components/Tracker/components/DeleteTaskModal';
import CaseHandlerField from './components/CaseHandlerField';
import SnoozeTaskModal from './components/Tracker/components/SnoozeTaskModal';
import ConfirmTaskCompleteModal from './components/Tracker/components/ConfirmTaskCompleteModal';
import NoExchangeOnboardedUsersModal from './components/Tracker/components/NoExchangeOnboardedUsersModal';
import LockedSwitch from './components/LockedSwitch';
import ReportPaidPaymentModal from './components/ReportPaidPaymentModal';
import DeletePaymentModal from './components/DeletePaymentModal';
import AddReferrerModal from './components/AddReferrerModal';
import DeleteReferrerModal from './components/DeleteReferrerModal';

const setValueOptions: SetValueConfig = { shouldDirty: true };

const TransactionSummary = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();
	const form = useForm<ITransactionSummary>();
	const transaction = useSelector(selectTransactionOverview);
	const summary = useSelector(selectTransactionSummary);
	const user = useSelector(selectUser);

	const sellSide = form.watch('sellSide');
	const buySide = form.watch('buySide');

	useEffect(() => {
		dispatch(getTransactionSummaryThunk({ id: transaction.id }));
		dispatch(getTransactionTrackerThunk({ id: transaction.id }));
	}, [transaction.id]);

	useEffect(() => {
		if (summary.data) form.reset(getFormattedFormData(summary.data) as never);
	}, [summary]);

	useEffect(() => {
		if (!sellSide?.quote?.withoutVat || !sellSide?.quote?.withVat) {
			form.setValue('sellSide.conveyoFee.withoutVat', null);
			form.setValue('sellSide.conveyoFee.withVat', null);

			return;
		}

		const conveyoFeeWithoutVat = sellSide.quote.withoutVat - (sellSide?.legalFee?.withoutVat || 0);
		const conveyoFeeWithVat = sellSide.quote.withVat - (sellSide?.legalFee?.withVat || 0);

		form.setValue(
			'sellSide.conveyoFee.withoutVat',
			+formatInputValueToPrice(conveyoFeeWithoutVat.toString()) || 0,
			setValueOptions,
		);
		form.setValue(
			'sellSide.conveyoFee.withVat',
			+formatInputValueToPrice(conveyoFeeWithVat.toString()) || 0,
			setValueOptions,
		);
	}, [sellSide?.legalFee?.withoutVat, sellSide?.quote?.withoutVat]);

	useEffect(() => {
		if (!buySide?.quote?.withoutVat || !buySide?.quote?.withVat) {
			form.setValue('buySide.conveyoFee.withoutVat', null);
			form.setValue('buySide.conveyoFee.withVat', null);

			return;
		}

		const conveyoFeeWithoutVat = buySide.quote.withoutVat - (buySide?.legalFee?.withoutVat || 0);
		const conveyoFeeWithVat = buySide.quote.withVat - (buySide?.legalFee?.withVat || 0);

		form.setValue(
			'buySide.conveyoFee.withoutVat',
			+formatInputValueToPrice(conveyoFeeWithoutVat.toString()) || 0,
			setValueOptions,
		);
		form.setValue(
			'buySide.conveyoFee.withVat',
			+formatInputValueToPrice(conveyoFeeWithVat.toString()) || 0,
			setValueOptions,
		);
	}, [buySide?.legalFee?.withoutVat, buySide?.quote?.withoutVat]);

	if (!summary.isInitialized || !summary.data || !sellSide || !buySide) {
		return (
			<Box width='100%' height='80%'>
				<Progress sx={{ height: '100%' }} />
			</Box>
		);
	}

	const handleSubmit = async (data: ITransactionSummary) => {
		try {
			setIsLoading(true);

			await updateTransactionSummary(data, transaction, form);

			await Promise.all([
				dispatch(getTransactionOverviewThunk({ id: transaction.id })),
				dispatch(getTransactionSummaryThunk({ id: transaction.id })),
			]);

			enqueueSnackbar('Updated successfully!');
		} catch (e) {
			console.error(e);

			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });

				return;
			}

			enqueueSnackbar('Something went wrong', { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleGenerateDocument = () => dispatch(openModal({ name: modals.generateDocument }));
	const handleGetOfficialCopies = () => dispatch(openModal({ name: modals.getOfficialCopies }));
	const handleAssignTitle = () => dispatch(openModal({ name: modals.assignTitle }));
	const handleUpdateProperty = () => dispatch(openModal({ name: modals.updateProperty }));
	const handleAddReferrer = () => dispatch(openModal({ name: modals.addReferrer }));

	const { createdBy, settings } = summary.data.general;

	const isSellSearchesPaid = sellSide.payment?.status === PaymentStatusEnum.PAID;
	const isBuySearchesPaid = buySide.payment?.status === PaymentStatusEnum.PAID;
	const isSellConveyoPaid = sellSide.exchangePayment?.status === PaymentStatusEnum.PAID;
	const isBuyConveyoPaid = buySide.exchangePayment?.status === PaymentStatusEnum.PAID;

	return (
		<Box position='relative' height='100%'>
			{isLoading && (
				<Box
					position='absolute'
					width='100%'
					height='100%'
					display='flex'
					alignItems='center'
					justifyContent='center'
					bgcolor='white'
					sx={{ opacity: 0.6 }}
					zIndex={99}
				>
					<Progress />
				</Box>
			)}
			<FormProvider methods={form} onSubmit={form.handleSubmit(handleSubmit)}>
				<Box display='flex' gap='15px' alignItems='center'>
					<Typography variant='h3' p={'25px'}>
						Transaction Summary
					</Typography>
					{user.isAdmin && (
						<Button variant='contained' disabled={form.formState.isSubmitting || !form.formState.isDirty} type='submit'>
							Save changes
						</Button>
					)}
					<Button variant='contained' onClick={handleAssignTitle}>
						<Iconify icon='material-symbols:title' fontSize={20} mr='5px' />
						Assign title
					</Button>
					<Button variant='contained' onClick={handleGetOfficialCopies}>
						<Iconify icon='teenyicons:documents-outline' fontSize={20} mr='5px' />
						Get Official Copies
					</Button>
					{user.isAdmin && (
						<Button variant='contained' onClick={handleGenerateDocument}>
							<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={20} mr='5px' />
							Generate document
						</Button>
					)}
					{user.isAdmin && (
						<Button variant='contained' onClick={handleUpdateProperty}>
							<Iconify icon='tabler:home' fontSize={20} mr='5px' />
							Replace Property
						</Button>
					)}
					{user.isAdmin && (
						<Button variant='contained' onClick={handleAddReferrer}>
							<Iconify icon='material-symbols:person' fontSize={20} mr='5px' />
							Add Referrers
						</Button>
					)}
				</Box>

				<Box p='0 30px' display='flex' flexDirection='row' gap='30px' flexWrap='wrap'>
					{user.isAdmin && (
						<>
							<TransactionSummaryTable title='Transaction'>
								<SummaryField name='Title' withBorder={false}>
									{summary.data.general.title ? (
										`${summary.data.general.title.title}${
											summary.data.general.title.tenure
												? ` (${labelByPropertyTitleTenure[summary.data.general.title.tenure]})`
												: ''
										} `
									) : (
										<Typography color='red' fontWeight={600}>
											UNASSIGNED
										</Typography>
									)}
								</SummaryField>
								<SummaryField name='Edition'>
									{summary.data.general.editionId ?? (
										<Typography color='red' fontWeight={600}>
											UNASSIGNED
										</Typography>
									)}
								</SummaryField>
								<SummaryField name='UPRN'>
									<Link to={PATH_PROPERTIES.view(summary.data.general.propertyId)}>{summary.data.general.uprn}</Link>
								</SummaryField>
								<SummaryField name='Name'>{summary.data.general.displayName}</SummaryField>
								<SummaryField name='Postcode'>{summary.data.general.postcode}</SummaryField>
								<SummaryField name='Ask Price'>
									{summary.data.general.askOffer?.price ? (
										formatPrice(summary.data.general.askOffer?.price)
									) : (
										<Typography color='red' fontWeight={600}>
											PENDING
										</Typography>
									)}
								</SummaryField>
								<SummaryField name='Agreed Price'>
									{summary.data.general.agreedOffer?.price ? (
										formatPrice(summary.data.general.agreedOffer?.price)
									) : (
										<Typography color='red' fontWeight={600}>
											PENDING
										</Typography>
									)}
								</SummaryField>
								{/* <SummaryField name='Next Action'>
						<RHFEditor name='general.nextAction' simple id='next-action' />
					</SummaryField> */}
								<SummaryField name='Notes'>
									<RHFEditor name='general.notes' simple id='notes' />
								</SummaryField>
								<SummaryField name='Title Notes'>
									<RHFEditor name='general.titleNotes' simple id='title-notes' />
								</SummaryField>
								<SummaryField name='Created at'>
									{formatDate(summary.data.general.createdAt, 'YYYY-MM-DD hh:mm:ss A zz')}
								</SummaryField>
								<SummaryField name='Created by'>
									{createdBy
										? `${createdBy.givenNames} ${createdBy.lastNames}${
												createdBy.role
													? ` (${createdBy.role}${createdBy.profession ? ` (${createdBy.profession})` : ''})`
													: ''
										  }`
										: ''}
								</SummaryField>
								<SummaryField name='Managed by'>
									<CaseHandlerField />
								</SummaryField>
								<SummaryField name='Instruction Date'>
									{formatDate(summary.data.general.instructionDate, 'YYYY-MM-DD hh:mm:ss A zz')}
								</SummaryField>
								<SummaryField name='Last Updated On'>
									{formatDate(summary.data.general.latestActionDate, 'YYYY-MM-DD hh:mm:ss A zz')}
								</SummaryField>
								<SummaryField name='Our Margin'>
									£ {summary.data.general.margin.withoutVat} + VAT = £ {summary.data.general.margin.withVat}
								</SummaryField>
							</TransactionSummaryTable>
							<TransactionSummaryTable title='Sell Side' withBorder>
								<ActiveTransactionSummaryField name='PARTICIPANTS' />
								<SummaryField name='Seller(s)' childrenSx={{ flex: 1 }}>
									<UsersList sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
										{formatCompanies(summary.data.sellSide.sellers.companies)}
										{formatUsers(summary.data.sellSide.sellers.users)}
									</UsersList>
								</SummaryField>
								<SummaryField name='Agent(s)'>{formatUsers(summary.data.sellSide.agents)}</SummaryField>
								<SummaryField name='Solicitor(s)'>{formatUsers(summary.data.sellSide.solicitors)}</SummaryField>
								<ActiveTransactionSummaryField name='REFERRERS' />
								<SummaryField name='Referrer(s)'>{formatReferrers(summary.data.sellSide.referrers)}</SummaryField>
								<ActiveTransactionSummaryField name='QUOTE (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }}>
									<LockedSwitch name='general.settings.isSellQuoteLocked' handleSave={handleSubmit} />
								</ActiveTransactionSummaryField>
								<SummaryField name='Total'>
									<VATField name='sellSide.quote' disabled={settings.isSellQuoteLocked || isSellConveyoPaid} />
								</SummaryField>
								<SummaryField name='Solicitors'>
									<VATField name='sellSide.legalFee' disabled={settings.isSellQuoteLocked || isSellConveyoPaid} />
								</SummaryField>
								<SummaryField name='Conveyo'>
									<VATField name='sellSide.conveyoFee' disabled />
								</SummaryField>
								<SummaryField name='Searches'>
									<VATField name='sellSide.searchesFee' disabled={settings.isSellQuoteLocked || isSellSearchesPaid} />
								</SummaryField>
								<ActiveTransactionSummaryField name='EXPENSES (paid by Conveyo)' titleSx={{ whiteSpace: 'nowrap' }}>
									<LockedSwitch name='general.settings.areSellExpensesLocked' handleSave={handleSubmit} />
								</ActiveTransactionSummaryField>
								<SummaryField name='Referral Fee'>
									<VATField name='sellSide.referralFee' disabled />
								</SummaryField>
								<SummaryField name='Official Copies'>
									<VATField name='sellSide.officialCopies' vat={1} disabled={settings.areSellExpensesLocked} />
								</SummaryField>
								<SummaryField name='Identity Checks'>
									<VATField name='sellSide.identityChecks' disabled={settings.areSellExpensesLocked} />
								</SummaryField>
								<SummaryField name='Search Costs'>
									<VATField name='sellSide.searches' disabled={settings.areSellExpensesLocked} />
								</SummaryField>
								<ActiveTransactionSummaryField name='MARGIN' />
								<SummaryField name='Margin'>
									<VATField name='sellSide.margin' disabled />
								</SummaryField>
								<ActiveTransactionSummaryField name='PAYMENTS (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }} />
								<SummaryField name='Sale Pack Payment'>
									{sellSide?.salePackPayment ? (
										<PaymentField {...sellSide.salePackPayment} path='payments.salePack' />
									) : (
										'-'
									)}
								</SummaryField>
								<SummaryField name='Searches Payment'>
									{sellSide?.payment ? <PaymentField {...sellSide.payment} path='payments.sellSide' /> : '-'}
								</SummaryField>
								<SummaryField name='Conveyo Payment'>
									{sellSide?.exchangePayment ? (
										<PaymentField {...sellSide.exchangePayment} path='payments.exchange.sellSide' />
									) : (
										'-'
									)}
								</SummaryField>
								{/* <ActiveTransactionSummaryField name='Agency Rate'>
						<VATField
							name='sellSide.agencyRate'
							inputProps={{ endAdornment: <Iconify icon='ic:round-percent' fontSize={23} ml='5px' /> }}
						/>
					</ActiveTransactionSummaryField>
					<ActiveTransactionSummaryField name='Agency Fee'>
						<VATField name='sellSide.agencyFee' />
					</ActiveTransactionSummaryField> */}
							</TransactionSummaryTable>
							{!transaction.isInstrument && (
								<TransactionSummaryTable title='Buy Side' withBorder>
									<ActiveTransactionSummaryField name='PARTICIPANTS' />
									<SummaryField name='Buyer(s)' childrenSx={{ flex: 1 }}>
										<UsersList sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
											{formatCompanies(summary.data.buySide.buyers.companies)}
											{formatUsers(summary.data.buySide.buyers.users)}
										</UsersList>
									</SummaryField>
									<SummaryField name='Agent(s)'>{formatUsers(summary.data.buySide.agents)}</SummaryField>
									<SummaryField name='Solicitor(s)'>{formatUsers(summary.data.buySide.solicitors)}</SummaryField>
									<ActiveTransactionSummaryField name='REFERRERS' />
									<SummaryField name='Referrer(s)'>{formatReferrers(summary.data.buySide.referrers)}</SummaryField>
									<ActiveTransactionSummaryField name='QUOTE (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }}>
										<LockedSwitch name='general.settings.isBuyQuoteLocked' handleSave={handleSubmit} />
									</ActiveTransactionSummaryField>
									<SummaryField name='Total'>
										<VATField name='buySide.quote' disabled={settings.isBuyQuoteLocked || isBuyConveyoPaid} />
									</SummaryField>
									<SummaryField name='Solicitors'>
										<VATField name='buySide.legalFee' disabled={settings.isBuyQuoteLocked || isBuyConveyoPaid} />
									</SummaryField>
									<SummaryField name='Conveyo'>
										<VATField name='buySide.conveyoFee' disabled />
									</SummaryField>
									<SummaryField name='Searches'>
										<VATField name='buySide.searchesFee' disabled={settings.isBuyQuoteLocked || isBuySearchesPaid} />
									</SummaryField>
									<ActiveTransactionSummaryField name='EXPENSES (paid by Conveyo)' titleSx={{ whiteSpace: 'nowrap' }}>
										<LockedSwitch name='general.settings.areBuyExpensesLocked' handleSave={handleSubmit} />
									</ActiveTransactionSummaryField>
									<SummaryField name='Referral Fee'>
										<VATField name='buySide.referralFee' disabled />
									</SummaryField>
									<SummaryField name='Official Copies'>
										<VATField name='buySide.officialCopies' vat={1} disabled={settings.areBuyExpensesLocked} />
									</SummaryField>
									<SummaryField name='Identity Checks'>
										<VATField name='buySide.identityChecks' disabled={settings.areBuyExpensesLocked} />
									</SummaryField>
									<SummaryField name='Search Costs'>
										<VATField name='buySide.searches' disabled={settings.areBuyExpensesLocked} />
									</SummaryField>
									<ActiveTransactionSummaryField name='MARGIN' />
									<SummaryField name='Margin'>
										<VATField name='buySide.margin' disabled />
									</SummaryField>
									<ActiveTransactionSummaryField name='PAYMENTS (paid by Client)' titleSx={{ whiteSpace: 'nowrap' }} />
									<SummaryField name='Searches Payment'>
										{buySide?.payment ? <PaymentField {...buySide.payment} path='payments.buySide' /> : '-'}
									</SummaryField>
									<SummaryField name='Conveyo Payment'>
										{buySide?.exchangePayment ? (
											<PaymentField {...buySide.exchangePayment} path='payments.exchange.buySide' />
										) : (
											'-'
										)}
									</SummaryField>
									{/* <ActiveTransactionSummaryField name='Agency Rate'>
							<VATField
								name='buySide.agencyRate'
								inputProps={{ endAdornment: <Iconify icon='ic:round-percent' fontSize={23} ml='5px' /> }}
							/>
						</ActiveTransactionSummaryField>
						<ActiveTransactionSummaryField name='Agency Fee'>
							<VATField name='buySide.agencyFee' />
						</ActiveTransactionSummaryField> */}
								</TransactionSummaryTable>
							)}
						</>
					)}
					<Tracker />
				</Box>
				<GenerateDocumentModal />
				<GetOfficialCopiesModal />
				<AvailableDocumentsModal />
				<MoveTaskModal />
				<SnoozeTaskModal />
				<DeleteTaskModal />
				<ConfirmTaskCompleteModal />
				<NoExchangeOnboardedUsersModal />
				<ReportPaidPaymentModal />
				<DeletePaymentModal />
				<AddReferrerModal />
				<DeleteReferrerModal />
			</FormProvider>
		</Box>
	);
};

export default TransactionSummary;
