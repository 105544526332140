import moment from 'moment';
import { MouseEvent } from 'react';

export const stopPropagation = (e: MouseEvent) => e.stopPropagation();

export const descendingSortingComparator = (a: string | number | Date | null, b: string | number | Date | null) => {
	if ((a === null && b === null) || (a === undefined && b === undefined)) {
		return 0;
	}
	if (a === null || a === undefined) {
		return 1;
	}
	if (b === null || b === undefined) {
		return -1;
	}

	if (b < a) {
		return -1;
	}
	if (b > a) {
		return 1;
	}

	return 0;
};

export const getSortingComparator = <T>(order: string, getter: (value: T) => string | number | Date | null) =>
	order === 'desc'
		? (a: T, b: T) => descendingSortingComparator(getter(a), getter(b))
		: (a: T, b: T) => -descendingSortingComparator(getter(a), getter(b));

export const isValidDate = (value: Date | string | number) => {
	if (value instanceof Date && !Number.isNaN(value.getTime())) return true;
	if (typeof value === 'string') return !Number.isNaN(Date.parse(value));
	if (typeof value === 'number') return !Number.isNaN(new Date(value).getTime());

	return false;
};

export const wrapOptionalValue = <T, R>(value: T | undefined | null, wrapper: (s: T) => R) => {
	if (!value) return null;

	return wrapper(value);
};

export const isOptionEqualToValue =
	(valueAttribute = 'value') =>
	(o1, o2) =>
		o1[valueAttribute] === o2[valueAttribute];

export const getBusinessDaysDiff = (startDate: Date, endDate: Date) => {
	const start = moment(startDate);
	const end = moment(endDate);
	let businessDays = 0;

	while (start.isBefore(end, 'day')) {
		if (start.isoWeekday() < 6) businessDays++;
		start.add(1, 'day');
	}

	return businessDays;
};
