import type { DocumentReference } from 'firebase/firestore';
import { UserTitle } from 'src/pages/account-settings/types';
import type { Branch, UserAddress, UserSettings, UserStatus, VerificationSessionStatus } from 'src/types';

/** SHOULD NOT BE USED DIRECTLY, STANDS HERE JUST FOR PROPER TYPINGS */
export default abstract class UserProto {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	branch: DocumentReference<Branch>;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	uid: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	business?: DocumentReference;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	verificationStatus?: VerificationSessionStatus;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	email: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	givenNames: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	lastNames: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	knownAs: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	telephone?: string | null;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	landline?: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	title?: UserTitle;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	role: DocumentReference;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	professions: DocumentReference[];

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	status: UserStatus;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	trialCode: DocumentReference;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	nino: string | null;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	settings: UserSettings;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	address?: UserAddress;

	profilePhoto?: {
		file: string;
		mimeType: string;
	};

	documents?: { verification?: string; proofOfAddress?: string };

	isNameLocked?: boolean;

	isAddressLocked?: boolean;

	isHelpRequested?: boolean;

	workEmail?: string | null;

	signature?: string;

	website?: string;

	preferredContactMethods?: {
		mail: { email: boolean; post: boolean };
		message: { whatsApp: boolean; text: boolean };
		voice: { mobile: boolean; landline: boolean };
	};

	hiddenTransactions?: DocumentReference[];

	fees?: { referralFee?: { net: number } };
}
