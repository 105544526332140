import {
	DocumentReference,
	QuerySnapshot,
	collection,
	doc,
	getDoc,
	getDocs,
	orderBy,
	query,
	where,
} from 'firebase/firestore';

import {
	countriesCollection,
	epcCollection,
	firmsCollection,
	getBranchesCollection,
	getPropertyQuestionnaireCollection,
	getTransactionInvitationsCollection,
	getTransactionQuestionnaireCollection,
	getTransactionTasksCollection,
	invitationsCollection,
	paymentsCollection,
	professionsCollection,
	propertiesCollection,
	propertyListingsCollection,
	propertyPricesCollection,
	registersCollection,
	rolesCollection,
	roomsCollection,
	settingsCollection,
	trackersCollection,
	transactionOffersCollection,
	transactionOnboardingCollection,
	transactionsCollection,
	usersCollection,
} from 'src/constants/firestore';
import { db } from 'src/firebaseConfig';
import { PaymentStatusEnum, UserSettings } from 'src/types';

export const getSettings = async () => {
	const { docs } = await getDocs(settingsCollection);

	const [snapshot] = docs;

	return snapshot ? { ...snapshot.data() } : null;
};

export const getListOfDataFromRefs = async <T>(refs: DocumentReference<T>[]): Promise<Array<T & { id: string }>> =>
	Promise.all(
		refs.map(async (ref) => {
			const snapshot = await getDoc(ref);

			if (!snapshot.exists()) throw new Error(`Ref ${ref.path} not found`);

			return {
				...snapshot.data(),
				id: snapshot.id,
			};
		}),
	);

export const getDataByRef = async <T>(ref: DocumentReference<T>) => {
	const snap = await getDoc(ref);

	return { ...(snap.data() as T), id: snap.id };
};

export const getListOfCollectionDocuments = async <T>(name: string) => {
	const coll = collection(db, name);

	const { docs } = (await getDocs(coll)) as QuerySnapshot<T>;

	return docs.map((d) => ({ ...d.data(), id: d.id }));
};

export const getRoleRef = (id: string) => doc(rolesCollection, id);
export const getRoleSnapshot = (id: string) => getDoc(getRoleRef(id));
export const getRole = async (id: string) => {
	const snapshot = await getRoleSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Role ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: { ...snapshot.data(), id: snapshot.id },
	};
};

export const getEpcRef = (id: string) => doc(epcCollection, id);
export const getEpcSnapshot = (id: string) => getDoc(getEpcRef(id));
export const getEpc = async (id: string) => {
	const snapshot = await getEpcSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Epc ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getPropertyRef = (id: string | number) => doc(propertiesCollection, id.toString());
export const getPropertySnapshot = (id: string | number) => getDoc(getPropertyRef(id));
export const getProperty = async (id: string | number) => {
	const snapshot = await getPropertySnapshot(id);

	if (!snapshot.exists()) throw new Error(`Property ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getUserRef = (id: string) => doc(usersCollection, id);
export const getUserSnapshot = (id: string) => getDoc(getUserRef(id));
export const getUser = async (id: string) => {
	const snapshot = await getUserSnapshot(id);

	if (!snapshot.exists()) throw new Error(`User ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};
export const getUserSettings = async (id: string): Promise<UserSettings> => {
	const { data } = await getUser(id);

	return { pinnedProperties: [], pinnedTransactions: [], summariesColumns: [], summariesFilters: {}, ...data.settings };
};

export const getProfessionRef = (id: string) => doc(professionsCollection, id);
export const getProfessionSnapshot = (id: string) => getDoc(getProfessionRef(id));
export const getProfession = async (id: string) => {
	const snapshot = await getProfessionSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Profession ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getTransactionRef = (id: string) => doc(transactionsCollection, id);
export const getTransactionSnapshot = (id: string) => getDoc(getTransactionRef(id));
export const getTransaction = async (id: string) => {
	const snapshot = await getTransactionSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Transaction ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getTransactionInvitationRef = (transactionId: string, id: string) =>
	doc(getTransactionInvitationsCollection(transactionId), id);
export const getTransactionInvitationSnapshot = (transactionId: string, id: string) =>
	getDoc(getTransactionInvitationRef(transactionId, id));
export const getTransactionInvitation = async (transactionId: string, id: string) => {
	const snapshot = await getTransactionInvitationSnapshot(transactionId, id);

	if (!snapshot.exists()) throw new Error(`Transaction invitation ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getTransactionOfferRef = (id: string) => doc(transactionOffersCollection, id);
export const getTransactionOfferSnapshot = (id: string) => getDoc(getTransactionOfferRef(id));
export const getTransactionOffer = async (id: string) => {
	const snapshot = await getTransactionOfferSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Transaction offer ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getInvitationRef = (id: string) => doc(invitationsCollection, id);
export const getInvitationSnapshot = (id: string) => getDoc(getInvitationRef(id));
export const getInvitation = async (id: string) => {
	const snapshot = await getInvitationSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Invitation ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getTransactionQuestionnaireRef = (transactionId: string) =>
	doc(getTransactionQuestionnaireCollection(transactionId), transactionId);
export const getTransactionQuestionnaire = async (transactionId: string) => {
	console.log(
		'getTransactionQuestionnaireCollection(transactionId)',
		getTransactionQuestionnaireCollection(transactionId).path,
	);
	const { docs } = await getDocs(getTransactionQuestionnaireCollection(transactionId));

	if (!docs.length) throw new Error(`Transaction questionnaire ${transactionId} not found`);

	const [snapshot] = docs;

	if (!snapshot.exists()) throw new Error(`Transaction questionnaire ${transactionId} not found`);

	return { id: snapshot.id, ref: snapshot.ref, snapshot, data: snapshot.data() };
};

export const getPropertyQuestionnaireRef = (propertyId: string) =>
	doc(getPropertyQuestionnaireCollection(propertyId), propertyId);
export const getPropertyQuestionnaire = async (propertyId: string) => {
	const { docs } = await getDocs(getPropertyQuestionnaireCollection(propertyId));

	if (!docs.length) throw new Error(`Property questionnaire ${propertyId} not found`);

	const [snapshot] = docs;

	if (!snapshot.exists()) throw new Error(`Property questionnaire ${propertyId} not found`);

	return { id: snapshot.id, ref: snapshot.ref, snapshot, data: snapshot.data() };
};

export const getBranchRef = (firmId: string, id: string) => doc(getBranchesCollection(firmId), id);
export const getBranchSnapshot = (firmId: string, id: string) => getDoc(getBranchRef(firmId, id));
export const getBranch = async (firmId: string, id: string) => {
	const snapshot = await getDoc(getBranchRef(firmId, id));

	if (!snapshot.exists()) throw new Error(`Branch ${id}, firm id: ${firmId} not found`);

	return { id: snapshot.id, ref: snapshot.ref, snapshot, data: snapshot.data() };
};
export const getFirmBranches = async (firmId: string) => {
	const { docs } = await getDocs(query(getBranchesCollection(firmId)));

	return docs.map((d) => ({ ...d.data(), id: d.id }));
};

export const getFirmRef = (id: string) => doc(firmsCollection, id);
export const getFirmSnapshot = (id: string) => getDoc(getFirmRef(id));
export const getFirm = async (id: string) => {
	const snapshot = await getFirmSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Firm ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const findPaymentByUserAndPropertyId = async (
	userId: string,
	propertyId: string,
	status?: PaymentStatusEnum,
) => {
	const userRef = getUserRef(userId);
	const propertyRef = getPropertyRef(propertyId);
	const paymentsQuery = query(
		paymentsCollection,
		where('propertyRef', '==', propertyRef),
		where('userRef', '==', userRef),
		...(status ? [where('status', '==', status)] : []),
	);

	const { docs } = await getDocs(paymentsQuery);

	return docs[0]?.data() ?? null;
};

export const getRegisterRef = (id: string) => doc(registersCollection, id);
export const getRegisterSnapshot = (id: string) => getDoc(getRegisterRef(id));
export const getRegister = async (id: string) => {
	const snapshot = await getRegisterSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Register ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getPropertyListingRef = (id: string) => doc(propertyListingsCollection, id);
export const getPropertyListingSnapshot = (id: string) => getDoc(getPropertyListingRef(id));
export const getPropertyListing = async (id: string) => {
	const snapshot = await getPropertyListingSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Property listing ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getPropertyPricesRef = (id: string) => doc(propertyPricesCollection, id);
export const getPropertyPricesSnapshot = (id: string) => getDoc(getPropertyPricesRef(id));
export const getPropertyPrices = async (id: string) => {
	const snapshot = await getPropertyPricesSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Property prices ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getPaymentRef = (id: string) => doc(paymentsCollection, id);

export const getTransactionOnboardingRef = (id: string) => doc(transactionOnboardingCollection, id);
export const getTransactionOnboardingSnapshot = (id: string) => getDoc(getTransactionOnboardingRef(id));
export const getTransactionOnboarding = async (id: string) => {
	const snapshot = await getTransactionOnboardingSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Transaction onboarding ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getRooms = async () => {
	const { docs } = await getDocs(query(roomsCollection));

	return docs;
};

export const getTaskRef = (transactionId: string, id: string) => doc(getTransactionTasksCollection(transactionId), id);

export const getTrackerRef = (id: string) => doc(trackersCollection, id);
export const getTrackerSnapshot = (id: string) => getDoc(getTrackerRef(id));
export const getTracker = async (id: string) => {
	const snapshot = await getTrackerSnapshot(id);

	if (!snapshot.exists()) throw new Error(`Tracker ${id} not found`);

	return {
		id: snapshot.id,
		ref: snapshot.ref,
		snapshot,
		data: snapshot.data(),
	};
};

export const getCountries = async () => {
	const { docs } = await getDocs(query(countriesCollection, orderBy('name', 'asc')));

	return docs.map((d) => ({ ...d.data(), id: d.id }));
};
