/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import RHFDateRangePicker from 'src/components/hook-form/RHFDateRangePicker';
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { RHFAutocomplete } from 'src/components';
import { isOptionEqualToValue } from 'src/utils/common';
import {
	labelByTransactionState,
	transactionState,
	transactionStateOptions,
} from 'src/components/modals/transaction/UpdateTransactionState/constants';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSummaries, setSummariesFilters } from 'src/redux/slices/tableSlice';
import { isEqual } from 'lodash';
import { IDashboardTransactionSummaryUser } from 'src/redux/types';
import { getUserLabel } from './utils';
import { TransactionSummariesFilters } from './types';

const Filters = () => {
	const form = useForm<TransactionSummariesFilters>({
		defaultValues: {
			states: [
				{ key: transactionState.live, name: labelByTransactionState[transactionState.live] },
				{ key: transactionState.securedSale, name: labelByTransactionState[transactionState.securedSale] },
			],
		},
	});
	const summaries = useSelector(selectSummaries, isEqual);

	const data = form.watch();

	useEffect(() => {
		dispatch(setSummariesFilters(data));
	}, [data]);

	const handleStatesChange = (_, options) => form.setValue('states', options);
	const handleUsersChange = (_, options) => form.setValue('users', options);

	const selectedUsers = form.watch('users');
	const selectedStates = form.watch('states');

	return (
		<FormProvider {...form}>
			<Box py='15px' maxHeight='400px' overflow='auto' display='flex' gap='20px' flexWrap='wrap'>
				<Box>
					<Typography variant='subtitle1' mb='3px'>
						Filter by States
					</Typography>
					<RHFAutocomplete
						// @ts-ignore
						multiple
						name='states'
						placeholder={selectedStates?.length ? '' : 'Select states'}
						options={transactionStateOptions}
						value={form.watch('states') ?? []}
						sx={{ width: '500px' }}
						onChange={handleStatesChange}
						isOptionEqualToValue={isOptionEqualToValue('key')}
					/>
				</Box>
				<Box>
					<Typography variant='subtitle1' mb='3px'>
						Filter by Participants
					</Typography>
					<RHFAutocomplete
						// @ts-ignore
						multiple
						name='users'
						placeholder={selectedUsers?.length ? '' : 'Select users'}
						loading={!summaries.isInitialized}
						loadingText='Loading users...'
						getOptionLabel={getUserLabel}
						renderOption={(props, o: IDashboardTransactionSummaryUser) => (
							<li {...props} key={o.id}>
								{getUserLabel(o)}
							</li>
						)}
						options={summaries.data.users}
						value={form.watch('users') ?? []}
						sx={{ width: '600px' }}
						onChange={handleUsersChange}
						isOptionEqualToValue={isOptionEqualToValue('id')}
					/>
				</Box>
				<RHFDateRangePicker name='creationDate' label='Filter by Created On' />
				<RHFDateRangePicker name='latestActionDate' label='Filter by Last Updated On' />
			</Box>
		</FormProvider>
	);
};

export default Filters;
