import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import discussQuote from 'src/api/quote/discuss-quote';
import Page from 'src/components/Page';
import { getQuoteThunk, selectQuote } from 'src/redux/slices/quoteSlice';
import { dispatch } from 'src/redux/store';
import { Box } from '@mui/system';
import Progress from 'src/components/Progress';
import { Button, Typography } from '@mui/material';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { AuthPage } from '../auth/styles';
import { quoteTransactionStatus } from '../Quote/constants';
import { QuoteSection } from '../Quote/styled';
import Transaction from '../Quote/components/Transaction';
import ReportMissingQuoteAddressModal from '../Quote/components/ReportMissingQuoteAddressModal';
import QuoteQuestionsModal from './components/QuoteQuestionsModal';
import QuoteMeetingModal from './components/QuoteMeetingModal';
import QuoteCallbackModal from './components/QuoteCallbackModal';
import QuoteMessageModal from './components/QuoteMessageModal';

const DiscussQuote = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const quote = useSelector(selectQuote);

	const quoteId = searchParams.get('id');
	const token = searchParams.get('token');

	useEffect(() => {
		const setup = async () => {
			try {
				console.log('quoteId', quoteId);
				console.log('token', token);

				if (!quoteId || !token) {
					navigate('/auth/login', { replace: true });

					return;
				}

				await discussQuote({ id: quoteId, token });

				dispatch(getQuoteThunk({ id: quoteId, withLoading: true }));
			} catch (e) {
				console.log('e', e);

				navigate('/auth/login', { replace: true });
			}
		};

		setup();
	}, [quoteId, token]);

	const handleLogin = () => navigate(`/auth/login`);
	const handleReady = () => {
		sessionStorage.setItem('quoteId', quoteId as string);

		navigate(`/quote/${quoteId}`);
	};

	const handleHaveQuestions = () => {
		dispatch(openModal({ name: modals.quoteQuestions }));
	};

	const areAllTransactionsSubmitted = !!quote.data?.transactions.every(
		(t) => t.status === quoteTransactionStatus.submitted,
	);

	return (
		<Page title='Quote'>
			<AuthPage>
				<Box display='flex' alignItems='center' flexDirection='column'>
					{!quote.isInitialized ? (
						<Progress />
					) : quote.error || !quote.data ? (
						<Box display='flex' flexDirection='column' gap='10px' alignItems='center'>
							<Typography color='red' fontSize='16px' fontWeight={600}>
								{quote.error?.message ?? 'Something went wrong'}
							</Typography>
							<Button onClick={handleLogin} variant='contained'>
								Go to Login
							</Button>
						</Box>
					) : (
						<Box display='flex' flexDirection='column' gap='15px' maxWidth='750px' minWidth='750px'>
							<QuoteSection>
								<Typography variant='h4'>YOUR TRANSACTIONS</Typography>
								<Typography sx={{ fontSize: '16px' }}>
									Please provide the addresses for all the properties listed in your quote
								</Typography>
							</QuoteSection>
							{quote.data.transactions.map((t) => (
								<Transaction key={t.id} {...t} />
							))}
						</Box>
					)}
					<Box display='flex' gap='15px' alignItems='center'>
						<Button
							onClick={handleReady}
							variant='contained'
							sx={{
								mt: '15px',
								padding: '12px 24px', // Chunky button
								fontSize: '18px',
								textTransform: 'none', // Enforce sentence case
							}}
							disabled={!areAllTransactionsSubmitted}
						>
							I'm ready!
						</Button>

						<Button
							onClick={handleHaveQuestions}
							variant='outlined'
							sx={{
								mt: '15px',
								padding: '12px 24px', // Chunky button
								fontSize: '18px',
								textTransform: 'none', // Enforce sentence case
							}}
						>
							I still have questions
						</Button>
					</Box>
				</Box>
			</AuthPage>
			<ReportMissingQuoteAddressModal />
			<QuoteQuestionsModal />
			<QuoteMeetingModal />
			<QuoteCallbackModal />
			<QuoteMessageModal />
		</Page>
	);
};

export default DiscussQuote;
