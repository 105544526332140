import { call } from '../@';

export type SendQuoteMessagePayload = {
	id: string;
	issue: string;
	message: string;
};

export default async function sendQuoteMessage(payload: SendQuoteMessagePayload) {
	return await call('sendQuoteMessage', payload);
}
