import { noop } from 'lodash';
import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser, updateUser } from 'src/redux/slices/auth';
import { LoadingButton } from '@mui/lab';
import useLoading from 'src/hooks/useLoading';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { getNameVerificationDocument } from 'src/utils/verification';
import { FC, useEffect } from 'react';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { dispatch } from 'src/redux/store';
import { updateTransactionOnboarding } from 'src/redux/slices/transaction';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { useSnackbar } from 'notistack';
import { updatePropertyOnboarding } from 'src/redux/slices/property';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { ConfirmationModal } from '..';
import { VerificationNameIssueForm, VerificationNameIssueModalProps } from './types';
import { verificationNameIssueValidationSchema } from './validation';

const VerificationNameIssueModal: FC<VerificationNameIssueModalProps> = ({ isProperty }) => {
	const { isLoading, setIsLoading } = useLoading();
	const user = useSelector(selectUser);
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm<VerificationNameIssueForm>({
		resolver: yupResolver(verificationNameIssueValidationSchema),
	});

	const document = user.verificationSession ? getNameVerificationDocument(user.verificationSession) : null;

	useEffect(() => {
		if (!document) return;

		methods.reset({
			givenNames: document.given_names,
			familyNames: document.family_name,
		});
	}, [document]);

	if (!user.verificationSession) return null;

	const handleSubmit = async (data: VerificationNameIssueForm) => {
		try {
			setIsLoading(true);

			await updateDoc(user.verificationSessionRef as DocumentReference, {
				requestedGivenNames: data.givenNames,
				requestedFamilyNames: data.familyNames,
			});

			await dispatch(updateUser({ verificationStatus: VerificationSessionStatus.NAME_MISMATCH })).unwrap();

			if (isProperty) {
				await dispatch(
					updatePropertyOnboarding({
						payload: {
							nameIssue: OnboardingStepStatusEnum.PASSED,
							verificationIssue: OnboardingStepStatusEnum.PENDING,
						},
						moveToNextModal: true,
					}),
				).unwrap();
			} else {
				await dispatch(
					updateTransactionOnboarding({
						nameIssue: OnboardingStepStatusEnum.PASSED,
						verificationIssue: OnboardingStepStatusEnum.PENDING,
					}),
				).unwrap();
			}

			setIsLoading(true);
		} catch (e) {
			if (e instanceof Error) {
				enqueueSnackbar(e.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={isProperty ? propertyOnboardingSteps.nameIssue : transactionOnboardingSteps.nameIssue}
			title='NAME ERROR'
			description={
				<FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSubmit)}>
					<Typography variant='h6' color='black' mb='10px'>
						Enter your name as it appears on your chosen ID
					</Typography>
					<Box display='flex' flexDirection='column' gap='15px'>
						<RHFTextField name='givenNames' label='Given Name(s)' placeholder='Please enter your given name(s)' />
						<RHFTextField name='familyNames' label='Family Name(s)' placeholder='Please enter your family name(s)' />
					</Box>
					<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
						<LoadingButton variant='contained' size='large' loading={isLoading} type='submit'>
							Submit
						</LoadingButton>
					</Box>
				</FormProvider>
			}
			withButtons={false}
			confirmText='Go to Dashboard'
			handleClose={noop}
		/>
	);
};

export default VerificationNameIssueModal;
