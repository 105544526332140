import { Box } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { dispatch } from 'src/redux/store';
import { BrandModal } from 'src/components/common';
import { selectModal } from 'src/redux/slices/modal';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { CompanyBranch } from 'src/redux/types';
import { approveCompanyBranchThunk, getCompaniesThunk, rejectCompanyBranchThunk } from 'src/redux/slices/tableSlice';
import Field from 'src/components/common/Field';
import moment from 'moment';
import { InviteLogo } from 'src/components/InviteCard/styles';

const Content = () => {
	const { payload } = useSelector(selectModal<CompanyBranch>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleApprove = async () => {
		try {
			setIsLoading(true);

			await dispatch(approveCompanyBranchThunk({ id: payload.id, companyId: payload.companyId })).unwrap();

			dispatch(getCompaniesThunk());
			enqueueSnackbar('Company branch approved!');
			handleCloseModal();
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleReject = async () => {
		try {
			setIsLoading(true);

			await dispatch(rejectCompanyBranchThunk({ id: payload.id, companyId: payload.companyId })).unwrap();

			dispatch(getCompaniesThunk());
			enqueueSnackbar('Company branch rejected!');
			handleCloseModal();
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<BrandModal.Title title='VERIFY COMPANY BRANCH' />
			<Box py='15px'>
				<Box display='flex' flexDirection='column' gap='10px'>
					<Field label='Name' value={payload.name} />
					<Field label='Status' value={payload.status} />
					<Field label='Address' value={payload.address.formattedAddress} />
					<Field label='Telephone' value={payload.telephone} />
					<Field label='Website' value={payload.website} />
					<Field label='Emails' value={payload.emails.join(', ')} />
					<Field value={<InviteLogo src={payload.logo} maxHeight='100px !important' />} />
					<Field label='Created At' value={moment(payload.createdAt).format('YYYY-MM-DD')} />
				</Box>
				<ActionButtons mt='20px'>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleReject} loading={isLoading}>
						Reject
					</LoadingButton>
					<LoadingButton variant='contained' size='large' fullWidth onClick={handleApprove} loading={isLoading}>
						Approve
					</LoadingButton>
				</ActionButtons>
			</Box>
		</>
	);
};

const VerifyCompanyBranchModal = () => (
	<Modal name={modals.verifyCompanyBranch} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default VerifyCompanyBranchModal;
