import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { Box } from '@mui/system';
import { RowProps } from './types';
import Table from '../..';

const Row = <T,>({ columns, row, subRowsColumns, getSubRowsData, sx, cellSx }: RowProps<T>) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const subRowsData = getSubRowsData?.(row);
	const isExpandable = !!subRowsData && subRowsColumns?.length;

	const handleExpand = () => setIsExpanded(!isExpanded);

	return (
		<>
			<TableRow sx={{ borderBottom: '1px solid grey', ...sx }}>
				{columns.map((c, index) => {
					const isFirst = index === 0;
					const label = c.getLabel ? c.getLabel(row) : row[c.key];

					return (
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						<TableCell key={c.key} sx={{ ...cellSx, ...c.sx }}>
							{isFirst && isExpandable ? (
								<Box display='flex' alignItems='center' gap='5px'>
									<IconButton
										onClick={handleExpand}
										sx={{ transform: isExpanded ? 'rotate(180deg)' : '', transition: '0.3s all' }}
									>
										<Iconify icon='eva:arrow-down-fill' fontSize={25} color='#A3A3A3' />
									</IconButton>
									{label}
								</Box>
							) : (
								label
							)}
						</TableCell>
					);
				})}
			</TableRow>
			{isExpandable && (
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Collapse in={isExpanded} timeout='auto' unmountOnExit>
							<Box margin={1}>
								<Table
									columns={subRowsColumns}
									rowKeyField='id'
									data={{
										isInitialized: true,
										data: { data: subRowsData, limit: 0, page: 1, totalCount: 0, totalPages: 0 },
									}}
									withPagination={false}
								/>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

export default Row;
