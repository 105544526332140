import { Box, styled, TextField, Typography } from '@mui/material';
import { DefaultTheme, makeStyles } from '@mui/styles';
import palette from 'src/theme/palette';

export const makeAutoCompleteStyles = makeStyles({
	root: {
		'&:has(input:disabled)': {
			backgroundColor: palette.light.grey[200],
			borderRadius: '8px',
		},
	},
});

export const makeTextInputStyles = makeStyles<DefaultTheme, { disabled: boolean }>({
	root: {
		'& .MuiInputBase-root': {
			borderRadius: '8px',
			backgroundColor: ({ disabled }) => (disabled ? palette.light.grey[200] : 'unset'),
		},
	},
});

export const DateRangeInput = styled(Box)(({ theme }) => ({
	cursor: 'pointer',
	display: 'flex',
	flexDirection: 'row',
	gap: '20px',
	border: `1px solid ${theme.palette.grey[300]}`,
	borderRadius: '7px',
	padding: '10px',
	alignItems: 'center',
	height: '55px',
}));

export const DateRangePart = styled(Typography)();

export const StyledOtpInput = styled(TextField)({
	'.MuiInputBase-root': { borderRadius: '2px' },
});
