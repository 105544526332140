export const taskStatus = {
	toDo: 'TO_DO',
	inProgress: 'IN_PROGRESS',
	done: 'DONE',
} as const;

export const dynamicTaskType = {
	uploadSellBankStatements: 'UPLOAD_SELL_BANK_STATEMENTS_',
	uploadSellMortgageStatement: 'UPLOAD_SELL_MORTGAGE_STATEMENT_',
	uploadBuyBankStatements: 'UPLOAD_BUY_BANK_STATEMENTS_',
	uploadGiftBankStatements: 'UPLOAD_GIFT_BANK_STATEMENTS_',
} as const;

export const taskType = {
	acceptInvite: 'ACCEPT_INVITE',
	getVerified: 'GET_VERIFIED',
	confirmVerificationResults: 'CONFIRM_VERIFICATION_RESULTS',
	enterPersonalDetails: 'ENTER_PERSONAL_DETAILS',
	completeSellQuestions: 'COMPLETE_SELL_QUESTIONS',
	completePropertyQuestions: 'COMPLETE_PROPERTY_QUESTIONS',
	completeBuyQuestions: 'COMPLETE_BUY_QUESTIONS',
	completeGiftQuestions: 'COMPLETE_GIFT_QUESTIONS',
	uploadGiftProofOfFunds: 'UPLOAD_GIFT_PROOF_OF_FUNDS',
	uploadFloorPlan: 'UPLOAD_FLOOR_PLAN',
	uploadProtectedBuilding: 'UPLOAD_PROTECTED_BUILDING',
	uploadProtectedTrees: 'UPLOAD_PROTECTED_TREES',
	uploadAlterationsUse: 'UPLOAD_ALTERATIONS_USE',
	uploadAlterationsWindows: 'UPLOAD_ALTERATIONS_WINDOWS',
	uploadAlterationsExtension: 'UPLOAD_ALTERATIONS_EXTENSION',
	uploadAlterationsConservatory: 'UPLOAD_ALTERATIONS_CONSERVATORY',
	uploadLoftConversion: 'UPLOAD_LOFT_CONVERSION',
	uploadGarageConversion: 'UPLOAD_GARAGE_CONVERSION',
	uploadInternalWalls: 'UPLOAD_INTERNAL_WALLS',
	uploadOtherWorks: 'UPLOAD_OTHER_WORKS',
	uploadHomeWarranty: 'UPLOAD_HOME_WARRANTY',
	uploadUnderpinningWarranty: 'UPLOAD_UNDERPINNING_WARRANTY',
	uploadCentralHeatingWarranty: 'UPLOAD_CENTRAL_HEATING_WARRANTY',
	uploadRoofingWarranty: 'UPLOAD_ROOFING_WARRANTY',
	uploadElectricalWorkWarranty: 'UPLOAD_ELECTRICAL_WORK_WARRANTY',
	uploadWindowsWarranty: 'UPLOAD_WINDOWS_WARRANTY',
	uploadTimberTreatmentWarranty: 'UPLOAD_TIMBER_TREATMENT_WARRANTY',
	uploadDampRoofingWarranty: 'UPLOAD_DAMP_ROOFING_WARRANTY',
	uploadOtherWarranties: 'UPLOAD_OTHER_WARRANTIES',
	uploadBuildingInsurance: 'UPLOAD_BUILDING_INSURANCE',
	uploadHomeInsurance: 'UPLOAD_HOME_INSURANCE',
	uploadJapaneseKnotweed: 'UPLOAD_JAPANESE_KNOTWEED',
	orderManagementPack: 'ORDER_MANAGEMENT_PACK',
	uploadGroundRent: 'UPLOAD_GROUND_RENT',
	uploadServiceCharge: 'UPLOAD_SERVICE_CHARGE',
	uploadFreeholdManagementCompany: 'UPLOAD_FREEHOLD_MANAGEMENT_COMPANY',
	uploadSewerage: 'UPLOAD_SEWERAGE',
	uploadPartyWall: 'UPLOAD_PARTY_WALL',
	uploadEICR: 'UPLOAD_EICR',
	uploadGasSafeRegister: 'UPLOAD_GAS_SAFE_REGISTER',
	uploadNoticeOfSale: 'UPLOAD_NOTICE_OF_SALE',
	uploadNoticeAboutBuilding: 'UPLOAD_NOTICE_ABOUT_BUILDING',
	uploadLeaseTermChanges: 'UPLOAD_LEASE_TERM_CHANGES',
	uploadLandlordResponseToNotice: 'UPLOAD_LANDLORD_RESPONSE_TO_NOTICE',
	uploadBuyMortgage: 'UPLOAD_BUY_MORTGAGE',
	uploadBuyRemortgage: 'UPLOAD_BUY_REMORTGAGE',
	uploadBuyEquityRelease: 'UPLOAD_BUY_EQUITY_RELEASE',
	uploadBuySavings: 'UPLOAD_BUY_SAVINGS',
	uploadBuyInheritance: 'UPLOAD_BUY_INHERITANCE',
	uploadBuyPropertySale: 'UPLOAD_BUY_PROPERTY_SALE',
	uploadBuyPension: 'UPLOAD_BUY_PENSION',
	uploadBuyGambling: 'UPLOAD_BUY_GAMBLING',
	uploadBuyCompensation: 'UPLOAD_BUY_COMPENSATION',
	uploadBuyInvestment: 'UPLOAD_BUY_INVESTMENT',
	titlePendingApplications: 'TITLE_PENDING_APPLICATIONS',
	assignTitle: 'ASSIGN_TITLE',
	downloadTitle: 'DOWNLOAD_TITLE',
	confirmRelationship: 'CONFIRM_RELATIONSHIP',
	obtainNewEPC: 'OBTAIN_NEW_EPC',
	uploadTenantsNotice: 'UPLOAD_TENANTS_NOTICE',
	uploadCollectivePurchaseNotice: 'UPLOAD_COLLECTIVE_PURCHASE_NOTICE',
	uploadSection42Notice: 'UPLOAD_SECTION_42_NOTICE',
	uploadTenantsResponse: 'UPLOAD_TENANTS_RESPONSE',
	uploadCollectivePurchaseResponse: 'UPLOAD_COLLECTIVE_PURCHASE_RESPONSE',
	uploadSection42Response: 'UPLOAD_SECTION_42_RESPONSE',
	uploadGiftLoan: 'UPLOAD_GIFT_LOAN',
	uploadGiftRemortgage: 'UPLOAD_GIFT_REMORTGAGE',
	uploadGiftEquityRelease: 'UPLOAD_GIFT_EQUITY_RELEASE',
	uploadGiftSavings: 'UPLOAD_GIFT_SAVINGS',
	uploadGiftInheritance: 'UPLOAD_GIFT_INHERITANCE',
	uploadGiftPropertySale: 'UPLOAD_GIFT_PROPERTY_SALE',
	uploadGiftPension: 'UPLOAD_GIFT_PENSION',
	uploadGiftGambling: 'UPLOAD_GIFT_GAMBLING',
	uploadGiftCompensation: 'UPLOAD_GIFT_COMPENSATION',
	uploadGiftInvestment: 'UPLOAD_GIFT_INVESTMENT',
	uploadGiftBankStatements: 'UPLOAD_GIFT_BANK_STATEMENTS',
	uploadElectricalSafetyCertificate: 'UPLOAD_ELECTRICAL_SAFETY_CERTIFICATE',
	uploadElectricalBuildingCompliance: 'UPLOAD_ELECTRICAL_BUILDING_COMPLIANCE',
	uploadElectricalBuildingCompletion: 'UPLOAD_ELECTRICAL_BUILDING_COMPLETION',
	uploadRadonTest: 'UPLOAD_RADON_TEST',
	uploadFloodReport: 'UPLOAD_FLOOD_REPORT',
	uploadSolarPanels: 'UPLOAD_SOLAR_PANELS',
	uploadElectricalInsurance: 'UPLOAD_ELECTRICAL_INSURANCE',
	orderBuySearches: 'ORDER_BUY_SEARCHES',
	orderSalePack: 'ORDER_SALE_PACK',
	orderSellSearches: 'ORDER_SELL_SEARCHES',
	uploadNameChangeEvidence: 'UPLOAD_NAME_CHANGE_EVIDENCE',
	signPropertyForms: 'SIGN_PROPERTY_FORMS',
	signContentsForm: 'SIGN_CONTENTS_FORM',
	viewPropertyForms: 'VIEW_PROPERTY_FORMS',
	viewContentsForm: 'VIEW_CONTENTS_FORM',
	signGiftDeclaration: 'SIGN_GIFT_DECLARATION',
	sellQuoteSent: 'SELL_QUOTE_SENT',
	sellQuoteAccepted: 'SELL_QUOTE_ACCEPTED',
	searchesUploaded: 'SEARCHES_UPLOADED',
	searchesOrdered: 'SEARCHES_ORDERED',
	sellSolicitorAssigned: 'SELL_SOLICITOR_ASSIGNED',
	sellCCLSent: 'SELL_CCL_SENT',
	sellCCLSigned: 'SELL_CCL_SIGNED',
	sellPropertyListed: 'SELL_PROPERTY_LISTED',
	sellPriceSet: 'SELL_PRICE_SET',
	sellOfferAccepted: 'SELL_OFFER_ACCEPTED',
	buyQuoteSent: 'BUY_QUOTE_SENT',
	buyQuoteAccepted: 'BUY_QUOTE_ACCEPTED',
	buySolicitorAssigned: 'BUY_SOLICITOR_ASSIGNED',
	buyCCLSent: 'BUY_CCL_SENT',
	buyCCLSigned: 'BUY_CCL_SIGNED',
	sellContractSent: 'SELL_CONTRACT_SENT',
	buyContractReceived: 'BUY_CONTRACT_RECEIVED',
	buyContractApproved: 'BUY_CONTRACT_APPROVED',
	buyTR1Sent: 'BUY_TR1_SENT',
	sellTR1Received: 'SELL_TR1_RECEIVED',
	sellTR1Approved: 'SELL_TR1_APPROVED',
	buyEnquiriesSent: 'BUY_ENQUIRIES_SENT',
	sellEnquiriesReceived: 'SELL_ENQUIRIES_RECEIVED',
	sellRepliesSent: 'SELL_REPLIES_SENT',
	buyRepliesReceived: 'BUY_REPLIES_RECEIVED',
	buyEnquiriesComplete: 'BUY_ENQUIRIES_COMPLETE',
	exchangeDateAgreed: 'EXCHANGE_DATE_AGREED',
	completionDateAgreed: 'COMPLETION_DATE_AGREED',
	hasExchanged: 'HAS_EXCHANGED',
	hasCompleted: 'HAS_COMPLETED',
	uploadMemorandumOfSale: 'UPLOAD_MEMORANDUM_OF_SALE',
	uploadMemorandumOfPurchase: 'UPLOAD_MEMORANDUM_OF_PURCHASE',
	createUserBankAccounts: 'CREATE_USER_BANK_ACCOUNTS',
	reviewUserBankAccounts: 'REVIEW_USER_BANK_ACCOUNTS',
	uploadUserBankAccountStatements: 'UPLOAD_USER_BANK_ACCOUNT_STATEMENTS',
	uploadDeedOfTrust: 'UPLOAD_DEED_OF_TRUST',
	confirmBuyTeam: 'CONFIRM_BUY_TEAM',
} as const;

export const taskCategory = {
	user: 'user',
	admin: 'admin',
	automated: 'automated',
} as const;
