import { ProgressReport } from 'src/api/reports/get-progress-reports';
import { SortableRow } from 'src/redux/types';

export const getProgressReportSortableRow = (row: ProgressReport): SortableRow<ProgressReport> => ({
	id: { value: row.id, label: row.id },
	name: { value: row.name, label: row.name },
	buyersAdded: { value: row.buyersAdded, label: row.buyersAdded },
	buyersOnboarded: { value: row.buyersOnboarded, label: row.buyersOnboarded },
	buySolicitorsAdded: { value: row.buySolicitorsAdded, label: row.buySolicitorsAdded },
	buySolicitorsOnboarded: { value: row.buySolicitorsOnboarded, label: row.buySolicitorsOnboarded },
	contractPackAvailable: { value: row.contractPackAvailable, label: row.contractPackAvailable },
	enquiriesCompleted: { value: row.enquiriesCompleted, label: row.enquiriesCompleted },
	enquiriesStarted: { value: row.enquiriesStarted, label: row.enquiriesStarted },
	exchanged: { value: row.exchanged, label: row.exchanged },
	exchangeDateAgreed: { value: row.exchangeDateAgreed, label: row.exchangeDateAgreed },
	handler: { value: row.handler, label: row.handler ?? '' },
	memorandumOfSaleAvailable: { value: row.memorandumOfSaleAvailable, label: row.memorandumOfSaleAvailable },
	searchesOrdered: { value: row.searchesOrdered, label: row.searchesOrdered },
	searchesUploaded: { value: row.searchesUploaded, label: row.searchesUploaded },
	propertyFormsAvailable: { value: row.propertyFormsAvailable, label: row.propertyFormsAvailable },
	sellersAdded: { value: row.sellersAdded, label: row.sellersAdded },
	sellersOnboarded: { value: row.sellersOnboarded, label: row.sellersOnboarded },
	sellSolicitorsAdded: { value: row.sellSolicitorsAdded, label: row.sellSolicitorsAdded },
	sellSolicitorsOnboarded: { value: row.sellSolicitorsOnboarded, label: row.sellSolicitorsOnboarded },
	invoicePaid: { value: row.invoicePaid, label: row.invoicePaid },
	completed: { value: row.completed, label: row.completed },
});
