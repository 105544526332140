import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { TrackerWrapper } from './styles';
import { ITrackerStep } from '../TrackerStep/types';
import TrackerStep from '../TrackerStep';
import { TrackerStepSeparator } from '../TrackerStep/styles';

const Tracker = () => {
	const transaction = useSelector(selectTransactionOverview);

	const steps: Array<ITrackerStep & { key: string }> = useMemo(
		() => [
			{
				...transaction.progress.seller,
				key: 'seller',
				step: 'seller',
				title: (
					<>
						Sellers <br /> readiness
					</>
				),
				order: 1,
			},
			{
				...transaction.progress.property,
				key: 'property',
				step: 'property',
				title: (
					<>
						Property <br /> readiness
					</>
				),
				order: 2,
			},
			{
				...transaction.progress.listing,
				key: 'listing',
				step: 'listing',
				title: <>Listing</>,
				status: transaction.progress.listing.status === 'pending' ? 'inProgress' : transaction.progress.listing.status,
				order: 0,
			},
			{
				...transaction.progress.buyer,
				key: 'buyer',
				step: 'buyer',
				title: (
					<>
						Buyers <br /> readiness
					</>
				),
				order: 3,
			},
			{
				...transaction.progress.legals,
				key: 'legals',
				title: (
					<>
						Exchange <br /> readiness
					</>
				),
				date: null,
				step: 'legals',
				order: 4,
			},
		],
		// .sort((a, b) => {
		// 	if (a.key === 'legals') return 1;
		// 	if (b.key === 'legals') return -1;

		// 	if (a.status === 'completed' && !a.date) return b.status === 'completed' && !b.date ? 0 : -1;
		// 	if (b.status === 'completed' && !b.date) return 1;

		// 	if (!a.date) return 1;
		// 	if (!b.date) return -1;

		// 	return new Date(a.date).getTime() - new Date(b.date).getTime();
		// })
		[transaction.progress],
	);

	return (
		<Stack flexDirection='row' m='40px 0 70px 20px'>
			<TrackerWrapper>
				{steps.map((step, index) => (
					<>
						<TrackerStep {...step} />
						{index + 1 !== steps.length && <TrackerStepSeparator />}
					</>
				))}
			</TrackerWrapper>
		</Stack>
	);
};

export default Tracker;
