import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { extractNewPropertyAddress, handleCloseModal } from 'src/utils/propertyHelpers';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import UprnForm from 'src/components/UprnForm';
import useLoading from 'src/hooks/useLoading';
import useError from 'src/hooks/useError';
import { InitialAddress } from 'src/components/GoogleAddressAutoComplete/GooglePlaceAutocomlete';
import { newPropertyDataSchema } from 'src/sections/property/components/validationSchema';
import { dispatch } from 'src/redux/store';
import {
	getTransactionOverviewThunk,
	selectTransactionOverview,
	updateTransactionThunk,
} from 'src/redux/slices/transaction';
import searchPropertyByUprn from 'src/api/property/search-property-by-uprn';
import addProperty from 'src/api/property/add';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getProperty } from 'src/redux/slices/property';
import { getPropertyQuestionnaire } from 'src/redux/slices/propertyQuestionnaire';
import { UpdatePropertyModalForm } from './types';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();
	const { error, setError } = useError();
	const transaction = useSelector(selectTransactionOverview);
	const navigate = useNavigate();

	const form = useForm<UpdatePropertyModalForm>({
		resolver: yupResolver(newPropertyDataSchema),
		defaultValues: InitialAddress,
		mode: 'onChange',
	});

	const onSubmit = async (data: UpdatePropertyModalForm) => {
		try {
			const existingProperty = await searchPropertyByUprn({ uprn: data.uprn });

			if (!existingProperty.id) {
				const { propertyId } = await addProperty({ uprn: data.uprn, address: extractNewPropertyAddress(data.address) });

				existingProperty.id = propertyId;
			}

			await dispatch(updateTransactionThunk({ propertyId: data.uprn.toString() })).unwrap();
			dispatch(getTransactionOverviewThunk({ id: transaction.id, withLoading: true }));
			dispatch(getProperty({ id: existingProperty.id }));
			dispatch(getPropertyQuestionnaire({ id: existingProperty.id }));
			navigate(`/transactions/${transaction.id}/overview`);

			handleCloseModal();
		} catch (e) {
			console.error(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(onSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle} pt={0}>
					SELECT NEW ADDRESS
				</Typography>
				{error}
				<UprnForm setError={setError} isLoading={isLoading} setIsLoading={setIsLoading} />
				<ActionButtons mt='20px'>
					<LoadingButton
						variant='outlined'
						size='large'
						fullWidth
						onClick={handleCloseModal}
						loading={isLoading || form.formState.isSubmitting}
					>
						Cancel
					</LoadingButton>
					<LoadingButton
						variant='contained'
						size='large'
						fullWidth
						type='submit'
						loading={isLoading || form.formState.isSubmitting}
					>
						Confirm
					</LoadingButton>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

export const UpdatePropertyModal = () => (
	<Modal name={modals.updateProperty} cardSx={{ maxWidth: '600px', overflow: 'visible' }}>
		<Content />
	</Modal>
);
