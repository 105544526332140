import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, StandardTextFieldProps, TextField } from '@mui/material';
import noop from 'src/utils/noop';
import type { IRHFAutocomplete, LocalOptions } from './types';
import { makeAutoCompleteStyles } from './styles';

// ----------------------------------------------------------------------

const RHFAutocomplete: FC<IRHFAutocomplete> = ({
	handleChange = noop<[]>,
	name,
	label,
	placeholder,
	controlProps,
	options = [],
	width,
	InputLabelProps,
	valueAttribute = 'id',
	labelAttribute = 'name',
	...other
}) => {
	const { control } = useFormContext();

	const classes = makeAutoCompleteStyles();

	return (
		<Controller
			render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
				<Autocomplete
					sx={{ width }}
					classes={classes}
					loadingText='Loading...'
					clearIcon={null}
					disablePortal
					onChange={(_, data) => {
						handleChange(data);
						onChange(data?.[valueAttribute]);
					}}
					options={options}
					value={options?.find((option) => option[valueAttribute] === value) ?? null}
					getOptionLabel={
						other.getOptionLabel ?? (!options.length ? () => '' : (option: LocalOptions) => option[labelAttribute])
					}
					renderOption={(props, option: LocalOptions) => (
						<li {...props} key={option.id}>
							{option[labelAttribute]}
						</li>
					)}
					fullWidth
					openOnFocus
					renderInput={(params) => (
						<TextField
							fullWidth
							error={!!error}
							helperText={error?.message}
							placeholder={placeholder}
							inputRef={ref}
							label={label}
							{...(params as Omit<StandardTextFieldProps, 'fullWidth'>)}
							InputLabelProps={InputLabelProps}
						/>
					)}
					{...other}
				/>
			)}
			name={name}
			control={controlProps || control}
		/>
	);
};

export default RHFAutocomplete;
