import { LocalOptions } from 'src/components/hook-form/types';
import {
	companyBranchStatus,
	companyBranchType,
	labelByCompanyBranchStatus,
	labelByCompanyBranchType,
} from '../../constants';

export const companyBranchStatusOptions = Object.values(companyBranchStatus).reduce<LocalOptions[]>((acc, status) => {
	acc.push({ id: status, name: labelByCompanyBranchStatus[status] });

	return acc;
}, []);

export const companyBranchTypeOptions = Object.values(companyBranchType).reduce<LocalOptions[]>((acc, type) => {
	acc.push({ id: type, name: labelByCompanyBranchType[type] });

	return acc;
}, []);
