/* eslint-disable react/no-unstable-nested-components */
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import { getProgressReportsThunk, selectProgressReports, sortProgressReports } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { TableColumn } from 'src/components/common/Table/types';
import { ProgressReport } from 'src/api/reports/get-progress-reports';
import { Table } from 'src/components';
import { Link } from 'react-router-dom';
import ProgressStatus from '../ProgressStatus';
import { cellBorderLeft } from './constants';

const ProgressTable = () => {
	const reports = useSelector(selectProgressReports);

	useEffect(() => {
		dispatch(getProgressReportsThunk());
	}, []);

	if (reports.isLoading) return <Progress />;
	if (reports.error || !reports.data) {
		return (
			<Box p='15px'>
				<Typography color='red' variant='h4'>
					{reports.error?.message ?? 'Something went wrong'}
				</Typography>
			</Box>
		);
	}

	const handleSortChange = ({ order, orderBy }) => dispatch(sortProgressReports({ order, orderBy }));

	const columns: TableColumn<ProgressReport>[] = [
		{
			key: 'name',
			name: 'Transaction name',
			sortable: true,
			width: 350,
			getLabel: (row) => (
				<Link to={`/transactions/${row.id}`}>
					<b>{row.name}</b>
				</Link>
			),
		},
		{
			key: 'handler',
			name: 'Handler assigned',
			sortable: true,
			getLabel: (row) => row.handler || <ProgressStatus status='pending' />,
		},
		{
			key: 'sellersAdded',
			name: 'Sellers added',
			width: 150,
			sx: cellBorderLeft,
			getLabel: (row) => <ProgressStatus status={row.sellersAdded} />,
		},
		{
			key: 'sellSolicitorsAdded',
			name: 'Solicitors added',
			getLabel: (row) => <ProgressStatus status={row.sellSolicitorsAdded} />,
		},
		{
			key: 'sellSolicitorsOnboarded',
			name: 'Solicitors onboarded',
			getLabel: (row) => <ProgressStatus status={row.sellSolicitorsOnboarded} />,
		},
		{
			key: 'sellersOnboarded',
			name: 'Sellers onboarded',
			getLabel: (row) => <ProgressStatus status={row.sellersOnboarded} />,
		},
		{
			key: 'buyersAdded',
			name: 'Buyers added',
			sx: cellBorderLeft,
			getLabel: (row) => <ProgressStatus status={row.buyersAdded} />,
		},
		{
			key: 'buySolicitorsAdded',
			name: 'Solicitors added',
			getLabel: (row) => <ProgressStatus status={row.buySolicitorsAdded} />,
		},
		{
			key: 'buySolicitorsOnboarded',
			name: 'Solicitors onboarded',
			getLabel: (row) => <ProgressStatus status={row.buySolicitorsOnboarded} />,
		},
		{
			key: 'buyersOnboarded',
			name: 'Buyers onboarded',
			getLabel: (row) => <ProgressStatus status={row.buyersOnboarded} />,
		},
		{
			key: 'memorandumOfSaleAvailable',
			name: 'MOS available',
			sx: cellBorderLeft,
			getLabel: (row) => <ProgressStatus status={row.memorandumOfSaleAvailable} />,
		},
		{
			key: 'propertyFormsAvailable',
			name: 'Property forms available',
			getLabel: (row) => <ProgressStatus status={row.propertyFormsAvailable} />,
		},
		{
			key: 'contractPackAvailable',
			name: 'Contract pack available',
			getLabel: (row) => <ProgressStatus status={row.contractPackAvailable} />,
		},
		{
			key: 'searchesOrdered',
			name: 'Searches ordered',
			getLabel: (row) => <ProgressStatus status={row.searchesOrdered} />,
		},
		{
			key: 'searchesUploaded',
			name: 'Searches uploaded',
			getLabel: (row) => <ProgressStatus status={row.searchesUploaded} />,
		},
		{
			key: 'enquiriesStarted',
			name: 'Enquiries started',
			getLabel: (row) => <ProgressStatus status={row.enquiriesStarted} />,
		},
		{
			key: 'enquiriesCompleted',
			name: 'Enquiries completed',
			getLabel: (row) => <ProgressStatus status={row.enquiriesCompleted} />,
		},
		{
			key: 'exchangeDateAgreed',
			name: 'Exchange date agreed',
			getLabel: (row) => <ProgressStatus status={row.exchangeDateAgreed} />,
		},
		{ key: 'exchanged', name: 'Exchanged', getLabel: (row) => <ProgressStatus status={row.exchanged} /> },
		{ key: 'invoicePaid', name: 'Invoice Paid', getLabel: (row) => <ProgressStatus status={row.invoicePaid} /> },
		{ key: 'completed', name: 'Completed', getLabel: (row) => <ProgressStatus status={row.completed} /> },
	];

	return (
		<Table
			columns={columns}
			data={{
				data: {
					data: reports.data,
					limit: 0,
					page: 0,
					totalCount: 0,
					totalPages: 0,
					sortOrder: reports.order,
					orderBy: reports.orderBy,
				},
				isInitialized: !reports.isLoading,
			}}
			handleSortChange={handleSortChange}
			withPagination={false}
			stickyHeader
			rowKeyField='id'
			width={1750}
		/>
	);
};

export default ProgressTable;
