import { dispatch, useSelector } from 'src/redux/store';
import { selectModal } from 'src/redux/slices/modal';
import {
	getTransactionParticipantsThunk,
	makeAnOffer,
	makeCounterOffer,
	selectTransactionOverview,
} from 'src/redux/slices/transaction';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { extractBidValue, formatBidValue } from 'src/utils/formatBid';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import modals from 'src/constants/modals';
import PriceField from 'src/components/PriceField';
import { IStateTransactionOffer } from 'src/redux/types';
import { LocalUser } from 'src/components/InviteCard/types';
import { ConfirmationModal } from 'src/components/modals';
import useLoading from 'src/hooks/useLoading';
import { counterOfferSchema } from './validationSchemas';
import { ICounterOfferForm } from './types';

const CounterOfferModal = () => {
	const transaction = useSelector(selectTransactionOverview);
	const { payload } = useSelector(
		selectModal<{ offer?: IStateTransactionOffer; user?: LocalUser; isViewSide?: boolean }>,
	);
	const { isLoading, setIsLoading } = useLoading();
	const form = useForm<ICounterOfferForm>({
		resolver: yupResolver(counterOfferSchema),
		values: { price: payload.offer?.price ? (formatBidValue(`${payload.offer?.price}`) as string) : '' },
	});

	const handleConfirm = async (data: ICounterOfferForm) => {
		if (!payload.offer) return;

		setIsLoading(true);

		const price = extractBidValue(data.price);

		if (payload.isViewSide) {
			await dispatch(makeAnOffer({ id: transaction.id, price, offerId: payload.offer.id }));
		} else {
			await dispatch(makeCounterOffer({ offerId: payload.offer.id, price }));
		}

		setIsLoading(false);
		handleCloseModal();
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	};

	return (
		<ConfirmationModal
			sx={{ minWidth: '550px' }}
			modalName={modals.counterOffer}
			title='MAKE A COUNTEROFFER'
			handleConfirm={form.handleSubmit(handleConfirm)}
			confirmText='Continue'
			cancelText='Cancel'
			isLoading={isLoading}
			description={
				<FormProvider {...form}>
					<>
						Enter your counteroffer to{' '}
						<b>
							{payload.user?.givenNames} {payload.user?.lastNames}
						</b>
						's offer: <br />
						<br />
					</>
					<PriceField name='price' />
				</FormProvider>
			}
		/>
	);
};

export default CounterOfferModal;
