import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDownloadURL, ref } from 'firebase/storage';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { liabilities } from 'src/constants/surveyJson/liabilities';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { storages } from 'src/api/@';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { fromObjToString } from '../localConsts';
import { DescriptionBlock } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';
import { LiabilitiesEnum } from './types';

export const QuestionnaireLiabilitiesCard = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const { fullQuestionnaire: propertyQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const transaction = useSelector(selectTransactionOverview);
	const [titlePlanImageUrl, setTitlePlanImageUrl] = useState<string>();
	const [trigger, setTrigger] = useState(false);

	const { onSubmit, onPageChanged, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.liabilities,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	const completedLiabilities = fillQuestionnaireJsonWithCompletedData(
		liabilities(transaction.tenure, propertyQuestionnaire, titlePlanImageUrl),
		fullQuestionnaire?.liabilities,
	);

	useEffect(() => {
		const setup = async () => {
			if (!transaction.documents?.titlePlanImage) return;

			const url = await getDownloadURL(ref(storages.properties, transaction.documents.titlePlanImage));

			setTitlePlanImageUrl(url);
			setTrigger((t) => !t);
		};

		setup();
	}, [transaction.documents?.titlePlanImage]);

	if (isHidden) return null;

	return (
		<>
			<QuestionnarieModal
				initialCurrentPage={fullQuestionnaire?.liabilities.currentPage}
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				questionnaireKey={QuestionnaireTransactionEnum.liabilities}
				onSubmit={onSubmit}
				onPageChanged={onPageChanged}
				onComplete={onComplete}
				surveyJson={completedLiabilities}
				status={fullQuestionnaire?.liabilities.status}
				title='CONCERNS'
				trigger={trigger}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Concerns'
				type={QuestionnaireTransactionEnum.liabilities}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => (
					<>
						<DescriptionBlock>
							{fromObjToString(details?.potential_liabilities?.liabilities ?? {}, LiabilitiesEnum)}
						</DescriptionBlock>
						{![details?.disputes.disputes_complaints_yn, details?.disputes.disputes_complaints_future_yn].includes(
							null,
						) && (
							<>
								<DescriptionBlock style={{ marginBottom: '8px' }}>
									Disputes and complaints:&nbsp;
									<b>
										{details?.disputes.disputes_complaints_yn || details?.disputes.disputes_complaints_future_yn
											? 'Yes'
											: 'No'}
									</b>
								</DescriptionBlock>
								{![
									details?.notices_proposals.notices_proposals_yn,
									details?.notices_proposals.planned_development_yn,
								].includes(null) && (
									<DescriptionBlock>
										Notices and proposals:&nbsp;
										<b>
											{details?.notices_proposals.notices_proposals_yn ||
											details?.notices_proposals.planned_development_yn
												? 'Yes'
												: 'No'}
										</b>
									</DescriptionBlock>
								)}
							</>
						)}
					</>
				)}
			</QuestionnaireCard>
		</>
	);
};
