import { Box, InputProps } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import { SetValueConfig, useFormContext } from 'react-hook-form';
import { calculatePriceWithoutVAT, calculatePriceWithVAT } from 'src/utils/transaction/summary';
import { VAT } from 'src/constants/common';
import { VATFieldProps } from './types';
import { formatInputValueToPrice, separateNumberStringThousands } from './utils';

const inputPropsWithPoundPrefix: InputProps = {
	startAdornment: <Iconify icon='mdi:sterling' fontSize={23} color='#697885' mr='3px' />,
};

const setValueOptions: SetValueConfig = {
	shouldDirty: true,
	shouldTouch: true,
	shouldValidate: true,
};

const VATField: FC<VATFieldProps> = ({ name, inputProps = inputPropsWithPoundPrefix, vat = VAT, disabled = false }) => {
	const nameWithoutVat = `${name}.withoutVat`;
	const nameWithVat = `${name}.withVat`;

	const { setValue, watch } = useFormContext();

	const [withoutVat, withVat] = watch([nameWithoutVat, nameWithVat]);

	const handleVatExcludedChange = (e: ChangeEvent<HTMLInputElement>) => {
		const formattedValue = formatInputValueToPrice(e.target.value);
		const isEmpty = formattedValue === '';

		setValue(nameWithoutVat, isEmpty ? null : formattedValue, setValueOptions);
		setValue(nameWithVat, isEmpty ? null : calculatePriceWithVAT(+formattedValue, vat), setValueOptions);
	};
	const handleVatIncludedChange = (e: ChangeEvent<HTMLInputElement>) => {
		const formattedValue = formatInputValueToPrice(e.target.value);
		const isEmpty = formattedValue === '';

		setValue(nameWithVat, isEmpty ? null : formattedValue, setValueOptions);
		setValue(nameWithoutVat, isEmpty ? null : calculatePriceWithoutVAT(+formattedValue, vat), setValueOptions);
	};

	return (
		<Box display='flex' gap='10px' whiteSpace='nowrap' alignItems='center'>
			<RHFTextField
				name={nameWithoutVat}
				placeholder='VAT Excluded'
				InputProps={inputProps}
				onChange={handleVatExcludedChange}
				value={separateNumberStringThousands(withoutVat?.toString() || '')}
				disabled={disabled}
			/>
			+ VAT =
			<RHFTextField
				name={nameWithVat}
				placeholder='VAT Included'
				InputProps={inputProps}
				onChange={handleVatIncludedChange}
				value={separateNumberStringThousands(withVat?.toString() || '')}
				disabled={disabled}
			/>
		</Box>
	);
};

export default VATField;
