import { Box, Button, IconButton } from '@mui/material';
import { FC } from 'react';
import Iconify from 'src/components/Iconify';
import modals from 'src/constants/modals';
import { openModal } from 'src/redux/slices/modal';
import { dispatch } from 'src/redux/store';
import { Payment, PaymentStatusEnum } from 'src/types';

const PaymentField: FC<WithId<Payment> & { path: string }> = ({ path, ...payment }) => {
	const { status, paymentUrl } = payment;
	const isPaid = payment.status === PaymentStatusEnum.PAID;

	const handleDelete = async () => {
		dispatch(openModal({ name: modals.deletePayment, payload: { ...payment, path } }));
	};
	const handleReportPaid = () => dispatch(openModal({ name: modals.reportPaidPayment, payload: payment }));

	return (
		<Box display='flex' justifyContent='space-between' alignItems='center'>
			<Box>
				{status === PaymentStatusEnum.PAID ? '🟢' : '🟡'}{' '}
				<a href={paymentUrl} target='_blank' rel='noreferrer'>
					Link
				</a>
				{status !== PaymentStatusEnum.PAID && (
					<Button sx={{ ml: '15px' }} onClick={handleReportPaid}>
						Enter manually
					</Button>
				)}
			</Box>
			{!isPaid && (
				<IconButton onClick={handleDelete}>
					<Iconify icon='icon-park-solid:close-one' fontSize='25px' />
				</IconButton>
			)}
		</Box>
	);
};

export default PaymentField;
