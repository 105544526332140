import { call } from '../@';
import { PropertyAddress } from '../property/add';

export type SubmitQuoteTransactionAddressPayload = {
	id: string;
	transactionId: string;
	uprn: number;
	address: PropertyAddress;
};

export default async function submitQuoteTransactionAddress(payload: SubmitQuoteTransactionAddressPayload) {
	return await call('submitQuoteTransactionAddress', payload);
}
