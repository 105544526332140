import { call } from '../@';

export type DiscussQuotePayload = {
	id: string;
	token: string;
};

export default async function discussQuote(payload: DiscussQuotePayload) {
	return await call('discussQuote', payload);
}
