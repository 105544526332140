import { TransactionRole, TransactionSide, TransactionRelationship } from 'src/pages/transactions/types';
import { call } from '../@';

export type Payload = {
	propertyId: string;
	role?: TransactionRole;
	side?: TransactionSide;
	relationship?: TransactionRelationship;
	registerId?: string;
	isInstrument?: boolean;
};

export default async function addTransaction(payload: Payload) {
	return await call('transaction-add', payload);
}
