import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { Task } from 'src/redux/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { getTaskRef } from 'src/utils/firebase';
import { getTransactionTrackerThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { updateDoc } from 'firebase/firestore';
import { taskStatus } from 'src/constants/tasks';
import { useSnackbar } from 'notistack';
import createExchangePayments from 'src/api/transaction/create-exchange-payments';
import syncTransactionTasks from 'src/api/transaction/sync-transaction-tasks';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<{ task: Task; type: string }>);

	const taskRef = getTaskRef(transaction.id, payload.task.id);

	const handleContinue = async () => {
		try {
			setIsLoading(true);

			await createExchangePayments(transaction.id, true);

			await updateDoc<unknown>(taskRef, {
				status: taskStatus.done,
				completedAt: new Date(),
				...(!payload.task.dueDate && { dueDate: new Date() }),
			});

			await syncTransactionTasks(transaction.id);

			dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Typography sx={styledMainTitle}>NO ONBOARDED {payload.type.toUpperCase()}</Typography>
			<Typography variant='subtitle1' mb='7px' mt='7px'>
				Valid {payload.type === 'sellers' ? 'sale' : 'purchase'} quote but no onboarded {payload.type} found.
			</Typography>
			<Typography variant='subtitle1' mb='7px'>
				No invoice will be sent out to the {payload.type}.
			</Typography>{' '}
			<Typography variant='subtitle1' mb='7px'>
				Do you still want to proceed?
			</Typography>
			<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center' mt='20px'>
				<LoadingButton variant='outlined' onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' loading={isLoading} onClick={handleContinue}>
					Continue
				</LoadingButton>
			</Box>
		</Box>
	);
};

const NoExchangeOnboardedUsersModal = () => (
	<Modal name={modals.noExchangeOnboardedUsers} cardSx={{ maxWidth: '600px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default NoExchangeOnboardedUsersModal;
