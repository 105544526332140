import { FC } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { labelByTransactionPosition } from 'src/pages/transactions/constants';
import InviteCard from 'src/components/InviteCard/InviteCard';
import { companiesColors } from '../../constants';
import { CompanyProps } from './types';

const Company: FC<CompanyProps> = ({ index, getActions, ...company }) => (
	<>
		{company.participants.map((s, i) => {
			const isFirst = i === 0;
			const isLast = i === company.participants.length - 1;
			const companyLabel = `${company.companyName} (${
				company.parentName ? `PWSC of ${company.parentName}` : 'Proprietor'
			})`;

			return (
				<Box
					key={s.uid}
					sx={{
						background: companiesColors[index],
						...(isFirst && { borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px' }),
						...(isLast && { borderTopRightRadius: '15px', borderBottomRightRadius: '15px' }),
					}}
					pt='15px'
					position='relative'
				>
					{!!isFirst && (
						<Typography
							position='absolute'
							top='7px'
							left='15px'
							fontWeight={700}
							whiteSpace='nowrap'
							fontSize='14px'
							overflow='hidden'
							textOverflow='ellipsis'
							width='415px'
							title={companyLabel}
						>
							{companyLabel}
						</Typography>
					)}
					<InviteCard
						user={s}
						menuOptions={getActions(s)}
						customOnTitleLabel='Listed as'
						{...(s.positions?.length && {
							customRole: (
								<>
									{s.positions.map((p, positionIndex) => {
										const isLastPosition = positionIndex === (s.positions?.length ?? 0) - 1;

										return (
											<>
												{labelByTransactionPosition[p]} {!isLastPosition && <br />}
											</>
										);
									})}
								</>
							),
						})}
					/>
				</Box>
			);
		})}
	</>
);

export default Company;
