import { getDocs, query, QueryDocumentSnapshot, where } from 'firebase/firestore';
import { getPropertyQuestionnaireCollection, getTransactionQuestionnaireCollection } from 'src/constants/firestore';
import { FullQuestionnaireByStringType, QuestionnaireTypeEnum } from 'src/types';
import { getPropertyRef } from 'src/utils/firebase';

export default async function getQuestionnaireById<T extends QuestionnaireTypeEnum>(id: string, type: T) {
	if (type === QuestionnaireTypeEnum.TRANSACTION) {
		const { docs } = await getDocs(getTransactionQuestionnaireCollection(id));

		return docs[0] as QueryDocumentSnapshot<FullQuestionnaireByStringType<T>>;
	}

	const questionnarieQuery = query<unknown>(
		getPropertyQuestionnaireCollection(id),
		where('propertyRef', '==', getPropertyRef(id)),
	);

	const {
		docs: [questionnaire],
	} = await getDocs(questionnarieQuery);

	if (!questionnaire) throw new Error(`Questionnaire (${type}) ${id} doesn't exist`);

	return questionnaire as QueryDocumentSnapshot<FullQuestionnaireByStringType<T>>;
}
