import { useEffect } from 'react';

import InviteCard from 'src/components/InviteCard/InviteCard';
import { useDispatch, useSelector } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import { IInviteCardMenuOption, LocalUser } from 'src/components/InviteCard/types';
import {
	getTransactionParticipantsThunk,
	removeTransactionPartie,
	selectTransactionOverview,
	selectTransactionParticipantsField,
} from 'src/redux/slices/transaction';
import { emptyTransactionBuyer, emptyTransactionSeller } from 'src/constants/mock';
import { selectUser } from 'src/redux/slices/auth';
import modals from 'src/constants/modals';
import { useSnackbar } from 'notistack';
import Progress from 'src/components/Progress';
import { Box } from '@mui/material';
import { selectTransactionQuestionnaire } from 'src/redux/slices/transactionQuestionnaire';
import { IExtendedQuestionnaireTransaction } from 'src/types';
import ErrorView from 'src/components/ErrorView';
import { ParticipantsCategories } from './style';
import { TransactionSide } from '../types';
import PromoteBuyerModal from '../modals/PromoteBuyerModal';
import ConfirmPriceModal from '../modals/ConfirmPriceModal';
import SaleConfirmationModal from '../modals/SaleConfirmationModal';
import CounterOfferModal from '../modals/CounterOfferModal';
import Category from './components/Category';
import {
	getQuestionnaireMenuOptions,
	getPlaceholderMenuOptions,
	getResendInvitationMenuOption,
	getVerificationIdMenuOptions,
	getCancelInvitationMenuOption,
} from './utils';
import WithdrawOfferModal from '../modals/WithdrawOfferModal';
import AcceptAskPriceConfirmationModal from '../modals/AcceptAskPriceConfirmationModal';
import { AcceptOfferModal } from '../modals/AcceptOfferModal';
import Company from './components/Company';

const MyParticipants = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const currentUser = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);
	const questionnaire = useSelector(selectTransactionQuestionnaire) as IExtendedQuestionnaireTransaction;
	const participants = useSelector(selectTransactionParticipantsField);

	const handleInvite = (side?: TransactionSide) => () => {
		dispatch(openModal({ name: modals.invitation, payload: { side } }));
	};

	// const getViewSideActions = useCallback(
	// 	(user: LocalUser): IInviteCardMenuOption[] => {
	// 		const isMe = user.uid === currentUser.uid;
	// 		const isAddedByMe = user.addedBy?.uid === currentUser.uid;
	// 		const actions: IInviteCardMenuOption[] = [
	// 			...getPlaceholderMenuOptions(transaction.id, currentUser, user),
	// 			...getVerificationIdMenuOptions(currentUser.isAdmin, currentUser.uid, user, enqueueSnackbar),
	// 			...getQuestionnaireMenuOptions(currentUser, user, questionnaire),
	// 		];

	// 		// if (transaction.isBuyerViewer && isMe && user.counterOffer) {
	// 		// 	actions.push({
	// 		// 		label: 'View a counter-offer',
	// 		// 		onClick: () =>
	// 		// 			dispatch(openModal({ name: modals.viewCounterOffer, payload: { offerId: user.counterOffer?.id } })),
	// 		// 	});
	// 		// }

	// 		if (transaction.isBuyerViewer && isMe && user.offer) {
	// 			actions.push({
	// 				label: 'Withdraw offer',
	// 				onClick: () => dispatch(openModal({ name: modals.withdrawOffer, payload: { offerId: user.offer?.id } })),
	// 			});
	// 		}

	// 		// if (user.isActive && transaction.isSeller) {
	// 		// 	actions.push({
	// 		// 		label: 'Confirm Buyer',
	// 		// 		onClick: () => dispatch(openModal({ name: modals.promoteBuyer, payload: { userId: user.uid } })),
	// 		// 	});
	// 		// }

	// 		// if (transaction.isSeller && user.latestOffer) {
	// 		// 	actions.push({
	// 		// 		label: 'Make a counter-offer',
	// 		// 		onClick: () =>
	// 		// 			dispatch(
	// 		// 				openModal({
	// 		// 					name: modals.counterOffer,
	// 		// 					payload: {
	// 		// 						userId: user.uid,
	// 		// 						price: user.counterOffer?.price ?? user.latestOffer?.price,
	// 		// 						offerId: user.latestOffer?.id,
	// 		// 					},
	// 		// 				}),
	// 		// 			),
	// 		// 	});
	// 		// }

	// 		if (!user.approved && (isAddedByMe || currentUser.isAdmin) && !user.isPlaceholder) {
	// 			actions.push(
	// 				getResendInvitationMenuOption(
	// 					{ role: user.role, side: user.side, userId: user.uid, transactionId: transaction.id },
	// 					enqueueSnackbar,
	// 				),
	// 			);
	// 		}

	// 		if ((transaction.isBuyer || transaction.isSeller || currentUser.isAdmin) && !user.isPlaceholder) {
	// 			actions.push({
	// 				label: 'Remove',
	// 				onClick: () => {
	// 					dispatch(removeTransactionPartie({ id: transaction.id, role: 'buyer', side: 'view', userId: user.uid }));
	// 				},
	// 			});
	// 		}

	// 		return actions;
	// 	},
	// 	[transaction.isBuyerViewer, currentUser.uid, transaction.id],
	// );

	const getBuySideActions = (user: LocalUser): IInviteCardMenuOption[] => {
		const isMe = user.uid === currentUser.uid;
		const isAddedByMe = user.addedById === currentUser.uid;
		const actions: IInviteCardMenuOption[] = [
			...getPlaceholderMenuOptions(transaction.id, currentUser, user),
			...getVerificationIdMenuOptions(currentUser.isAdmin, user, enqueueSnackbar),
			...getQuestionnaireMenuOptions(currentUser, user, questionnaire),
		];

		if (!user.approved && (isAddedByMe || currentUser.isAdmin) && !user.isPlaceholder && user.invitationId) {
			actions.push(
				getResendInvitationMenuOption(
					{ transactionInvitationId: user.invitationId, userId: user.uid, transactionId: transaction.id },
					enqueueSnackbar,
				),
			);
		}

		if (!user.isPlaceholder && !user.approved && (isAddedByMe || currentUser.isAdmin) && user.invitationId) {
			actions.push(
				getCancelInvitationMenuOption(
					{ transactionInvitationId: user.invitationId, userId: user.uid, transactionId: transaction.id },
					enqueueSnackbar,
				),
			);
		}

		if ((user.canRemove || currentUser.isAdmin) && !isMe && !user.isPlaceholder) {
			actions.push({
				label: 'Remove',
				onClick: () => {
					dispatch(removeTransactionPartie({ id: transaction.id, role: user.role, side: 'buy', userId: user.uid }));
				},
			});
		}

		return actions;
	};

	const getSellSideActions = (user: LocalUser): IInviteCardMenuOption[] => {
		const isAddedByMe = user.addedById === currentUser.uid;
		const isMe = user.uid === currentUser.uid;
		const actions: IInviteCardMenuOption[] = [
			...getPlaceholderMenuOptions(transaction.id, currentUser, user),
			...getVerificationIdMenuOptions(currentUser.isAdmin, user, enqueueSnackbar),
			...getQuestionnaireMenuOptions(currentUser, user, questionnaire),
		];

		if (!user.approved && (isAddedByMe || currentUser.isAdmin) && !user.isPlaceholder && user.invitationId) {
			actions.push(
				getResendInvitationMenuOption(
					{ transactionInvitationId: user.invitationId, userId: user.uid, transactionId: transaction.id },
					enqueueSnackbar,
				),
			);
		}

		if (!user.isPlaceholder && !user.approved && (isAddedByMe || currentUser.isAdmin) && user.invitationId) {
			actions.push(
				getCancelInvitationMenuOption(
					{ transactionInvitationId: user.invitationId, userId: user.uid, transactionId: transaction.id },
					enqueueSnackbar,
				),
			);
		}

		if ((user.canRemove || currentUser.isAdmin) && !isMe && !user.isPlaceholder) {
			actions.push({
				label: 'Remove',
				onClick: () => {
					dispatch(removeTransactionPartie({ id: transaction.id, role: user.role, side: 'sell', userId: user.uid }));
				},
			});
		}

		return actions;
	};

	useEffect(() => {
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: participants.isLoading }));
	}, [transaction.id]);

	const buySideParticipants = participants.data?.participants.filter((p) => p.side === 'buy') ?? [];
	const sellSideParticipants = participants.data?.participants.filter((p) => p.side === 'sell') ?? [];
	const sellSideCompanies = participants.data?.companies.filter((c) => c.side === 'sell') ?? [];
	const buySideCompanies = participants.data?.companies.filter((c) => c.side === 'buy') ?? [];
	const hasSellSideCompanies = !!sellSideCompanies.length;
	const hasBuySideCompanies = !!buySideCompanies.length;

	return (
		<ParticipantsCategories height='100%'>
			{participants.error && <ErrorView error={participants.error} />}
			{participants.isLoading && (
				<Box
					position='absolute'
					zIndex={999}
					width='100%'
					height='100%'
					display='flex'
					alignItems='center'
					justifyContent='center'
					bgcolor='white'
					sx={{ opacity: 0.6 }}
				>
					<Progress />
				</Box>
			)}
			{!!participants.data && (
				<>
					<Category title='Sell-Side' withHeader={!transaction.isInstrument}>
						{sellSideParticipants.map((s) => (
							<Box key={s.uid} pt={buySideParticipants ? '15px' : 0}>
								<InviteCard user={s} menuOptions={getSellSideActions(s)} />
							</Box>
						))}
						{sellSideCompanies.map((c, index) => (
							<Company key={c.companyId} {...c} index={index} getActions={getSellSideActions} />
						))}
						{!sellSideParticipants.length && transaction.isBuyer && (
							<Box pt={hasSellSideCompanies ? '15px' : 0}>
								<InviteCard user={emptyTransactionSeller} onClick={handleInvite('sell')} />
							</Box>
						)}
					</Category>
					{!transaction.isInstrument && (
						<>
							<Category title='Buy-Side' isVisible={!!buySideParticipants.length}>
								{buySideParticipants.map((b) => (
									<InviteCard key={b.uid} user={b} menuOptions={getBuySideActions(b)} />
								))}
								{buySideCompanies.map((c, index) => (
									<Company key={c.companyId} {...c} index={index} getActions={getBuySideActions} />
								))}
								{!buySideParticipants.length && transaction.isSeller && !transaction.hasBuyerViewer && (
									<Box pt={hasBuySideCompanies ? '15px' : 0}>
										<InviteCard user={emptyTransactionBuyer} onClick={handleInvite('buy')} />
									</Box>
								)}
							</Category>
						</>
					)}
				</>
			)}
			<PromoteBuyerModal />
			<ConfirmPriceModal />
			<SaleConfirmationModal />
			<CounterOfferModal />
			<WithdrawOfferModal />
			<AcceptAskPriceConfirmationModal />
			<AcceptOfferModal />
		</ParticipantsCategories>
	);
};

export default MyParticipants;
