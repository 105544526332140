import { TransactionRelationship } from 'src/pages/transactions/types';
import { call } from '../@';
import { SuccessResponse } from '../@/api-methods';

export type UpdateTransactionUserDto = {
	id: string;
	userId: string;
	givenNames: string;
	lastNames: string;
	phone?: string;
	businessId?: string;
	relationship?: TransactionRelationship;
	branchId?: string;
	business?: string;
	branch?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	address?: any;
};

export type UpdateTransactionUserResponse = SuccessResponse;

export default async function updateTransactionUser(dto: UpdateTransactionUserDto) {
	return await call('transaction-updateUser', dto);
}
