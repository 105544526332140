import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import { getSolicitorsReportsThunk, selectSolicitorsReports, sortSolicitorsReports } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import Iconify from 'src/components/Iconify';
import useLoading from 'src/hooks/useLoading';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { downloadCsv } from 'src/utils/csv';
import { SolicitorReport } from 'src/redux/types';
import { TableColumn } from 'src/components/common/Table/types';
import { formatPrice } from 'src/utils/transaction/summary';
import { Table } from 'src/components';
import Row from 'src/components/common/Table/components/Row';

const SolicitorsTable = () => {
	const { data: reports, isLoading: areReportsLoading, error } = useSelector(selectSolicitorsReports);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleSortChange = ({ order, orderBy }) => dispatch(sortSolicitorsReports({ order, orderBy }));

	useEffect(() => {
		dispatch(getSolicitorsReportsThunk());
	}, []);

	if (areReportsLoading) return <Progress />;
	if (error || !reports.data) {
		return (
			<Box p='15px'>
				<Typography color='red' variant='h4'>
					{error?.message ?? 'Something went wrong'}
				</Typography>
			</Box>
		);
	}

	const columns: TableColumn<SolicitorReport>[] = [
		{ key: 'name', name: 'Solicitor', sortable: true, width: 200 },
		{ key: 'firm', name: 'Firm', sortable: true, width: 175 },
		{ key: 'livePendingTransactions', name: 'Pending', sortable: true, width: 200 },
		{ key: 'liveApprovedTransactions', name: 'Live', sortable: true, width: 200 },
		{ key: 'totalApprovedTransactions', name: 'columnKey', sortable: true, width: 200 },
		{
			key: 'totalRevenue',
			name: 'Total quoted (incl VAT)',
			sortable: true,
			width: 200,
			getLabel: (row) => formatPrice(row.totalRevenue),
		},
		{
			key: 'totalPaidOut',
			name: 'Total paid out (incl VAT)',
			sortable: true,
			width: 200,
			getLabel: (row) => formatPrice(row.totalPaidOut),
		},
		{
			key: 'averageSellSideTransactionTime',
			name: 'Avg transaction time (sellside)',
			sortable: true,
			width: 200,
			getLabel: (row) => (row.averageSellSideTransactionTime ? `${row.averageSellSideTransactionTime} days` : 'NaN'),
		},
		{
			key: 'averageBuySideTransactionTime',
			name: 'Avg transaction time (buyside)',
			sortable: true,
			width: 200,
			getLabel: (row) => (row.averageBuySideTransactionTime ? `${row.averageBuySideTransactionTime} days` : 'NaN'),
		},
	];

	const handleDownload = () => {
		try {
			if (!reports.data) return;

			setIsLoading(true);

			const rows = [
				columns.map((c) => c.name),
				...[...reports.data.rows, reports.data.total].map((row) =>
					columns.map((c) => (c.getLabel ? c.getLabel(row) : row[c.key])),
				),
			];

			const date = moment().format('YYYY_MM_DD');

			downloadCsv(`solicitors_report_${date}.csv`, rows);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Table
				columns={columns}
				data={{
					data: {
						data: reports.data.rows,
						limit: 0,
						page: 0,
						totalCount: 0,
						totalPages: 0,
						sortOrder: reports.order,
						orderBy: reports.orderBy,
					},
					isInitialized: !isLoading,
				}}
				handleSortChange={handleSortChange}
				withPagination={false}
				stickyHeader
				rowKeyField='id'
				width={1750}
				headSx={{ borderBottom: '1px solid grey' }}
			>
				<Row columns={columns} row={reports.data.total} sx={{ background: 'lightgrey' }} />
			</Table>
			<Box pt='10px'>
				<LoadingButton variant='contained' onClick={handleDownload} loading={isLoading}>
					Download CSV
					<Iconify icon='mdi:file' fontSize={20} ml='5px' />
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default SolicitorsTable;
