export const STATES_COLLECTION_NAME = 'state-regions';
export const ROLES_COLLECTION_NAME = 'roles';
export const USERS_COLLECTION_NAME = 'users';
export const SETTINGS_COLLECTION_NAME = 'settings';
export const CITIES_COLLECTION_NAME = 'town-cities';
export const BRANCHES_COLLECTION_NAME = 'branches';
export const FIRMS_COLLECTION_NAME = 'firms';
export const PROPERTIES_COLLECTION_NAME = 'properties';
export const PROPERTY_ONBOARDINGS_COLLECTION_NAME = 'property-onboardings';
export const TRANSACTION_ONBOARDINGS_COLLECTION_NAME = 'transaction-onboardings';
export const PROPERTY_LISTINGS_COLLECTION_NAME = 'property-listings';
export const PROPERTY_PRICES_COLLECTION_NAME = 'property-prices';
export const TRANSACTIONS_COLLECTION_NAME = 'transactions';
export const TRACKERS_COLLECTION_NAME = 'transaction-trackers';
export const INVITATIONS_COLLECTION_NAME = 'invitations';
export const TRANSACTION_INVITATIONS_COLLECTION_NAME = 'invitations';
export const TRANSACTION_OFFERS_COLLECTION_NAME = 'transaction-offers';
export const PROPERTY_QUESTIONNAIRES_COLLECTION_NAME = 'property-questionnaires';
export const TRANSACTION_QUESTIONNAIRES_COLLECTION_NAME = 'transaction-questionnaires';
export const VERIFICATION_SESSIONS_COLLECTION_NAME = 'verification-sessions';
export const PROFESSIONS_COLLECTION_NAME = 'professions';
export const PAYMENTS_COLLECTION_NAME = 'payments';
export const REGISTERS_COLLECTION_NAME = 'register';
export const USER_ONBOARDINGS_COLLECTION_NAME = 'user-onboardings';
export const EPC_COLLECTION_NAME = 'epc';
export const COUNTRIES_COLLECTION_NAME = 'countries';
export const USER_CHANGE_EMAIL_REQUESTS_COLLECTION_NAME = 'change-email-requests';
export const ROOMS_COLLECTION_NAME = 'rooms';
export const TRANSACTION_SUMMARIES_COLLECTION_NAME = 'transaction-summaries';
export const TASKS_COLLECTION_NAME = 'tasks';
