import { FC, useMemo } from 'react';
import { convertTrackerStepStatusToText } from 'src/utils/tracker';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import { TrackerStepStatus } from 'src/types';
import Iconify from 'src/components/Iconify';
import { makeTooltipStyles, TrackerStepETA, TrackerStepTitle, TrackerStepWrapper } from './styles';
import { ITrackerStep } from './types';
import { colorByTrackerStepStatus } from './constants';

const InProgress = <Iconify color='#4d88f0' icon='material-symbols:pending' fontSize={70} />;
const Pending = <Iconify color='#fac72d' icon='material-symbols:error-rounded' fontSize={70} />;
const Completed = <Iconify icon='material-symbols:check-circle-rounded' color='#1aba22' fontSize={70} />;
const Error = <Iconify icon='material-symbols:cancel-rounded' color='#f0292c' fontSize={70} />;

const TrackerStep: FC<ITrackerStep> = ({ title, status, order, date, step }) => {
	const statusBasedOnEta: TrackerStepStatus = useMemo(() => {
		if (status === 'inProgress' && date) {
			const days = moment().diff(new Date(date), 'd');

			if (days >= 1) return 'pending';
		}

		return status;
	}, [status, date]);

	const content = useMemo(() => {
		switch (statusBasedOnEta) {
			case 'inProgress':
				return InProgress;
			case 'pending':
				return Pending;
			case 'completed':
				return Completed;
			case 'error':
				return Error;
			default:
				return order;
		}
	}, [statusBasedOnEta, order, date]);

	const isCompleted = statusBasedOnEta === 'completed' && !!date;
	const isListing = step === 'listing';
	const tooltipTitle = convertTrackerStepStatusToText(statusBasedOnEta);
	const tooltip = makeTooltipStyles();

	return (
		<Tooltip title={tooltipTitle} arrow placement='left' classes={tooltip}>
			{isListing ? (
				<TrackerStepWrapper width='50px !important' height='50px !important'>
					<Iconify icon='mdi:handshake-outline' fontSize='30px' color={colorByTrackerStepStatus[status]} />
				</TrackerStepWrapper>
			) : (
				<TrackerStepWrapper>
					{!!date && (
						<TrackerStepETA isCompleted={isCompleted}>{moment(new Date(date)).format('DD MMM')}</TrackerStepETA>
					)}
					{content}
					<TrackerStepTitle>{title}</TrackerStepTitle>
					{/* <TrackerStepStages>
					{Object.keys(stages).map((s) => {
						const stage = stages[s];

						return (
							<TrackerStepStage key={s}>
								{iconByTrackerStepStatus[stage.status]} {labelByTrackerStage[s]}
							</TrackerStepStage>
						);
					})}
				</TrackerStepStages> */}
				</TrackerStepWrapper>
			)}
		</Tooltip>
	);
};

export default TrackerStep;
