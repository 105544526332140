import { TransactionState } from 'src/components/modals/transaction/UpdateTransactionState/types';
import { transactionStatus } from 'src/components/TransactionSummary/constants';
import { ITransactionSummary } from 'src/redux/types';
import { getSortingComparator } from 'src/utils/common';
import { convertStringToSortingNumber } from 'src/utils/strings';

export const getTransactionIconColorByDaysCount = ({ status, days }: ITransactionSummary['status']) => {
	if (typeof days !== 'number') return 'green';

	if (status === transactionStatus.inProgress) {
		if (days >= 30) return '#e9d01a';
		if (days >= 15) return '#9eeb33';

		return 'green';
	}

	if (status === transactionStatus.pending) {
		if (days >= 125) return 'red';
		if (days >= 75) return '#ea5239';

		return '#e77534';
	}

	return 'green';
};

export const stateSortingOrder: Record<TransactionState, number> = {
	LIVE: 1,
	SECURED_SALE: 1,
	ARCHIVED: 2,
	STANDBY: 3,
	DEMO: 4,
	DEFUNCT: 5,
	WITHDRAWAL: 6,
	FALL_THROUGH: 7,
	ERROR: 8,
};

export const getTransactionSummariesSorter = (order: string, orderBy: string) =>
	getSortingComparator(order, (summary: ITransactionSummary) => {
		const defaultValue = summary.isPinned ? (order === 'desc' ? 1e16 : -1e16) : 0;

		if (orderBy === 'askPrice') return defaultValue - (summary.general.askOffer?.price ?? 0);
		if (orderBy === 'name') return defaultValue - convertStringToSortingNumber(summary.general.name);
		if (orderBy === 'lastUpdatedOn') {
			return (
				defaultValue - (summary.general.latestActionDate ? new Date(summary.general.latestActionDate).getTime() : 0)
			);
		}
		if (orderBy === 'createdDate') {
			return defaultValue - (summary.general.createdDate ? new Date(summary.general.createdDate).getTime() : 0);
		}

		return 0;
	});
