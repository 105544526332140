/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getQuestionnaireById } from 'src/api';
import { questionnaireNotFoundError } from 'src/constants/questionnaire';
import { IExtendedQuestionnaireTransaction, QuestionnaireTypeEnum } from 'src/types';
import { QuestionnaireTransactionType } from 'src/utils/types';
import { dispatch, RootState } from '../store';
import {
	GetQuestionnaireThunkPayload,
	GetQuestionnaireThunkResponse,
	QuestionnaireState,
	QuestionnaireThunkOptions,
	UpdateQuestionnaireThunkPayload,
} from '../types/questionnaire';
import { getNativeErrorMessage } from '../utils/common/error-handler';
import { clearDoneQuestionnaire } from '../utils/questionnaire/clear-done';
import { updateQuestionnaire } from '../utils/questionnaire/update';
import {
	getDynamicTransactionQuestionnairesThunk,
	getTransactionQuestionnaireFinanceThunk,
	selectTransactionOverview,
	selectTransactionOverviewField,
} from './transaction';

const initialState: QuestionnaireState<IExtendedQuestionnaireTransaction> = {
	fullQuestionnaire: null,
	errorMessage: '',
	loading: true,
};

export const selectTransactionQuestionnaireSlice = (state: RootState) => state.transactionQuestionnaire;
export const selectTransactionQuestionnaire = (state: RootState) => state.transactionQuestionnaire.fullQuestionnaire;

export const getTransactionQuestionnaire = createAsyncThunk<
	GetQuestionnaireThunkResponse<QuestionnaireTypeEnum.TRANSACTION>,
	GetQuestionnaireThunkPayload,
	QuestionnaireThunkOptions
>('transactionQuestionnaire/get', async ({ id }, { rejectWithValue }) => {
	try {
		const questionnaire = await getQuestionnaireById(id, QuestionnaireTypeEnum.TRANSACTION);

		return { questionnaire };
	} catch (error) {
		return rejectWithValue(getNativeErrorMessage(error) || questionnaireNotFoundError);
	}
});

export const refreshTransactionQuestionnaire = createAsyncThunk<
	IExtendedQuestionnaireTransaction,
	void,
	QuestionnaireThunkOptions
>('transactionQuestionnaire/refresh', async (_, { rejectWithValue, getState }) => {
	try {
		const fullQuestionnaire = selectTransactionQuestionnaire(getState() as RootState);
		const transaction = selectTransactionOverviewField(getState() as RootState);
		const questionnaire = await getQuestionnaireById(
			fullQuestionnaire?.transaction?.id ?? transaction.data?.id ?? '',
			QuestionnaireTypeEnum.TRANSACTION,
		);

		dispatch(getDynamicTransactionQuestionnairesThunk());
		dispatch(getTransactionQuestionnaireFinanceThunk());

		return {
			...questionnaire.data(),
			id: questionnaire.id,
		};
	} catch (error) {
		return rejectWithValue(getNativeErrorMessage(error) || questionnaireNotFoundError);
	}
});

export const updateTransactionQuestionnaire = createAsyncThunk<
	void,
	UpdateQuestionnaireThunkPayload,
	QuestionnaireThunkOptions
>('transactionQuestionnaire/update', async ({ type, data }, { getState, rejectWithValue }) => {
	try {
		const state = getState() as RootState;
		const fullQuestionnaire = selectTransactionQuestionnaire(state);
		const transaction = selectTransactionOverview(state);

		return await updateQuestionnaire({
			questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
			type,
			data,
			fullQuestionnaire,
			id: transaction.id,
		});
	} catch (error) {
		return rejectWithValue(getNativeErrorMessage(error));
	}
});

export const clearDoneTransactionQuestionnaire = createAsyncThunk<
	void,
	QuestionnaireTransactionType,
	QuestionnaireThunkOptions
>('transactionQuestionnaire/clear', async (type, { getState, rejectWithValue }) => {
	try {
		const state = getState() as RootState;
		const fullQuestionnaire = selectTransactionQuestionnaire(state);
		const transaction = selectTransactionOverview(state);

		return await clearDoneQuestionnaire({
			questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
			type,
			fullQuestionnaire,
			id: transaction.id,
		});
	} catch (error) {
		return rejectWithValue(getNativeErrorMessage(error));
	}
});

const slice = createSlice({
	name: 'transactionQuestionnaire',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getTransactionQuestionnaire.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getTransactionQuestionnaire.fulfilled, (state, { payload: { questionnaire } }) => {
			if (questionnaire.exists()) state.fullQuestionnaire = { id: questionnaire.id, ...questionnaire.data() };
			state.loading = false;
		});
		builder.addCase(getTransactionQuestionnaire.rejected, (state, { payload }) => {
			state.errorMessage = payload as ValidationErrors;
			state.loading = false;
		});
		builder.addCase(updateTransactionQuestionnaire.rejected, (state, { payload = '' }) => {
			state.errorMessage = payload;
		});
		builder.addCase(clearDoneTransactionQuestionnaire.rejected, (state, { payload = '' }) => {
			state.errorMessage = payload;
		});
		builder.addCase(refreshTransactionQuestionnaire.fulfilled, (state, { payload }) => {
			state.fullQuestionnaire = payload;
		});
	},
});

export default slice.reducer;
