import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { InitialAddress } from 'src/components/GoogleAddressAutoComplete/GooglePlaceAutocomlete';
import Modal from 'src/components/customModal';
import { FormProvider } from 'src/components/hook-form';
import modals from 'src/constants/modals';
import { LoadingButtonStyled } from 'src/pages/properties/style';
import { SubtitleStyled, TitleStyled } from 'src/pages/transactions/modals/style';
import { addNewProperty } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import { PATH_PROPERTIES } from 'src/routes/paths';
import { extractNewPropertyAddress, getNav, handleCloseModal } from 'src/utils/propertyHelpers';
import UprnForm from 'src/components/UprnForm';
import addProperty from 'src/api/property/add';
import useLoading from 'src/hooks/useLoading';
import useError from 'src/hooks/useError';
import { Box } from '@mui/system';
import { newPropertyDataSchema } from './validationSchema';

export const hardcodeCountry = 'England';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const { error, setError } = useError();
	const navigate = useNavigate();

	const methods = useForm({
		resolver: yupResolver(newPropertyDataSchema),
		defaultValues: InitialAddress,
		mode: 'onChange',
	});

	const {
		handleSubmit,
		reset,
		watch,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (data) => {
		try {
			setError(null);
			const { propertyId } = await addProperty({ uprn: data.uprn, address: extractNewPropertyAddress(data.address) });

			getNav();
			dispatch(addNewProperty(propertyId));

			navigate(PATH_PROPERTIES.view(propertyId));
			reset();
			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) setError(e.message);
		}
	};
	const [postcodeWatcher, uprnWatcher] = watch(['address.postcode', 'uprn']);

	return (
		<>
			<TitleStyled>Add property</TitleStyled>
			<SubtitleStyled>Enter the property postcode and choose an address.</SubtitleStyled>
			{error}
			<Box sx={{ mb: '7px' }} />
			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<UprnForm setError={setError} isLoading={isLoading} setIsLoading={setIsLoading} />

				<Stack>
					<LoadingButtonStyled
						type='submit'
						variant='contained'
						loading={isSubmitting || isLoading}
						size='large'
						disabled={!uprnWatcher || !postcodeWatcher || !!error}
					>
						Add Property
					</LoadingButtonStyled>
				</Stack>
			</FormProvider>
		</>
	);
};

const PropertyCreateModal = (props) => (
	<Modal name={modals.addProperty} cardSx={{ maxWidth: '630px', overflow: 'visible' }}>
		<Content {...props} />
	</Modal>
);

export default PropertyCreateModal;
