import { call } from '../@';

export type RequestQuoteCallbackPayload = {
	id: string;
	phone: string;
	issue: string;
};

export default async function requestQuoteCallback(payload: RequestQuoteCallbackPayload) {
	return await call('requestQuoteCallback', payload);
}
