import { useSelector } from 'react-redux';
import { getTransactionOverviewThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { Typography, Box } from '@mui/material';
import palette from 'src/theme/palette';
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { taskType } from 'src/constants/tasks';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';
import Task from './components/Task';
import { TasksWrapper } from './styled';
import ConfirmRelationshipModal from './components/ConfirmRelationshipModal';

const TasksSection = () => {
	const transaction = useSelector(selectTransactionOverview);

	useEffect(() => {
		dispatch(getTransactionOverviewThunk({ id: transaction.id, withLoading: false }));
	}, []);

	return (
		<PropertyGeneralAddressCard
			sx={{
				maxHeight: '620px',
				overflowY: 'auto',
				pb: 0,
				border: `1px solid ${palette.light.info.light}`,
				background: '#F5F8FFCC',
			}}
		>
			<TypographyAddressTitle mb='20px'>YOUR TASKS</TypographyAddressTitle>
			<TasksWrapper>
				<Box width='100%' mb='25px'>
					{transaction.tasks.length ? (
						<Box>
							<Box display='flex' flexDirection='column' gap='10px'>
								{transaction.tasks.map(({ user, tasks: userTasks }) => {
									const hasInviteTask = userTasks.some((t) => t.type === taskType.acceptInvite);
									const hasVerificationTasks = userTasks.some(
										(t) => t.type === taskType.getVerified || t.type === taskType.confirmVerificationResults,
									);
									const hasPersonalDetailsTask = userTasks.some((t) => t.type === taskType.enterPersonalDetails);
									const hasConfirmBuyTask = userTasks.some((t) => t.type === taskType.confirmBuyTeam);
									const hasAssignTitleTask = userTasks.some((t) => t.type === taskType.assignTitle);
									const hasDownloadTitleTask = userTasks.some((t) => t.type === taskType.downloadTitle);
									const hasConfirmRelationshipTask = userTasks.some((t) => t.type === taskType.confirmRelationship);

									return (
										<div key={user.id}>
											<Typography sx={{ cursor: 'default' }} variant='link'>
												@{user.knownAs || user.givenNames}
											</Typography>
											:
											<Box pl='35px'>
												<Box
													component='ol'
													display='flex'
													flexDirection='column'
													gap='5px'
													sx={{ listStylePosition: 'outside' }}
												>
													{userTasks
														.filter((t) =>
															hasInviteTask
																? t.type === taskType.acceptInvite
																: hasVerificationTasks
																? t.type === taskType.getVerified || t.type === taskType.confirmVerificationResults
																: hasPersonalDetailsTask
																? t.type === taskType.enterPersonalDetails
																: hasConfirmBuyTask
																? t.type === taskType.confirmBuyTeam
																: hasAssignTitleTask
																? t.type === taskType.assignTitle
																: hasDownloadTitleTask
																? t.type === taskType.downloadTitle
																: hasConfirmRelationshipTask
																? t.type === taskType.confirmRelationship
																: true,
														)
														.map((task) => (
															<li key={task.id}>
																<Task {...task} user={user} />
															</li>
														))}
												</Box>
											</Box>
										</div>
									);
								})}
							</Box>
						</Box>
					) : (
						<b>There are currently no pending tasks.</b>
					)}
				</Box>
			</TasksWrapper>
			<ConfirmRelationshipModal />
		</PropertyGeneralAddressCard>
	);
};

export default TasksSection;
