import { Box } from '@mui/material';
import SummariesTable from './Table';
import SummariesToolbar from './Toolbar';

const TransactionSummaries = () => (
	<Box pt='10px' height='100%'>
		<SummariesToolbar />
		<SummariesTable />
	</Box>
);

export default TransactionSummaries;
