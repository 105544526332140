import { ProviderContext } from 'notistack';
import resendTransactionInvitation, {
	Payload as ResendTransactionInvitationPayload,
} from 'src/api/invitation/resend-transaction-invitation';
import { IInviteCardMenuOption, LocalUser } from 'src/components/InviteCard/types';
import modals from 'src/constants/modals';
import { openModal } from 'src/redux/slices/modal';
import { dispatch } from 'src/redux/store';
import { downloadUserDocument } from 'src/utils/storage';
import { UserData } from 'src/api/auth/@user-data';
import {
	getTransactionParticipantsThunk,
	removeTransactionPlaceholder,
	updateTransactionUserThunk,
} from 'src/redux/slices/transaction';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { IExtendedQuestionnaireTransaction } from 'src/types';
import cancelTransactionInvitation from 'src/api/invitation/cancel-transaction-invitation';
import { getFileNameExtension } from 'src/utils/strings';
import updateTransactionPlaceholder from 'src/api/transaction/update-placeholder';
import { transactionUserRelationship } from '../constants';

type Alert = ProviderContext['enqueueSnackbar'];

export const getResendInvitationMenuOption = (
	payload: ResendTransactionInvitationPayload,
	alert: Alert,
): IInviteCardMenuOption => ({
	label: 'Resend invitation',
	onClick: async () => {
		try {
			const { message } = await resendTransactionInvitation(payload);

			dispatch(getTransactionParticipantsThunk({ withLoading: true, id: payload.transactionId }));

			alert(message);
		} catch (e) {
			console.log('e', e);
			if (e instanceof Error) {
				alert(e.message, { variant: 'error' });
			}
		}
	},
});

export const getCancelInvitationMenuOption = (
	payload: ResendTransactionInvitationPayload,
	alert: Alert,
): IInviteCardMenuOption => ({
	label: 'Cancel invitation',
	onClick: async () => {
		try {
			await cancelTransactionInvitation(payload);

			dispatch(getTransactionParticipantsThunk({ withLoading: true, id: payload.transactionId }));

			alert('Invitation successfully canceled');
		} catch (e) {
			console.log('e', e);
			if (e instanceof Error) {
				alert(e.message, { variant: 'error' });
			}
		}
	},
});

export const getVerificationIdMenuOptions = (
	isAdmin: boolean,
	user: LocalUser,
	alert: Alert,
): IInviteCardMenuOption[] => {
	const options: IInviteCardMenuOption[] = [];

	if (!user.isActive && !user.isPlaceholder && (isAdmin || user.canEdit)) {
		options.push({
			label: 'Edit invite',
			onClick: async () => {
				dispatch(openModal({ name: modals.invitation, payload: { isEditParticipant: true, participantId: user.uid } }));
			},
		});
	}

	if ((user.canDownloadDocuments || isAdmin) && user.documents?.verification) {
		options.push({
			label: 'Download Proof of ID',
			onClick: async () => {
				const proof = await downloadUserDocument(
					`${user.givenNames} ${user.lastNames} - ID, Address and AML check.pdf`,
					user.documents?.verification ?? `${user.uid}/verification-pdf.pdf`,
				);

				if (!proof) alert('No Proof of ID found!', { variant: 'error' });
			},
		});
	}

	if ((user.canDownloadDocuments || isAdmin) && user.documents?.proofOfAddress) {
		options.push({
			label: 'Download Proof of Address',
			onClick: async () => {
				const proofOfAddress = user.documents?.proofOfAddress as string;

				const proof = await downloadUserDocument(
					`${user.givenNames} ${user.lastNames} - Proof of Address${getFileNameExtension(proofOfAddress)}`,
					proofOfAddress,
				);

				if (!proof) alert('No Proof of Address found!', { variant: 'error' });
			},
		});
	}

	return options;
};

export const getPlaceholderMenuOptions = (
	transactionId: string,
	currentUser: UserData,
	user: LocalUser,
): IInviteCardMenuOption[] => {
	const options: IInviteCardMenuOption[] = [];

	if (user.isPlaceholder) {
		if (user.canEdit || currentUser.isAdmin) {
			options.push({
				label: 'Edit',
				onClick: async () => {
					dispatch(
						openModal({ name: modals.invitation, payload: { placeholderId: user.placeholderId, isEdit: true } }),
					);
				},
			});
		}

		options.push({
			label: 'Invite',
			onClick: async () => {
				dispatch(openModal({ name: modals.invitation, payload: { placeholderId: user.placeholderId } }));
			},
		});

		if ((user.canRemove || currentUser.isAdmin) && !user.isFromTitle) {
			options.push({
				label: 'Remove',
				onClick: async () => {
					await dispatch(removeTransactionPlaceholder({ id: user.placeholderId as string }));
					dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
				},
			});
		}
	}

	if (currentUser.isAdmin && user.role === 'seller') {
		if (user.relationship === 'proprietor') {
			options.push({
				label: () => <>Set as Trustee</>,
				onClick: async (handleClose) => {
					if (user.isPlaceholder) {
						await updateTransactionPlaceholder({
							id: user.placeholderId as string,
							transactionId,
							relationship: transactionUserRelationship.trustee.key,
							givenNames: user.givenNames,
							lastNames: user.lastNames,
						});
					} else {
						await dispatch(
							updateTransactionUserThunk({
								userId: user.uid,
								givenNames: user.givenNames,
								lastNames: user.lastNames,
								relationship: 'trustee',
							}),
						);
					}
					dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));

					handleClose();
				},
			});
		}

		if (user.relationship === 'trustee') {
			options.push({
				label: () => <>Set as Proprietor</>,
				onClick: async (handleClose) => {
					if (user.isPlaceholder) {
						await updateTransactionPlaceholder({
							id: user.placeholderId as string,
							transactionId,
							relationship: transactionUserRelationship.proprietor.key,
							givenNames: user.givenNames,
							lastNames: user.lastNames,
						});
					} else {
						await dispatch(
							updateTransactionUserThunk({
								userId: user.uid,
								givenNames: user.givenNames,
								lastNames: user.lastNames,
								relationship: 'proprietor',
							}),
						);
					}
					dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));

					handleClose();
				},
			});
		}
	}

	return options;
};

export const getQuestionnaireMenuOptions = (
	currentUser: UserData,
	user: LocalUser,
	questionnaire: IExtendedQuestionnaireTransaction,
): IInviteCardMenuOption[] => {
	const isPrincipal = user.role === 'buyer' || user.role === 'seller';
	const options: IInviteCardMenuOption[] = [];
	const personalDetails = questionnaire?.personal_details?.[user.uid];

	if (isPrincipal && user.approved && personalDetails && currentUser.isAdmin) {
		options.push({
			label: 'Edit details',
			onClick: () => {
				dispatch(openModal({ name: QuestionnaireTransactionEnum.personal_details, payload: { userId: user.uid } }));
			},
		});
	}

	return options;
};
