import 'simplebar/src/simplebar.css';

import 'react-quill/dist/quill.snow.css';
import './utils/highlight';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as Sentry from '@sentry/react';
import { persistor } from './redux/store';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Providers from './providers';

if (process.env.REACT_APP_ENV_MODE !== 'local') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const isIframe = window.self !== window.top;

if (isIframe) {
	root.render(<div>Forbidden</div>);
} else {
	root.render(
		<HelmetProvider>
			<Providers>
				<PersistGate loading={null} persistor={persistor}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<SettingsProvider>
							<CollapseDrawerProvider>
								<App />
							</CollapseDrawerProvider>
						</SettingsProvider>
					</LocalizationProvider>
				</PersistGate>
			</Providers>
		</HelmetProvider>,
	);
}

reportWebVitals();
