import Tracker from 'src/sections/@dashboard/greetings/Tracker';
import { useSelector } from 'react-redux';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { Box } from '@mui/material';
import { TrackerSectionWrapper, TrackerWrapper } from './styled';
import ExchangeSection from '../ExchangeSection';

const TrackerSection = () => {
	const transaction = useSelector(selectTransactionOverview);

	return (
		<TrackerSectionWrapper withTracker={!transaction.isInstrument}>
			<Box display='flex' gap='20px'>
				<TrackerWrapper>
					<Tracker />
				</TrackerWrapper>
				<ExchangeSection />
			</Box>
		</TrackerSectionWrapper>
	);
};

export default TrackerSection;
