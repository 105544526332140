import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { onSnapshot } from 'firebase/firestore';
import { RatingCard } from 'src/components/RatingCard';
import { getProperty, getPropertyByTransactionId, selectPropertySlice } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import { Box } from '@mui/material';
import { getPropertyRef } from 'src/utils/firebase';
import { noop } from 'lodash';
import { GeneralAddress } from './GeneralAddress';
import { rangeEnergy, rangeEnvironment } from './localConstants';
import { CardsWrapper } from './styled';
import { IGeneralTab } from './types';
import Summary from './Summary';
import { FloorPlansSection, PhotosSection, PricesSection, TasksSection, TrackerSection } from './components';
import { PhotosCarouselModal } from '../modals';
import Progress from '../Progress';
import BuiltFormSection from './components/BuiltFormSection';
import MaterialsSection from './components/MaterialsSection';
import UtilitiesSection from './components/UtilitiesSection';
import LocalAuthoritySection from './components/LocalAuthoritySection';
import { useEPC } from './hooks/useEPC';

const GeneralTab: FC<IGeneralTab> = ({ isProperty }) => {
	const { id: propOrTrsnId = '' } = useParams();
	const { property, isLoading } = useSelector(selectPropertySlice);
	const epc = useEPC();

	useEffect(() => {
		if (!isProperty) dispatch(getPropertyByTransactionId({ id: propOrTrsnId, withLoading: isLoading }));
	}, [propOrTrsnId]);

	useEffect(() => {
		if (!property?.id) return noop;

		const unsubscribe = onSnapshot(getPropertyRef(property.id), (snapshot) => {
			dispatch(getProperty({ id: snapshot.id }));
		});

		return () => unsubscribe();
	}, [property?.id]);

	if (isLoading || !property) {
		return (
			<Box height='100%' width='100%'>
				<Progress />
			</Box>
		);
	}

	return (
		<Box pt='30px'>
			{!isProperty && <TrackerSection />}
			<CardsWrapper>
				{!isProperty && <TasksSection />}
				<GeneralAddress />
				<Summary />
				{property.dataExtraction?.stages?.epc && (
					<>
						<BuiltFormSection />
						<MaterialsSection />
						<UtilitiesSection />
						<LocalAuthoritySection />
					</>
				)}
				{isProperty && <PhotosSection />}
				{isProperty && <FloorPlansSection />}
				<RatingCard
					title='Energy Efficiency Rating'
					maxValueTitle='Very energy efficient - lower running costs'
					minValueTitle='Not energy efficient - higher running costs'
					rateRanges={rangeEnergy}
					current={epc.data?.current_energy_efficiency}
					potential={epc.data?.potential_energy_efficiency}
					status={epc.status}
				/>
				<RatingCard
					title='Environmental Impact (CO₂) Rating'
					maxValueTitle='Very environmentally friendly - lower CO₂ emissions'
					minValueTitle='Not environmentally friendly - higher CO₂ emissions'
					rateRanges={rangeEnvironment}
					current={epc.data?.environment_impact_current}
					potential={epc.data?.environment_impact_potential}
					status={epc.status}
				/>
				{isProperty && <PricesSection />}
			</CardsWrapper>

			<PhotosCarouselModal />
		</Box>
	);
};

export default GeneralTab;
