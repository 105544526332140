export const services = {
	logoWidth: '100px',
	logoHeight: '100px',
	logoPosition: 'right',
	completedHtml: "<h3>That's it! Thank you for completing the Services card! 🎉</h3>",
	completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
	loadingHtml: '<h3>Loading questions...</h3>',
	pages: [
		{
			name: 'services_page',
			elements: [
				{
					type: 'checkbox',
					name: 'services',
					title: 'Which of the following services are connected to the property? *',
					hideNumber: true,
					defaultValue: ['drainage', 'electricity', 'gas', 'sewage', 'water'],
					isRequired: true,
					choices: [
						{
							value: 'drainage',
							text: 'Mains drainage',
						},
						{
							value: 'electricity',
							text: 'Electricity',
						},
						{
							value: 'broadband',
							text: 'Broadband',
						},
						{
							value: 'landline',
							text: 'Telephone landline',
						},
						{
							value: 'cable',
							text: 'Cable TV',
						},
						{
							value: 'water',
							text: 'Water supply',
						},
						{
							value: 'gas',
							text: 'Gas',
						},
						{
							value: 'sewage',
							text: 'Public sewer',
						},
						{
							value: 'solarpanels',
							text: 'Solar panels',
						},
						{
							value: 'windturbine',
							text: 'Wind turbine',
						},
					],
					showOtherItem: true,
				},
				{
					type: 'panel',
					name: 'electricity',
					elements: [
						{
							type: 'boolean',
							name: 'electricity_report_yn',
							title: 'Has an Electrical Installation Condition Report (EICR) been released for the property? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['electricity']",
						},
						{
							type: 'html',
							name: 'electricity_report_yes_note',
							visibleIf: '{electricity_report_yn} = true',
							html: "We'll ask you to upload a copy of the <b> Electrical Installation Condition Report</b> in the <b>Documents</b> tab.",
						},
						{
							type: 'html',
							name: 'electricity_report_no_note',
							visibleIf: '{electricity_report_yn} = false',
							html: "No worries! We'll get a qualified and registered electrician booked in for you. Alternatively, you can arrange a visit by an electrician of your choice. \nOnce you have obtained your certificate, you can upload it from the <b>Documents</b> tab.",
						},
						{
							type: 'dropdown',
							name: 'electricity_provider',
							title: 'What is your current electricity provider? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['electricity']",
							choices: [
								'Affect Energy',
								'Boost Energy',
								'British Gas',
								'Co-Operative Energy',
								'E',
								'E.ON',
								'EBICo',
								'Ecotricity',
								'EDF Energy',
								'Good Energy',
								'Green Energy UK',
								'M&S Energy',
								'Octopus Energy',
								'Outfox the Market',
								'Ovo Energy',
								"Sainsbury's Energy",
								'Scottish Power',
								'Shell Energy',
								'So Energy',
								'SSE',
								'Southern Electric',
								'Scottish Hydro',
								'Swalec',
								'Telecom Utility Warehouse',
								'Utilita Energy',
							],
							showOtherItem: true,
						},
						{
							type: 'text',
							name: 'electricity_meter_location',
							title: 'Where is the electricity meter? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['electricity']",
						},
					],
					visibleIf: "{services} anyof ['electricity']",
					requiredIf: "{services} anyof ['electricity']",
					title: 'Electricity',
				},
				{
					type: 'panel',
					name: 'gas',
					elements: [
						{
							type: 'boolean',
							name: 'gas_certificate_yn',
							title: 'Has a Gas Safe Register been released for the property? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['gas']",
						},
						{
							type: 'html',
							name: 'gas_certificate_yes_note',
							visibleIf: '{gas_certificate_yn} = true',
							html: "We'll ask you to upload a copy of the <b>Gas Safe Register</b> in the <b>Documents</b> tab.",
						},
						{
							type: 'html',
							name: 'gas_certificate_no_note',
							visibleIf: '{gas_certificate_yn} = false',
							html: "No worries! We'll get a qualified and registered boiler surveyor booked in for you. Alternatively, you can arrange a visit by a boiler surveyor of your choice. Once your <b>Gas Safe Register</b> has been released, you can upload it from the <b>Documents</b> tab.",
						},
						{
							type: 'dropdown',
							name: 'gas_provider',
							title: 'What is your current gas provider? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['gas']",
							choices: [
								'Affect Energy',
								'Boost Energy',
								'British Gas',
								'Co-Operative Energy',
								'E',
								'E.ON',
								'EBICo',
								'Ecotricity',
								'EDF Energy',
								'Good Energy',
								'Green Energy UK',
								'M&S Energy',
								'Octopus Energy',
								'Outfox the Market',
								'Ovo Energy',
								"Sainsbury's Energy",
								'Scottish Power',
								'Shell Energy',
								'So Energy',
								'SSE',
								'Southern Electric',
								'Scottish Hydro',
								'Swalec',
								'Telecom Utility Warehouse',
								'Utilita Energy',
							],
							showOtherItem: true,
						},
						{
							type: 'text',
							name: 'gas_meter_location',
							title: 'Where is the gas meter? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['gas']",
						},
					],
					visibleIf: "{services} anyof ['gas']",
					requiredIf: "{services} anyof ['gas']",
					title: 'Gas',
				},
				{
					type: 'panel',
					name: 'water',
					elements: [
						{
							type: 'text',
							name: 'water_provider',
							title: 'What is your current water provider? *',
							hideNumber: true,
							isRequired: true,
						},
						{
							type: 'text',
							name: 'water_stopcock_location',
							title: 'Where is the stopcock? *',
							hideNumber: true,
							isRequired: true,
						},
						{
							type: 'text',
							name: 'water_meter_location',
							title: 'Where is the water meter? *',
							hideNumber: true,
							isRequired: true,
						},
					],
					visible: false,
					visibleIf: "{services} anyof ['water']",
					requiredIf: "{services} anyof ['water']",
					title: 'Water',
				},
				{
					type: 'panel',
					name: 'landline',
					elements: [
						{
							type: 'dropdown',
							name: 'landline_provider',
							title: 'What is your current landline provider? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['landline']",
							choices: [
								'BT (British Telecom)',
								'EE',
								'Hyperoptic',
								'John Lewis Broadband',
								'Plusnet',
								'Post Office',
								'Sky',
								'TalkTalk',
								'Virgin Media',
								'Vodafone',
							],
							showOtherItem: true,
						},
					],
					visible: false,
					visibleIf: "{services} anyof ['landline']",
					requiredIf: "{services} anyof ['landline']",
					title: 'Telephone landline',
				},
				{
					type: 'panel',
					name: 'broadband',
					elements: [
						{
							type: 'dropdown',
							name: 'broadband_provider',
							title: 'What is your current broadband provider? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['broadband']",
							choices: [
								'BT (British Telecom)',
								'Community Fibre',
								'Direct Save Telecom',
								'EE',
								'Gigaclear',
								'Hyperoptic',
								'John Lewis Broadband',
								'Onestream',
								'Origin',
								'Plusnet',
								'Post Office',
								'Shell Energy',
								'Sky',
								'TalkTalk',
								'Three',
								'Virgin Media',
								'Vodafone',
							],
							showOtherItem: true,
						},
					],
					visible: false,
					visibleIf: "{services} anyof ['broadband']",
					requiredIf: "{services} anyof ['broadband']",
					title: 'Broadband',
				},
				{
					type: 'panel',
					name: 'cable_tv',
					elements: [
						{
							type: 'text',
							name: 'cable_provider',
							title: 'What is your current cable provider? *',
							hideNumber: true,
							isRequired: true,
							requiredIf: "{services} anyof ['cable']",
						},
					],
					visibleIf: "{services} anyof ['cable']",
					requiredIf: "{services} anyof ['cable']",
					title: 'Cable television',
				},
			],
			title: 'Facilities and utilities',
		},
		{
			name: 'heating_page',
			elements: [
				{
					type: 'panel',
					name: 'heating_panel',
					elements: [
						{
							type: 'html',
							name: 'heating_system_definitions',
							html: '<b>Central heating systems</b> are larger machines that are installed in one place in your home, usually somewhere like a basement or a mechanical room, and use pipes or radiators to spread warmth throughout your entire house.\n\n<b>Room heating</b>, on the other hand, is an extra way to heat a smaller area like a bedroom or living room. This can be done using portable heaters, stoves or fireplaces that are placed directly in the room and provide heat in that specific area, rather than heating the whole house.',
						},
						{
							type: 'matrix',
							name: 'heating_system',
							title: 'Which of the following heating systems are present? *',
							hideNumber: true,
							isRequired: true,
							columns: [
								{
									value: 'yes',
									text: 'Yes',
								},
								{
									value: 'no',
									text: 'No',
								},
							],
							rows: [
								{
									value: 'central',
									text: 'Central heating',
								},
								{
									value: 'direct',
									text: 'Room heating',
								},
							],
						},
						{
							type: 'checkbox',
							name: 'central_heating_type',
							visible: false,
							visibleIf: "{heating_system.central} = 'yes'",
							title: 'What type of central heating system is it? *',
							hideNumber: true,
							requiredIf: "{heating_system.central} = 'yes'",
							choices: ['Furnace', 'Boiler', 'Heat pump'],
							showOtherItem: true,
						},
						{
							type: 'html',
							name: 'central_heating_note',
							html: '<ul>\n  <li><strong>Furnace:</strong> Uses fuel combustion (gas, oil, propane) to heat air, which is then circulated throughout the property via ductwork.</li>\n  <li><strong>Boiler:</strong> Heats water, which is distributed through pipes to radiators, baseboard heaters, or radiant floor systems to warm the space.</li>\n  <li><strong>Heat Pump:</strong> Uses electricity to transfer heat from one place to another (e.g. from outside air to inside the home in winter), providing both heating and cooling.</li>\n</ul>',
						},
						{
							type: 'panel',
							name: 'furnace',
							elements: [
								{
									type: 'text',
									name: 'furnace_install_date',
									title: 'When was the furnace installed? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'boolean',
									name: 'furnace_working',
									title: 'Is the furnace in good working order? *',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Furnace']",
									isRequired: true,
								},
								{
									type: 'text',
									name: 'furnace_service_date',
									title: 'When was the furnace last serviced? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'checkbox',
									name: 'furnace_fuel',
									title: 'What fuel does the furnace run on? *',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Furnace']",
									choices: ['Mains gas', 'Liquid gas', 'Oil', 'Coal', 'Electricity'],
									showOtherItem: true,
									isRequired: true,
								},
							],
							visibleIf: "{central_heating_type} anyof ['Furnace']",
							title: 'Furnace',
						},
						{
							type: 'panel',
							name: 'boiler',
							elements: [
								{
									type: 'text',
									name: 'boiler_install_date',
									title: 'When was the boiler installed? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'boolean',
									name: 'boiler_working',
									title: 'Is the boiler in good working order? *',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Boiler']",
								},
								{
									type: 'text',
									name: 'boiler_service_date',
									title: 'When was the boiler last serviced? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'checkbox',
									name: 'boiler_fuel',
									title: 'What fuel does the boiler run on? *',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Boiler']",
									choices: ['Mains gas', 'Liquid gas', 'Oil', 'Coal', 'Electricity'],
									showOtherItem: true,
									isRequired: true,
								},
							],
							visibleIf: "{central_heating_type} anyof ['Boiler']",
							title: 'Boiler',
						},
						{
							type: 'panel',
							name: 'heatpump',
							elements: [
								{
									type: 'text',
									name: 'heatpump_install_date',
									title: 'When was the heat pump installed? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'boolean',
									name: 'heatpump_working',
									title: 'Is the heat pump in good working order? *',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Heat pump']",
									isRequired: true,
								},
								{
									type: 'text',
									name: 'heatpump_service_date',
									title: 'When was the heat pump last serviced? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'checkbox',
									name: 'heatpump_type',
									title: 'What kind of heat pump is it? *',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Heat pump']",
									choices: ['Air-source heat pump', 'Ground-source heat pump', 'Water-source heat pump'],
									showOtherItem: true,
									isRequired: true,
								},
								{
									type: 'checkbox',
									name: 'heatpump_fuel',
									title: 'What fuel does the heat pump run on? *',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Heat pump']",
									choices: ['Mains gas', 'Liquid gas', 'Oil', 'Coal', 'Electricity'],
									showOtherItem: true,
									isRequired: true,
								},
							],
							visibleIf: "{central_heating_type} anyof ['Heat pump']",
							title: 'Heat pump',
						},
						{
							type: 'panel',
							name: 'other_central_heating',
							elements: [
								{
									type: 'text',
									name: 'othercentralheating_install_date',
									title: 'When was the system installed? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'boolean',
									name: 'othercentralheating_working',
									title: 'Is the system in good working order? *',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['other']",
								},
								{
									type: 'text',
									name: 'othercentralheating_service_date',
									title: 'When was the system last serviced? *',
									titleLocation: 'left',
									hideNumber: true,
									inputType: 'month',
									description: '(Leave empty if not known)',
								},
								{
									type: 'checkbox',
									name: 'othercentralheating_fuel',
									title: 'What fuel does the system run on? *',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['other']",
									choices: ['Mains gas', 'Liquid gas', 'Oil', 'Coal', 'Electricity'],
									showOtherItem: true,
									isRequired: true,
								},
							],
							visibleIf: "{central_heating_type} anyof ['other']",
							title: 'Other central heating',
						},
						{
							type: 'checkbox',
							name: 'central_heating_means',
							visibleIf: "{heating_system.central} = 'yes'",
							title: 'How does the central heating system heat the property? *',
							hideNumber: true,
							requiredIf: "{heating_system.central} = 'yes'",
							choices: [
								{
									value: 'Underfloor',
									text: 'Underfloor heating',
								},
								{
									value: 'Radiating',
									text: 'Radiating heating',
								},
								{
									value: 'Warm air',
									text: 'Warm air heating',
								},
							],
							showOtherItem: true,
						},
						{
							type: 'checkbox',
							name: 'direct_heating_type',
							visibleIf: "{heating_system.direct} = 'yes'",
							title: 'What type of direct heating system is it? *',
							description:
								'"Room heaters" is a broad category including standalone appliances such as electric heaters, stoves and aircon units.',
							hideNumber: true,
							requiredIf: "{heating_system.direct} = 'yes'",
							choices: [
								{
									value: 'Heaters',
									text: 'Room heaters',
								},
								'Fireplaces',
							],
							showOtherItem: true,
						},
					],
					title: 'Heating system',
				},
			],
			title: 'Heating',
		},
		{
			name: 'cooling_page',
			elements: [
				{
					type: 'panel',
					name: 'cooling',
					elements: [
						{
							type: 'html',
							name: 'cooling_systems_definitions',
							html: "<b>Central cooling systems</b> are larger machines that are installed in one place in your home, usually somewhere like a basement or a mechanical room, and use pipes or ducts to spread cool air throughout your entire house.\n\n<b>Room cooling units</b>, on the other hand, are small machines that are designed to cool just one room at a time and don't use ducts or pipes to distribute the cool air. Instead, they just blow cool air directly into the room they're in.",
						},
						{
							type: 'matrix',
							name: 'cooling_system',
							title: 'Which of the following cooling systems are present? *',
							hideNumber: true,
							isRequired: true,
							columns: [
								{
									value: 'yes',
									text: 'Yes',
								},
								{
									value: 'no',
									text: 'No',
								},
							],
							rows: [
								{
									value: 'central',
									text: 'Central cooling',
								},
								{
									value: 'direct',
									text: 'Room cooling',
								},
							],
						},
						{
							type: 'checkbox',
							name: 'central_cooling_type',
							visible: false,
							visibleIf: "{cooling_system.central} = 'yes'",
							title: 'What type of central cooling system is it? *',
							hideNumber: true,
							requiredIf: "{cooling_system.central} = 'yes'",
							choices: ['Heat pump'],
							showOtherItem: true,
						},
						{
							type: 'panel',
							name: 'heatpump_cooling',
							elements: [
								{
									type: 'text',
									name: 'heatpump_cooling_install_date',
									title: 'When was the heat pump installed? *',
									description: '(Leave empty if not known)',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['Heat pump']",
									inputType: 'month',
								},
								{
									type: 'boolean',
									name: 'heatpump_cooling_working',
									title: 'Is the heat pump in good working order? *',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['Heat pump']",
								},
								{
									type: 'text',
									name: 'heatpump_cooling_service_date',
									title: 'When was the heat pump last serviced? *',
									description: '(Leave empty if not known)',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['Heat pump']",
									inputType: 'month',
								},
								{
									type: 'checkbox',
									name: 'heatpump_cooling_type',
									title: 'What kind of heat pump is it? *',
									hideNumber: true,
									requiredIf: "{central_heating_type} anyof ['Heat pump']",
									choices: ['Air-source heat pump', 'Ground-source heat pump', 'Water-source heat pump'],
									showOtherItem: true,
								},
								{
									type: 'checkbox',
									name: 'heatpump_cooling_fuel',
									title: 'What fuel does the heat pump run on? *',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['Heat pump']",
									choices: ['Mains gas', 'Liquid gas', 'Oil', 'Coal', 'Electricity'],
									showOtherItem: true,
								},
							],
							visibleIf: "{central_cooling_type} anyof ['Heat pump']",
							title: 'Heat pump',
						},
						{
							type: 'panel',
							name: 'othercentralheating',
							elements: [
								{
									type: 'text',
									name: 'othercentralcooling_install_date',
									title: 'When was the system installed? *',
									description: '(Leave empty if not known)',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['other']",
									inputType: 'month',
								},
								{
									type: 'boolean',
									name: 'othercentralcooling_working',
									title: 'Is the system in good working order? *',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['other']",
								},
								{
									type: 'text',
									name: 'othercentralcooling_service_date',
									title: 'When was the system last serviced? *',
									description: '(Leave empty if not known)',
									titleLocation: 'left',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['other']",
									inputType: 'month',
								},
								{
									type: 'checkbox',
									name: 'othercentralcooling_fuel',
									title: 'What fuel does the system run on? *',
									hideNumber: true,
									requiredIf: "{central_cooling_type} anyof ['other']",
									choices: ['Mains gas', 'Liquid gas', 'Oil', 'Coal', 'Electricity'],
									showOtherItem: true,
								},
							],
							visibleIf: "{central_cooling_type} anyof ['other']",
							title: 'Other central cooling',
						},
						{
							type: 'checkbox',
							name: 'central_cooling_means',
							visibleIf: "{cooling_system.central} = 'yes'",
							title: 'How does the central cooling system cool the property? *',
							hideNumber: true,
							requiredIf: "{cooling_system.central} = 'yes'",
							choices: [
								{
									value: 'Underfloor',
									text: 'Underfloor cooling',
								},
								{
									value: 'Warm air',
									text: 'Cold air cooling',
								},
							],
							showOtherItem: true,
						},
						{
							type: 'checkbox',
							name: 'direct_cooling_type',
							visibleIf: "{cooling_system.direct} = 'yes'",
							title: 'What type of room cooling system is it? *',
							hideNumber: true,
							requiredIf: "{cooling_system.direct} = 'yes'",
							choices: [
								{
									value: 'Electric space heaters',
									text: 'Air conditioner',
								},
								{
									value: 'Wood-burning and pellet stoves',
									text: 'Fan',
								},
							],
							showOtherItem: true,
						},
					],
					title: 'Cooling system',
				},
			],
			title: 'Cooling',
		},
		{
			name: 'drainage_page',
			elements: [
				{
					type: 'panel',
					name: 'drainage_panel',
					elements: [
						{
							type: 'html',
							name: 'drainage_description',
							html: '<b>Drainage</b> is the removal of water away from your property to your boundary.   \nAt the boundary, your drain connects to other pipes:  private sewers, lateral drains, the public sewer. <br><br> The next question is about whether or not your property is connected to either or both public sewers:\n\n<ul>\n<li>The <b>foul sewer</b> carries used wastewater to a sewage works for treatment.</li>\n<li>The <b>surface water sewer</b> carries uncontaminated rainwater directly to a local river, stream or soak away.</li>\n</ul>\n\nThe typical urban dwelling is connected to both the foul sewer and the surface water sewer.',
						},
						{
							type: 'matrix',
							name: 'drainage',
							title: 'Is the property connected to mains:',
							hideNumber: true,
							defaultValue: {
								foul_water: 'yes',
								surface_water: 'yes',
							},
							requiredIf: "{services} anyof ['drainage']",
							columns: [
								{
									value: 'yes',
									text: 'Yes',
								},
								{
									value: 'no',
									text: 'No',
								},
								{
									value: 'not_known',
									text: 'Not known',
								},
							],
							rows: [
								{
									value: 'foul_water',
									text: 'Foul water drainage?',
								},
								{
									value: 'surface_water',
									text: 'Surface water drainage?',
								},
							],
						},
					],
				},
			],
			title: 'Drainage',
		},
		{
			name: 'sewerage_page',
			elements: [
				{
					type: 'panel',
					name: 'sewerage_panel',
					elements: [
						{
							type: 'matrix',
							name: 'sewerage_type',
							title: 'Is the sewerage for the property provided by:',
							hideNumber: true,
							requiredIf: "{services} notcontains 'sewage'",
							columns: [
								{
									value: 'yes',
									text: 'Yes',
								},
								{
									value: 'no',
									text: 'No',
								},
							],
							rows: [
								{
									value: 'septic_tank',
									text: 'a septic tank?',
								},
								{
									value: 'sewage_plant',
									text: 'a sewage treatment plant?',
								},
								{
									value: 'cesspool',
									text: 'a cesspool?',
								},
							],
						},
						{
							type: 'text',
							name: 'septic_tank_date',
							visibleIf: "{sewerage_type.septic_tank} = 'yes'",
							title: 'When was the septic tank last replaced or upgraded?',
							hideNumber: true,
							inputType: 'month',
						},
						{
							type: 'boolean',
							name: 'sewerage_shared',
							title: 'Is the sewerage system shared with other properties?',
							hideNumber: true,
						},
						{
							type: 'text',
							name: 'sewerage_shared_count',
							visibleIf: '{sewerage_shared} = true',
							title: 'How many properties is it shared with?',
							hideNumber: true,
							requiredIf: '{sewerage_shared} = true',
						},
						{
							type: 'text',
							name: 'sewerage_emptied_date',
							title: 'What year was the sewerage system last emptied?',
							hideNumber: true,
							inputType: 'number',
							min: 1900,
							step: 1,
						},
						{
							type: 'text',
							name: 'sewage_plant_servicing_date',
							visibleIf: "{sewerage_type.sewage_plant} = 'yes'",
							title: 'What year was the treatment plant last serviced?',
							hideNumber: true,
							requiredIf: "{sewerage_type.sewage_plant} = 'yes'",
							inputType: 'number',
							min: 1900,
							step: 1,
						},
						{
							type: 'text',
							name: 'sewerage_install_date',
							title: 'What year was the system installed?',
							hideNumber: true,
							inputType: 'number',
							min: 1900,
							step: 1,
						},
						{
							type: 'boolean',
							name: 'sewerage_outside',
							title: 'Is any part of the sewerage system, or the access to it, outside the boundary of the property?',
							hideNumber: true,
						},
						{
							type: 'html',
							name: 'sewerage_outside_note',
							visibleIf: '{sewerage_outside} = true',
							html: "After the questionnaire, we'll ask you to upload a copy of any plans showing the location of the system and how access is obtained.",
						},
					],
					visible: false,
					visibleIf:
						"{drainage.foul_water} = 'no' or {drainage.foul_water} = 'not_known' or {drainage.surface_water} = 'no' or {drainage.surface_water} = 'not_known'",
				},
			],
			title: 'Sewerage',
		},
		{
			name: 'solarpanels',
			elements: [
				{
					type: 'text',
					name: 'solar_panels_year',
					title: 'Which year were the solar panels installed?',
					titleLocation: 'left',
					hideNumber: true,
					requiredIf: "{services} contains 'solarpanels'",
					inputType: 'number',
					min: 1980,
					step: 1,
				},
				{
					type: 'boolean',
					name: 'solar_panels_owned',
					title: 'Do you own the solar panels outright?',
					titleLocation: 'left',
					hideNumber: true,
					requiredIf: "{services} contains 'solarpanels'",
				},
				{
					type: 'boolean',
					name: 'solar_panels_lease',
					title: 'Has a long lease of the roof / air space been granted to a solar panel provider?',
					description: 'A typical long lease may last 20 to 25 years.',
					hideNumber: true,
					requiredIf: "{services} contains 'solarpanels'",
				},
				{
					type: 'html',
					name: 'solar_panels_note',
					html: "In the <b>Documents</b> tab, we'll ask you to upload copies of any paperwork about your solar panels, such as installation certificates and electricity bills for feed-in tariffs (e.g. payments made for supplying electricity to the main grid).   <b>Not sure what to upload? Drop us a line at care@conveyo.io</b>!",
				},
			],
			visibleIf: "{services} contains 'solarpanels'",
			title: 'Solar panels',
		},
		{
			name: 'parking',
			elements: [
				{
					type: 'checkbox',
					name: 'parking_arrangements',
					title: 'What are the parking arrangements at the property? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'garage',
							text: 'Garage',
						},
						{
							value: 'allocated_space',
							text: 'Allocated space',
						},
						{
							value: 'permit',
							text: 'Permit',
						},
						{
							value: 'roadway',
							text: 'Roadway',
						},
						{
							value: 'driveway',
							text: 'Driveway',
						},
					],
					showOtherItem: true,
					showNoneItem: true,
					colCount: 2,
				},
				{
					type: 'radiogroup',
					name: 'parking_control',
					title: 'Is the property in a controlled parking zone or within a local authority parking scheme? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_sure',
							text: 'Not sure',
						},
					],
					colCount: 3,
				},
			],
			title: 'Parking',
		},
	],
	showTitle: false,
	showCompletedPage: false,
	showProgressBar: 'top',
	completeText: 'Submit',
	widthMode: 'static',
};
