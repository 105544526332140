import { Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { selectModal } from 'src/redux/slices/modal';
import { Payment } from 'src/types';
import {
	getTransactionOverviewThunk,
	getTransactionSummaryThunk,
	selectTransactionOverview,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import deletePayment from 'src/api/payment/delete-payment';
import { getTransactionRef } from 'src/utils/firebase';
import { deleteField, updateDoc } from 'firebase/firestore';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<WithId<Payment> & { path: string }>);
	const transaction = useSelector(selectTransactionOverview);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		try {
			setIsLoading(true);

			await deletePayment(payload.id);
			await updateDoc(getTransactionRef(transaction.id), { [payload.path]: deleteField() });

			await Promise.all([
				dispatch(getTransactionOverviewThunk({ id: transaction.id })),
				dispatch(getTransactionSummaryThunk({ id: transaction.id })),
			]);

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Typography sx={styledMainTitle} pt={0}>
				DELETE PAYMENT
			</Typography>
			<Typography>Are you sure you want to delete this payment? This action cannot be reversed.</Typography>
			<ActionButtons mt={'20px'}>
				<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton
					variant='contained'
					size='large'
					fullWidth
					type='submit'
					loading={isLoading}
					onClick={handleSubmit}
				>
					Continue
				</LoadingButton>
			</ActionButtons>
		</>
	);
};

const DeletePaymentModal = () => (
	<Modal name={modals.deletePayment} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default DeletePaymentModal;
