import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { dispatch } from 'src/redux/store';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { useSelector } from 'react-redux';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { selectQuote } from 'src/redux/slices/quoteSlice';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import sendQuoteMessage from 'src/api/quote/send-quote-message';
import { RHFTextField } from 'src/components';
import { validationSchema } from './validation';
import { QuoteMessageForm } from './types';

const Content = () => {
	const { payload } = useSelector(selectModal<{ issue: string }>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const quote = useSelector(selectQuote);
	const form = useForm<QuoteMessageForm>({
		resolver: yupResolver(validationSchema),
	});

	const handleSubmit = async (data: QuoteMessageForm) => {
		try {
			setIsLoading(false);

			await sendQuoteMessage({ id: quote.data?.id as string, issue: payload.issue, message: data.message });

			enqueueSnackbar('Quote message successfully submitted!');

			handleCloseModal();
		} catch (e) {
			console.log(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};
	const handleBack = () => {
		dispatch(openModal({ name: modals.quoteQuestions, payload: { issue: payload.issue } }));
	};

	return (
		<FormProvider {...form}>
			<Typography sx={styledMainTitle} pt={0}>
				SEND A MESSAGE
			</Typography>
			<Typography sx={{ fontSize: '14px', color: '#666', marginBottom: '10px' }}>
				Tell us more about your issue and we'll get back to you ASAP!
			</Typography>
			<RHFTextField
				name='message'
				placeholder='Enter your message'
				multiline
				sx={{
					marginBottom: '20px',
					borderColor: '#d3d3d3',
					minHeight: '120px',
					maxHeight: '200px',
					fontSize: '14px',
					resize: 'vertical',
					width: '100%',
				}}
			/>
			<ActionButtons mt={'20px'}>
				<LoadingButton
					variant='outlined'
					size='large'
					fullWidth
					onClick={handleBack}
					loading={isLoading}
					sx={{
						borderRadius: '8px',
						textTransform: 'none',
						padding: '12px',
						backgroundColor: '#f4f4f4',
						'&:hover': { backgroundColor: '#e0e0e0' },
					}}
				>
					Go back
				</LoadingButton>
				<LoadingButton
					variant='contained'
					size='large'
					fullWidth
					sx={{
						textTransform: 'none',
						borderRadius: '8px',
						padding: '12px',
					}}
					onClick={form.handleSubmit(handleSubmit)}
					loading={isLoading}
				>
					Send message
				</LoadingButton>
			</ActionButtons>
		</FormProvider>
	);
};

const QuoteMessageModal = () => (
	<Modal name={modals.quoteMessage} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default QuoteMessageModal;
